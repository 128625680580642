import axios from 'axios'
import {Loading} from 'element-ui'
import common from "./config";
import Vue from 'vue';

axios.defaults.timeout=20000
axios.defaults.baseURL = common.requestUrl

const vm = new Vue();

// import '../assets/css/pageLoading.css'

// loading函数
// 记录请求次数
let needLoadingRequestCount = 0
let loading

function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '',
        background: 'rgba(0, 0, 0, 0.1)'
    })
}

function endLoading() {
    // 延迟500ms，防止网速特快加载中画面一闪而过
    setTimeout(function () {
        if (loading) loading.close()
    }, 500)
}

// 打开loading
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++
}

// 关闭loading
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }
}

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // showFullScreenLoading()
        if (sessionStorage.getItem('token')) {
            config.headers.token = sessionStorage.getItem('token')
        }

        return config
    },

    error => {
        // tryHideFullScreenLoading()
        return Promise.reject(error)
    }
)

//响应拦截器
axios.interceptors.response.use(success => {
    if (success.data.code && success.data.code === 500) {
        // tryHideFullScreenLoading()
        vm.$warning('请重新登录')
        sessionStorage.clear();
        this.$router.push('/loginService');
    }

    if (success.data.code && success.data.code !== 200 && success.data.code !== 500) {
        // tryHideFullScreenLoading()
        vm.$warning(success.data.message)
    }
    // tryHideFullScreenLoading()
    return success;

}, error => {
    if (error.response.status === 504 || error.response.status === 404) {
        vm.$warning('服务器被吃了o(╯□╰)o')
    } else if (error.response.status === 403) {
        vm.$warning('权限不足')
    } else if (error.response.status === 500) {
        vm.$warning('服务异常')
    } else {
        if (error.response.data.message) {
            vm.$warning(error.response.data.message)
        } else {
            vm.$warning('系统重启中')
        }
    }
    // tryHideFullScreenLoading()
    return false;
});
