import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/dashboard'
        },
        {
            path: '/codeStatus',
            component: () => import(/* webpackChunkName: "login" */ '@/components/CodeStatus.vue'),
            meta: {title: '卡密状态查询|财神云客服系统'}
        },
        {
            path: '/getCode',
            component: () => import(/* webpackChunkName: "login" */ '@/components/GetCode.vue'),
            meta: {title: '引流工作台|财神云客服系统'}
        },
        {
            path: '/userIpQuery',
            component: () => import(/* webpackChunkName: "login" */ '@/components/UserIpQuery.vue'),
            meta: {title: '用户IP查询|财神云客服系统'}
        },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue'),
            meta: {title: '主页'},
            children: [
                {
                    path: '/dashboard',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Dashboard.vue'),
                    meta: {title: '系统首页'}
                },
                {
                    path: '/renew',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Renew.vue'),
                    meta: {title: '续费管理'}
                },
                {
                    path: '/account',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Account.vue'),
                    meta: {title: '客服管理'}
                },
                {
                    path: '/domain',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Domain.vue'),
                    meta: {title: '域名管理'}
                },
                {
                    path: '/order',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Order.vue'),
                    meta: {title: '订单管理'}
                },
                {
                    path: '/user',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/User.vue'),
                    meta: {title: '用户管理'}
                },
                {
                    path: '/announcement',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Announcement.vue'),
                    meta: {title: '公告管理'}
                },
                {
                    path: '/privacy',
                    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/Privacy.vue'),
                    meta: {title: '隐私协议'}
                },
                {
                    path: '/404',
                    component: () => import(/* webpackChunkName: "404" */ '@/pages/404.vue'),
                    meta: {title: '404'}
                },
                {
                    path: '/qrCode',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/QrCode.vue'),
                    meta: {title: '二维码'}
                },
                {
                    path: '/chat',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/ChatPage.vue'),
                    meta: {title: '消息列表'}
                },
                {
                    path: '/welcome',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/WelCome.vue'),
                    meta: {title: '欢迎语'}
                },
                {
                    path: '/intelligentRecovery',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/IntelligentRecovery.vue'),
                    meta: {title: '智能回复'}
                },
                {
                    path: '/quickReply',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/QuickReply.vue'),
                    meta: {title: '快捷回复'}
                },
                {
                    path: '/statistics',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/Statistics.vue'),
                    meta: {title: '统计'}
                },
                {
                    path: '/set',
                    component: () => import(/* webpackChunkName: "404" */ '@/components/Set.vue'),
                    meta: {title: '设置'}
                }
            ]
        },
        {
            path: '/loginAdmin',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/LoginAdmin.vue'),
            meta: {title: '管理员登录'}
        },
        {
            path: '/loginService',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/LoginService.vue'),
            meta: {title: '财神云客服'}
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});
