import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import ElementUI from 'element-ui';
import '@/assets/css/style.scss';

import Preview from '@/components/preview/index.js'
import '@/utils/request'; //引入axios配置
import axios from 'axios';
// import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import '@/assets/css/theme-green/index.css'; // 浅绿色主题
import 'babel-polyfill';
import store from '@/store/index';

import bus from '@/assets/js/bus';
import VueClipBoard from 'vue-clipboard2';
import LemonIMUI from '@/components/lemon-imui/index.umd.min.js';
import '@/components/lemon-imui/index.css';
import LemonMessageVideo from "@/components/lemon-imui/video";
import LemonMessageText from "@/components/lemon-imui/text";
import hevueImgPreview from 'hevue-img-preview'
import 'hevue-img-preview/css/theme-dark.css'
import VueSSE from 'vue-sse';
//自定义toast
import toast from '@/utils/toast';
//Vuesax
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import '@/assets/icon/image/iconfont.css';
import '@/assets/icon/quick/iconfont.css';
import '@/assets/icon/intelligentRecovery/iconfont.css';
import '@/assets/icon/lahei/iconfont.css';
import '@/assets/icon/quxiaolahei/iconfont.css';
import '@/assets/icon/chehui/iconfont.css';
import '@/assets/icon/service/iconfont.css';
import '@/assets/icon/interview/iconfont.css';
import '@/assets/icon/dashboard/iconfont.css';
import '@/assets/icon/renew/iconfont.css';
import '@/assets/icon/domain/iconfont.css';
import '@/assets/icon/order/iconfont.css';
import '@/assets/icon/user/iconfont.css';
import '@/assets/icon/announcement/iconfont.css';
import '@/assets/icon/privacy/iconfont.css';
import '@/assets/icon/qr/iconfont.css';
import '@/assets/icon/message/iconfont.css';
import '@/assets/icon/call/iconfont.css';
import '@/assets/icon/tongji/iconfont.css';
import '@/assets/icon/set/iconfont.css';
import '@/assets/icon/contentCopy/iconfont.css';
import '@/assets/icon/qunfa/iconfont.css';
import '@/assets/icon/shouqi/iconfont.css';
import '@/assets/icon/logout/iconfont.css';
import '@/assets/icon/editPass/iconfont.css';

Vue.use(Preview)

Vue.prototype.axios = axios;

Vue.prototype.bus = bus;

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 999999
});

Vue.use(VueClipBoard);

Vue.use(LemonIMUI);

Vue.component(LemonMessageVideo.name, LemonMessageVideo);
Vue.component(LemonMessageText.name, LemonMessageText);

Vue.use(hevueImgPreview,{
  clickMaskCLose:true,
  keyboard:true,
  arrowBtn:false
})

Vue.use(VueSSE);

Vue.use(toast);

Vue.use(Vuesax)



//路由跳转之前
router.beforeEach((to, from, next) => {
  let Authorization = sessionStorage.getItem('token');
  if (to.path !== '/loginAdmin' && to.path !== '/loginService' && to.path !== '/codeStatus' && to.path !== '/getCode'&&to.path!=='/userIpQuery') {
    if (!Authorization) {
      document.title = to.meta.title || '财神云客服';
      next({ path: '/loginService' });
    }
    document.title = to.meta.title || '财神云客服';
    next();
  } else {
    sessionStorage.clear();
    document.title = to.meta.title || '财神云客服';
    next();
  }

});

//文档https://github.com/lin-xin/vue-manage-system/tree/V4.2.0
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
