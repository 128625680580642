(function (t, e) {
    "object" === typeof exports && "object" === typeof module ? module.exports = e() : "function" === typeof define && define.amd ? define([], e) : "object" === typeof exports ? exports["index"] = e() : t["index"] = e()
})("undefined" !== typeof self ? self : this, (function () {
    return function (t) {
        var e = {};

        function n(i) {
            if (e[i]) return e[i].exports;
            var r = e[i] = {i: i, l: !1, exports: {}};
            return t[i].call(r.exports, r, r.exports, n), r.l = !0, r.exports
        }

        return n.m = t, n.c = e, n.d = function (t, e, i) {
            n.o(t, e) || Object.defineProperty(t, e, {enumerable: !0, get: i})
        }, n.r = function (t) {
            "undefined" !== typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(t, "__esModule", {value: !0})
        }, n.t = function (t, e) {
            if (1 & e && (t = n(t)), 8 & e) return t;
            if (4 & e && "object" === typeof t && t && t.__esModule) return t;
            var i = Object.create(null);
            if (n.r(i), Object.defineProperty(i, "default", {
                enumerable: !0,
                value: t
            }), 2 & e && "string" != typeof t) for (var r in t) n.d(i, r, function (e) {
                return t[e]
            }.bind(null, r));
            return i
        }, n.n = function (t) {
            var e = t && t.__esModule ? function () {
                return t["default"]
            } : function () {
                return t
            };
            return n.d(e, "a", e), e
        }, n.o = function (t, e) {
            return Object.prototype.hasOwnProperty.call(t, e)
        }, n.p = "", n(n.s = "fb15")
    }({
        "01f9": function (t, e, n) {
            "use strict";
            var i = n("2d00"), r = n("5ca1"), o = n("2aba"), s = n("32e9"), a = n("84f2"), c = n("41a0"), l = n("7f20"),
                h = n("38fd"), d = n("2b4c")("iterator"), u = !([].keys && "next" in [].keys()), p = "@@iterator",
                f = "keys", m = "values", g = function () {
                    return this
                };
            t.exports = function (t, e, n, v, b, y, x) {
                c(n, e, v);
                var E, C, w, T = function (t) {
                        if (!u && t in S) return S[t];
                        switch (t) {
                            case f:
                                return function () {
                                    return new n(this, t)
                                };
                            case m:
                                return function () {
                                    return new n(this, t)
                                }
                        }
                        return function () {
                            return new n(this, t)
                        }
                    }, k = e + " Iterator", N = b == m, I = !1, S = t.prototype, _ = S[d] || S[p] || b && S[b],
                    L = _ || T(b), A = b ? N ? T("entries") : L : void 0, O = "Array" == e && S.entries || _;
                if (O && (w = h(O.call(new t)), w !== Object.prototype && w.next && (l(w, k, !0), i || "function" == typeof w[d] || s(w, d, g))), N && _ && _.name !== m && (I = !0, L = function () {
                    return _.call(this)
                }), i && !x || !u && !I && S[d] || s(S, d, L), a[e] = L, a[k] = g, b) if (E = {
                    values: N ? L : T(m),
                    keys: y ? L : T(f),
                    entries: A
                }, x) for (C in E) C in S || o(S, C, E[C]); else r(r.P + r.F * (u || I), e, E);
                return E
            }
        }, "02f4": function (t, e, n) {
            var i = n("4588"), r = n("be13");
            t.exports = function (t) {
                return function (e, n) {
                    var o, s, a = String(r(e)), c = i(n), l = a.length;
                    return c < 0 || c >= l ? t ? "" : void 0 : (o = a.charCodeAt(c), o < 55296 || o > 56319 || c + 1 === l || (s = a.charCodeAt(c + 1)) < 56320 || s > 57343 ? t ? a.charAt(c) : o : t ? a.slice(c, c + 2) : s - 56320 + (o - 55296 << 10) + 65536)
                }
            }
        }, "0390": function (t, e, n) {
            "use strict";
            var i = n("02f4")(!0);
            t.exports = function (t, e, n) {
                return e + (n ? i(t, e).length : 1)
            }
        }, "0a49": function (t, e, n) {
            var i = n("9b43"), r = n("626a"), o = n("4bf8"), s = n("9def"), a = n("cd1c");
            t.exports = function (t, e) {
                var n = 1 == t, c = 2 == t, l = 3 == t, h = 4 == t, d = 6 == t, u = 5 == t || d, p = e || a;
                return function (e, a, f) {
                    for (var m, g, v = o(e), b = r(v), y = i(a, f, 3), x = s(b.length), E = 0, C = n ? p(e, x) : c ? p(e, 0) : void 0; x > E; E++) if ((u || E in b) && (m = b[E], g = y(m, E, v), t)) if (n) C[E] = g; else if (g) switch (t) {
                        case 3:
                            return !0;
                        case 5:
                            return m;
                        case 6:
                            return E;
                        case 2:
                            C.push(m)
                    } else if (h) return !1;
                    return d ? -1 : l || h ? h : C
                }
            }
        }, "0bfb": function (t, e, n) {
            "use strict";
            var i = n("cb7c");
            t.exports = function () {
                var t = i(this), e = "";
                return t.global && (e += "g"), t.ignoreCase && (e += "i"), t.multiline && (e += "m"), t.unicode && (e += "u"), t.sticky && (e += "y"), e
            }
        }, "0d58": function (t, e, n) {
            var i = n("ce10"), r = n("e11e");
            t.exports = Object.keys || function (t) {
                return i(t, r)
            }
        }, "104c": function (t, e, n) {
        }, 1169: function (t, e, n) {
            var i = n("2d95");
            t.exports = Array.isArray || function (t) {
                return "Array" == i(t)
            }
        }, "117e": function (t, e, n) {
        }, "11e9": function (t, e, n) {
            var i = n("52a7"), r = n("4630"), o = n("6821"), s = n("6a99"), a = n("69a8"), c = n("c69a"),
                l = Object.getOwnPropertyDescriptor;
            e.f = n("9e1e") ? l : function (t, e) {
                if (t = o(t), e = s(e, !0), c) try {
                    return l(t, e)
                } catch (n) {
                }
                if (a(t, e)) return r(!i.f.call(t, e), t[e])
            }
        }, 1495: function (t, e, n) {
            var i = n("86cc"), r = n("cb7c"), o = n("0d58");
            t.exports = n("9e1e") ? Object.defineProperties : function (t, e) {
                r(t);
                var n, s = o(e), a = s.length, c = 0;
                while (a > c) i.f(t, n = s[c++], e[n]);
                return t
            }
        }, "1c4c": function (t, e, n) {
            "use strict";
            var i = n("9b43"), r = n("5ca1"), o = n("4bf8"), s = n("1fa8"), a = n("33a4"), c = n("9def"), l = n("f1ae"),
                h = n("27ee");
            r(r.S + r.F * !n("5cc5")((function (t) {
                Array.from(t)
            })), "Array", {
                from: function (t) {
                    var e, n, r, d, u = o(t), p = "function" == typeof this ? this : Array, f = arguments.length,
                        m = f > 1 ? arguments[1] : void 0, g = void 0 !== m, v = 0, b = h(u);
                    if (g && (m = i(m, f > 2 ? arguments[2] : void 0, 2)), void 0 == b || p == Array && a(b)) for (e = c(u.length), n = new p(e); e > v; v++) l(n, v, g ? m(u[v], v) : u[v]); else for (d = b.call(u), n = new p; !(r = d.next()).done; v++) l(n, v, g ? s(d, m, [r.value, v], !0) : r.value);
                    return n.length = v, n
                }
            })
        }, "1fa8": function (t, e, n) {
            var i = n("cb7c");
            t.exports = function (t, e, n, r) {
                try {
                    return r ? e(i(n)[0], n[1]) : e(n)
                } catch (s) {
                    var o = t["return"];
                    throw void 0 !== o && i(o.call(t)), s
                }
            }
        }, "20d6": function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("0a49")(6), o = "findIndex", s = !0;
            o in [] && Array(1)[o]((function () {
                s = !1
            })), i(i.P + i.F * s, "Array", {
                findIndex: function (t) {
                    return r(this, t, arguments.length > 1 ? arguments[1] : void 0)
                }
            }), n("9c6c")(o)
        }, "214f": function (t, e, n) {
            "use strict";
            n("b0c5");
            var i = n("2aba"), r = n("32e9"), o = n("79e5"), s = n("be13"), a = n("2b4c"), c = n("520a"),
                l = a("species"), h = !o((function () {
                    var t = /./;
                    return t.exec = function () {
                        var t = [];
                        return t.groups = {a: "7"}, t
                    }, "7" !== "".replace(t, "$<a>")
                })), d = function () {
                    var t = /(?:)/, e = t.exec;
                    t.exec = function () {
                        return e.apply(this, arguments)
                    };
                    var n = "ab".split(t);
                    return 2 === n.length && "a" === n[0] && "b" === n[1]
                }();
            t.exports = function (t, e, n) {
                var u = a(t), p = !o((function () {
                    var e = {};
                    return e[u] = function () {
                        return 7
                    }, 7 != ""[t](e)
                })), f = p ? !o((function () {
                    var e = !1, n = /a/;
                    return n.exec = function () {
                        return e = !0, null
                    }, "split" === t && (n.constructor = {}, n.constructor[l] = function () {
                        return n
                    }), n[u](""), !e
                })) : void 0;
                if (!p || !f || "replace" === t && !h || "split" === t && !d) {
                    var m = /./[u], g = n(s, u, ""[t], (function (t, e, n, i, r) {
                        return e.exec === c ? p && !r ? {done: !0, value: m.call(e, n, i)} : {
                            done: !0,
                            value: t.call(n, e, i)
                        } : {done: !1}
                    })), v = g[0], b = g[1];
                    i(String.prototype, t, v), r(RegExp.prototype, u, 2 == e ? function (t, e) {
                        return b.call(t, this, e)
                    } : function (t) {
                        return b.call(t, this)
                    })
                }
            }
        }, "230e": function (t, e, n) {
            var i = n("d3f4"), r = n("7726").document, o = i(r) && i(r.createElement);
            t.exports = function (t) {
                return o ? r.createElement(t) : {}
            }
        }, "23c6": function (t, e, n) {
            var i = n("2d95"), r = n("2b4c")("toStringTag"), o = "Arguments" == i(function () {
                return arguments
            }()), s = function (t, e) {
                try {
                    return t[e]
                } catch (n) {
                }
            };
            t.exports = function (t) {
                var e, n, a;
                return void 0 === t ? "Undefined" : null === t ? "Null" : "string" == typeof (n = s(e = Object(t), r)) ? n : o ? i(e) : "Object" == (a = i(e)) && "function" == typeof e.callee ? "Arguments" : a
            }
        }, 2621: function (t, e) {
            e.f = Object.getOwnPropertySymbols
        }, 2638: function (t, e, n) {
            "use strict";

            function i() {
                return i = Object.assign ? Object.assign.bind() : function (t) {
                    for (var e, n = 1; n < arguments.length; n++) for (var i in e = arguments[n], e) Object.prototype.hasOwnProperty.call(e, i) && (t[i] = e[i]);
                    return t
                }, i.apply(this, arguments)
            }

            var r = ["attrs", "props", "domProps"], o = ["class", "style", "directives"], s = ["on", "nativeOn"],
                a = function (t) {
                    return t.reduce((function (t, e) {
                        for (var n in e) if (t[n]) if (-1 !== r.indexOf(n)) t[n] = i({}, t[n], e[n]); else if (-1 !== o.indexOf(n)) {
                            var a = t[n] instanceof Array ? t[n] : [t[n]], l = e[n] instanceof Array ? e[n] : [e[n]];
                            t[n] = [].concat(a, l)
                        } else if (-1 !== s.indexOf(n)) for (var h in e[n]) if (t[n][h]) {
                            var d = t[n][h] instanceof Array ? t[n][h] : [t[n][h]],
                                u = e[n][h] instanceof Array ? e[n][h] : [e[n][h]];
                            t[n][h] = [].concat(d, u)
                        } else t[n][h] = e[n][h]; else if ("hook" === n) for (var p in e[n]) t[n][p] = t[n][p] ? c(t[n][p], e[n][p]) : e[n][p]; else t[n] = e[n]; else t[n] = e[n];
                        return t
                    }), {})
                }, c = function (t, e) {
                    return function () {
                        t && t.apply(this, arguments), e && e.apply(this, arguments)
                    }
                };
            t.exports = a
        }, "27ee": function (t, e, n) {
            var i = n("23c6"), r = n("2b4c")("iterator"), o = n("84f2");
            t.exports = n("8378").getIteratorMethod = function (t) {
                if (void 0 != t) return t[r] || t["@@iterator"] || o[i(t)]
            }
        }, "2aba": function (t, e, n) {
            var i = n("7726"), r = n("32e9"), o = n("69a8"), s = n("ca5a")("src"), a = n("fa5b"), c = "toString",
                l = ("" + a).split(c);
            n("8378").inspectSource = function (t) {
                return a.call(t)
            }, (t.exports = function (t, e, n, a) {
                var c = "function" == typeof n;
                c && (o(n, "name") || r(n, "name", e)), t[e] !== n && (c && (o(n, s) || r(n, s, t[e] ? "" + t[e] : l.join(String(e)))), t === i ? t[e] = n : a ? t[e] ? t[e] = n : r(t, e, n) : (delete t[e], r(t, e, n)))
            })(Function.prototype, c, (function () {
                return "function" == typeof this && this[s] || a.call(this)
            }))
        }, "2aeb": function (t, e, n) {
            var i = n("cb7c"), r = n("1495"), o = n("e11e"), s = n("613b")("IE_PROTO"), a = function () {
            }, c = "prototype", l = function () {
                var t, e = n("230e")("iframe"), i = o.length, r = "<", s = ">";
                e.style.display = "none", n("fab2").appendChild(e), e.src = "javascript:", t = e.contentWindow.document, t.open(), t.write(r + "script" + s + "document.F=Object" + r + "/script" + s), t.close(), l = t.F;
                while (i--) delete l[c][o[i]];
                return l()
            };
            t.exports = Object.create || function (t, e) {
                var n;
                return null !== t ? (a[c] = i(t), n = new a, a[c] = null, n[s] = t) : n = l(), void 0 === e ? n : r(n, e)
            }
        }, "2b4c": function (t, e, n) {
            var i = n("5537")("wks"), r = n("ca5a"), o = n("7726").Symbol, s = "function" == typeof o,
                a = t.exports = function (t) {
                    return i[t] || (i[t] = s && o[t] || (s ? o : r)("Symbol." + t))
                };
            a.store = i
        }, "2d00": function (t, e) {
            t.exports = !1
        }, "2d95": function (t, e) {
            var n = {}.toString;
            t.exports = function (t) {
                return n.call(t).slice(8, -1)
            }
        }, "2f21": function (t, e, n) {
            "use strict";
            var i = n("79e5");
            t.exports = function (t, e) {
                return !!t && i((function () {
                    e ? t.call(null, (function () {
                    }), 1) : t.call(null)
                }))
            }
        }, "2fdb": function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("d2c8"), o = "includes";
            i(i.P + i.F * n("5147")(o), "String", {
                includes: function (t) {
                    return !!~r(this, t, o).indexOf(t, arguments.length > 1 ? arguments[1] : void 0)
                }
            })
        }, "323d": function (t, e, n) {
        }, "32e9": function (t, e, n) {
            var i = n("86cc"), r = n("4630");
            t.exports = n("9e1e") ? function (t, e, n) {
                return i.f(t, e, r(1, n))
            } : function (t, e, n) {
                return t[e] = n, t
            }
        }, "33a4": function (t, e, n) {
            var i = n("84f2"), r = n("2b4c")("iterator"), o = Array.prototype;
            t.exports = function (t) {
                return void 0 !== t && (i.Array === t || o[r] === t)
            }
        }, 3846: function (t, e, n) {
            n("9e1e") && "g" != /./g.flags && n("86cc").f(RegExp.prototype, "flags", {configurable: !0, get: n("0bfb")})
        }, "38fd": function (t, e, n) {
            var i = n("69a8"), r = n("4bf8"), o = n("613b")("IE_PROTO"), s = Object.prototype;
            t.exports = Object.getPrototypeOf || function (t) {
                return t = r(t), i(t, o) ? t[o] : "function" == typeof t.constructor && t instanceof t.constructor ? t.constructor.prototype : t instanceof Object ? s : null
            }
        }, "3b2b": function (t, e, n) {
            var i = n("7726"), r = n("5dbc"), o = n("86cc").f, s = n("9093").f, a = n("aae3"), c = n("0bfb"),
                l = i.RegExp, h = l, d = l.prototype, u = /a/g, p = /a/g, f = new l(u) !== u;
            if (n("9e1e") && (!f || n("79e5")((function () {
                return p[n("2b4c")("match")] = !1, l(u) != u || l(p) == p || "/a/i" != l(u, "i")
            })))) {
                l = function (t, e) {
                    var n = this instanceof l, i = a(t), o = void 0 === e;
                    return !n && i && t.constructor === l && o ? t : r(f ? new h(i && !o ? t.source : t, e) : h((i = t instanceof l) ? t.source : t, i && o ? c.call(t) : e), n ? this : d, l)
                };
                for (var m = function (t) {
                    t in l || o(l, t, {
                        configurable: !0, get: function () {
                            return h[t]
                        }, set: function (e) {
                            h[t] = e
                        }
                    })
                }, g = s(h), v = 0; g.length > v;) m(g[v++]);
                d.constructor = l, l.prototype = d, n("2aba")(i, "RegExp", l)
            }
            n("7a56")("RegExp")
        }, "41a0": function (t, e, n) {
            "use strict";
            var i = n("2aeb"), r = n("4630"), o = n("7f20"), s = {};
            n("32e9")(s, n("2b4c")("iterator"), (function () {
                return this
            })), t.exports = function (t, e, n) {
                t.prototype = i(s, {next: r(1, n)}), o(t, e + " Iterator")
            }
        }, "456d": function (t, e, n) {
            var i = n("4bf8"), r = n("0d58");
            n("5eda")("keys", (function () {
                return function (t) {
                    return r(i(t))
                }
            }))
        }, 4588: function (t, e) {
            var n = Math.ceil, i = Math.floor;
            t.exports = function (t) {
                return isNaN(t = +t) ? 0 : (t > 0 ? i : n)(t)
            }
        }, 4630: function (t, e) {
            t.exports = function (t, e) {
                return {enumerable: !(1 & t), configurable: !(2 & t), writable: !(4 & t), value: e}
            }
        }, "4bf8": function (t, e, n) {
            var i = n("be13");
            t.exports = function (t) {
                return Object(i(t))
            }
        }, "4c77": function (t, e, n) {
            "use strict";
            n("d6f8")
        }, "504c": function (t, e, n) {
            var i = n("9e1e"), r = n("0d58"), o = n("6821"), s = n("52a7").f;
            t.exports = function (t) {
                return function (e) {
                    var n, a = o(e), c = r(a), l = c.length, h = 0, d = [];
                    while (l > h) n = c[h++], i && !s.call(a, n) || d.push(t ? [n, a[n]] : a[n]);
                    return d
                }
            }
        }, 5147: function (t, e, n) {
            var i = n("2b4c")("match");
            t.exports = function (t) {
                var e = /./;
                try {
                    "/./"[t](e)
                } catch (n) {
                    try {
                        return e[i] = !1, !"/./"[t](e)
                    } catch (r) {
                    }
                }
                return !0
            }
        }, "520a": function (t, e, n) {
            "use strict";
            var i = n("0bfb"), r = RegExp.prototype.exec, o = String.prototype.replace, s = r, a = "lastIndex",
                c = function () {
                    var t = /a/, e = /b*/g;
                    return r.call(t, "a"), r.call(e, "a"), 0 !== t[a] || 0 !== e[a]
                }(), l = void 0 !== /()??/.exec("")[1], h = c || l;
            h && (s = function (t) {
                var e, n, s, h, d = this;
                return l && (n = new RegExp("^" + d.source + "$(?!\\s)", i.call(d))), c && (e = d[a]), s = r.call(d, t), c && s && (d[a] = d.global ? s.index + s[0].length : e), l && s && s.length > 1 && o.call(s[0], n, (function () {
                    for (h = 1; h < arguments.length - 2; h++) void 0 === arguments[h] && (s[h] = void 0)
                })), s
            }), t.exports = s
        }, "52a7": function (t, e) {
            e.f = {}.propertyIsEnumerable
        }, 5537: function (t, e, n) {
            var i = n("8378"), r = n("7726"), o = "__core-js_shared__", s = r[o] || (r[o] = {});
            (t.exports = function (t, e) {
                return s[t] || (s[t] = void 0 !== e ? e : {})
            })("versions", []).push({
                version: i.version,
                mode: n("2d00") ? "pure" : "global",
                copyright: "© 2020 Denis Pushkarev (zloirock.ru)"
            })
        }, "55dd": function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("d8e8"), o = n("4bf8"), s = n("79e5"), a = [].sort, c = [1, 2, 3];
            i(i.P + i.F * (s((function () {
                c.sort(void 0)
            })) || !s((function () {
                c.sort(null)
            })) || !n("2f21")(a)), "Array", {
                sort: function (t) {
                    return void 0 === t ? a.call(o(this)) : a.call(o(this), r(t))
                }
            })
        }, 5778: function (t, e, n) {
            "use strict";
            n("80be")
        }, "5ca1": function (t, e, n) {
            var i = n("7726"), r = n("8378"), o = n("32e9"), s = n("2aba"), a = n("9b43"), c = "prototype",
                l = function (t, e, n) {
                    var h, d, u, p, f = t & l.F, m = t & l.G, g = t & l.S, v = t & l.P, b = t & l.B,
                        y = m ? i : g ? i[e] || (i[e] = {}) : (i[e] || {})[c], x = m ? r : r[e] || (r[e] = {}),
                        E = x[c] || (x[c] = {});
                    for (h in m && (n = e), n) d = !f && y && void 0 !== y[h], u = (d ? y : n)[h], p = b && d ? a(u, i) : v && "function" == typeof u ? a(Function.call, u) : u, y && s(y, h, u, t & l.U), x[h] != u && o(x, h, p), v && E[h] != u && (E[h] = u)
                };
            i.core = r, l.F = 1, l.G = 2, l.S = 4, l.P = 8, l.B = 16, l.W = 32, l.U = 64, l.R = 128, t.exports = l
        }, "5cc5": function (t, e, n) {
            var i = n("2b4c")("iterator"), r = !1;
            try {
                var o = [7][i]();
                o["return"] = function () {
                    r = !0
                }, Array.from(o, (function () {
                    throw 2
                }))
            } catch (s) {
            }
            t.exports = function (t, e) {
                if (!e && !r) return !1;
                var n = !1;
                try {
                    var o = [7], a = o[i]();
                    a.next = function () {
                        return {done: n = !0}
                    }, o[i] = function () {
                        return a
                    }, t(o)
                } catch (s) {
                }
                return n
            }
        }, "5dbc": function (t, e, n) {
            var i = n("d3f4"), r = n("8b97").set;
            t.exports = function (t, e, n) {
                var o, s = e.constructor;
                return s !== n && "function" == typeof s && (o = s.prototype) !== n.prototype && i(o) && r && r(t, o), t
            }
        }, "5df3": function (t, e, n) {
            "use strict";
            var i = n("02f4")(!0);
            n("01f9")(String, "String", (function (t) {
                this._t = String(t), this._i = 0
            }), (function () {
                var t, e = this._t, n = this._i;
                return n >= e.length ? {value: void 0, done: !0} : (t = i(e, n), this._i += t.length, {
                    value: t,
                    done: !1
                })
            }))
        }, "5eda": function (t, e, n) {
            var i = n("5ca1"), r = n("8378"), o = n("79e5");
            t.exports = function (t, e) {
                var n = (r.Object || {})[t] || Object[t], s = {};
                s[t] = e(n), i(i.S + i.F * o((function () {
                    n(1)
                })), "Object", s)
            }
        }, "5f1b": function (t, e, n) {
            "use strict";
            var i = n("23c6"), r = RegExp.prototype.exec;
            t.exports = function (t, e) {
                var n = t.exec;
                if ("function" === typeof n) {
                    var o = n.call(t, e);
                    if ("object" !== typeof o) throw new TypeError("RegExp exec method returned something other than an Object or null");
                    return o
                }
                if ("RegExp" !== i(t)) throw new TypeError("RegExp#exec called on incompatible receiver");
                return r.call(t, e)
            }
        }, "613b": function (t, e, n) {
            var i = n("5537")("keys"), r = n("ca5a");
            t.exports = function (t) {
                return i[t] || (i[t] = r(t))
            }
        }, "61c8": function (t, e, n) {
        }, "626a": function (t, e, n) {
            var i = n("2d95");
            t.exports = Object("z").propertyIsEnumerable(0) ? Object : function (t) {
                return "String" == i(t) ? t.split("") : Object(t)
            }
        }, "631a": function (t, e, n) {
            "use strict";
            n("104c")
        }, 6762: function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("c366")(!0);
            i(i.P, "Array", {
                includes: function (t) {
                    return r(this, t, arguments.length > 1 ? arguments[1] : void 0)
                }
            }), n("9c6c")("includes")
        }, 6821: function (t, e, n) {
            var i = n("626a"), r = n("be13");
            t.exports = function (t) {
                return i(r(t))
            }
        }, "69a8": function (t, e) {
            var n = {}.hasOwnProperty;
            t.exports = function (t, e) {
                return n.call(t, e)
            }
        }, "69bb": function (t, e, n) {
            "use strict";
            n("ba05")
        }, "6a2b": function (t, e, n) {
        }, "6a99": function (t, e, n) {
            var i = n("d3f4");
            t.exports = function (t, e) {
                if (!i(t)) return t;
                var n, r;
                if (e && "function" == typeof (n = t.toString) && !i(r = n.call(t))) return r;
                if ("function" == typeof (n = t.valueOf) && !i(r = n.call(t))) return r;
                if (!e && "function" == typeof (n = t.toString) && !i(r = n.call(t))) return r;
                throw TypeError("Can't convert object to primitive value")
            }
        }, "6b54": function (t, e, n) {
            "use strict";
            n("3846");
            var i = n("cb7c"), r = n("0bfb"), o = n("9e1e"), s = "toString", a = /./[s], c = function (t) {
                n("2aba")(RegExp.prototype, s, t, !0)
            };
            n("79e5")((function () {
                return "/a/b" != a.call({source: "a", flags: "b"})
            })) ? c((function () {
                var t = i(this);
                return "/".concat(t.source, "/", "flags" in t ? t.flags : !o && t instanceof RegExp ? r.call(t) : void 0)
            })) : a.name != s && c((function () {
                return a.call(this)
            }))
        }, "6fb5": function (t, e, n) {
        }, "718e": function (t, e, n) {
            "use strict";
            n("dfa9")
        }, 7333: function (t, e, n) {
            "use strict";
            var i = n("9e1e"), r = n("0d58"), o = n("2621"), s = n("52a7"), a = n("4bf8"), c = n("626a"),
                l = Object.assign;
            t.exports = !l || n("79e5")((function () {
                var t = {}, e = {}, n = Symbol(), i = "abcdefghijklmnopqrst";
                return t[n] = 7, i.split("").forEach((function (t) {
                    e[t] = t
                })), 7 != l({}, t)[n] || Object.keys(l({}, e)).join("") != i
            })) ? function (t, e) {
                var n = a(t), l = arguments.length, h = 1, d = o.f, u = s.f;
                while (l > h) {
                    var p, f = c(arguments[h++]), m = d ? r(f).concat(d(f)) : r(f), g = m.length, v = 0;
                    while (g > v) p = m[v++], i && !u.call(f, p) || (n[p] = f[p])
                }
                return n
            } : l
        }, 7514: function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("0a49")(5), o = "find", s = !0;
            o in [] && Array(1)[o]((function () {
                s = !1
            })), i(i.P + i.F * s, "Array", {
                find: function (t) {
                    return r(this, t, arguments.length > 1 ? arguments[1] : void 0)
                }
            }), n("9c6c")(o)
        }, 7726: function (t, e) {
            var n = t.exports = "undefined" != typeof window && window.Math == Math ? window : "undefined" != typeof self && self.Math == Math ? self : Function("return this")();
            "number" == typeof __g && (__g = n)
        }, "77f1": function (t, e, n) {
            var i = n("4588"), r = Math.max, o = Math.min;
            t.exports = function (t, e) {
                return t = i(t), t < 0 ? r(t + e, 0) : o(t, e)
            }
        }, "79e5": function (t, e) {
            t.exports = function (t) {
                try {
                    return !!t()
                } catch (e) {
                    return !0
                }
            }
        }, "7a56": function (t, e, n) {
            "use strict";
            var i = n("7726"), r = n("86cc"), o = n("9e1e"), s = n("2b4c")("species");
            t.exports = function (t) {
                var e = i[t];
                o && e && !e[s] && r.f(e, s, {
                    configurable: !0, get: function () {
                        return this
                    }
                })
            }
        }, "7f20": function (t, e, n) {
            var i = n("86cc").f, r = n("69a8"), o = n("2b4c")("toStringTag");
            t.exports = function (t, e, n) {
                t && !r(t = n ? t : t.prototype, o) && i(t, o, {configurable: !0, value: e})
            }
        }, "7f7f": function (t, e, n) {
            var i = n("86cc").f, r = Function.prototype, o = /^\s*function ([^ (]*)/, s = "name";
            s in r || n("9e1e") && i(r, s, {
                configurable: !0, get: function () {
                    try {
                        return ("" + this).match(o)[1]
                    } catch (t) {
                        return ""
                    }
                }
            })
        }, "80be": function (t, e, n) {
        }, 8378: function (t, e) {
            var n = t.exports = {version: "2.6.12"};
            "number" == typeof __e && (__e = n)
        }, "83a1": function (t, e, n) {
        }, "84f2": function (t, e) {
            t.exports = {}
        }, "85ff": function (t, e, n) {
            "use strict";
            n("cb50")
        }, 8615: function (t, e, n) {
            var i = n("5ca1"), r = n("504c")(!1);
            i(i.S, "Object", {
                values: function (t) {
                    return r(t)
                }
            })
        }, "86cc": function (t, e, n) {
            var i = n("cb7c"), r = n("c69a"), o = n("6a99"), s = Object.defineProperty;
            e.f = n("9e1e") ? Object.defineProperty : function (t, e, n) {
                if (i(t), e = o(e, !0), i(n), r) try {
                    return s(t, e, n)
                } catch (a) {
                }
                if ("get" in n || "set" in n) throw TypeError("Accessors not supported!");
                return "value" in n && (t[e] = n.value), t
            }
        }, "8b97": function (t, e, n) {
            var i = n("d3f4"), r = n("cb7c"), o = function (t, e) {
                if (r(t), !i(e) && null !== e) throw TypeError(e + ": can't set as prototype!")
            };
            t.exports = {
                set: Object.setPrototypeOf || ("__proto__" in {} ? function (t, e, i) {
                    try {
                        i = n("9b43")(Function.call, n("11e9").f(Object.prototype, "__proto__").set, 2), i(t, []), e = !(t instanceof Array)
                    } catch (r) {
                        e = !0
                    }
                    return function (t, n) {
                        return o(t, n), e ? t.__proto__ = n : i(t, n), t
                    }
                }({}, !1) : void 0), check: o
            }
        }, "8bcf": function (t, e, n) {
        }, "8e6e": function (t, e, n) {
            var i = n("5ca1"), r = n("990b"), o = n("6821"), s = n("11e9"), a = n("f1ae");
            i(i.S, "Object", {
                getOwnPropertyDescriptors: function (t) {
                    var e, n, i = o(t), c = s.f, l = r(i), h = {}, d = 0;
                    while (l.length > d) n = c(i, e = l[d++]), void 0 !== n && a(h, e, n);
                    return h
                }
            })
        }, "8fb6": function (t, e, n) {
            "use strict";
            n("83a1")
        }, 9093: function (t, e, n) {
            var i = n("ce10"), r = n("e11e").concat("length", "prototype");
            e.f = Object.getOwnPropertyNames || function (t) {
                return i(t, r)
            }
        }, 9204: function (t, e) {
            var n = Object.defineProperty, i = (t, e, i) => e in t ? n(t, e, {
                enumerable: !0,
                configurable: !0,
                writable: !0,
                value: i
            }) : t[e] = i, r = (t, e, n) => (i(t, "symbol" != typeof e ? e + "" : e, n), n);
            (function () {
                const t = document.createElement("link").relList;
                if (!(t && t.supports && t.supports("modulepreload"))) {
                    for (const t of document.querySelectorAll('link[rel="modulepreload"]')) n(t);
                    new MutationObserver(t => {
                        for (const e of t) if ("childList" === e.type) for (const t of e.addedNodes) "LINK" === t.tagName && "modulepreload" === t.rel && n(t)
                    }).observe(document, {childList: !0, subtree: !0})
                }

                function e(t) {
                    const e = {};
                    return t.integrity && (e.integrity = t.integrity), t.referrerPolicy && (e.referrerPolicy = t.referrerPolicy), "use-credentials" === t.crossOrigin ? e.credentials = "include" : "anonymous" === t.crossOrigin ? e.credentials = "omit" : e.credentials = "same-origin", e
                }

                function n(t) {
                    if (t.ep) return;
                    t.ep = !0;
                    const n = e(t);
                    fetch(t.href, n)
                }
            })();

            class o {
                constructor(t) {
                    r(this, "richText"), r(this, "vnode"), r(this, "cursorIndex"), r(this, "cursorLeft"), r(this, "needCallSpace", !1), r(this, "VOID_KEY", "\ufeff"), r(this, "ZERO_WIDTH_KEY", "​"), r(this, "pointEndIME"), r(this, "IME_KEY", "​_"), this.richText = t, this.textInnerHtmlInit(), this.richText.focus()
                }

                textInnerHtmlInit(t = !1, e) {
                    if (t || this.getNodeEmpty(this.richText)) {
                        this.richText.innerHTML = "";
                        const t = this.getGridElm();
                        this.richText.appendChild(t);
                        const n = t.children[0].children[0];
                        e && (n.innerHTML = e, n.setAttribute("data-set-empty", "false"));
                        const i = n.childNodes[0];
                        this.restCursorPos(i, i.textContent === this.VOID_KEY ? 1 : i.textContent.length)
                    }
                }

                onceCall(t, e = !1, n = "") {
                    return new Promise(i => {
                        const r = this.createAtUserSpan(t), o = this.replaceRegContent(r, !0, e, n) || "";
                        i(o)
                    })
                }

                searchCall(t, e) {
                    return new Promise(n => {
                        const i = this.createAtUserSpan(t);
                        this.replaceRegContent(i, e), n()
                    })
                }

                onceExternalCall(t) {
                    return new Promise(e => {
                        const n = this.createAtUserSpan(t), i = this.replaceRegContent(n, !1, !0) || "";
                        e(i)
                    })
                }

                upDataNodeOrIndex() {
                    var t, e, n;
                    const {focusNode: i, focusOffset: r, anchorOffset: o} = window.getSelection(),
                        s = (null == i ? void 0 : i.parentNode) || void 0;
                    !s || !s.getAttribute || "richInput" !== s.getAttribute("data-set-richType") || (null == (n = null == (e = null == (t = null == i ? void 0 : i.parentNode) ? void 0 : t.parentNode) ? void 0 : e.parentNode) ? void 0 : n.parentNode) !== this.richText || (this.vnode = i, this.cursorIndex = r, this.cursorLeft = o < r ? o : r)
                }

                showAt() {
                    if (this.upDataNodeOrIndex(), !this.vnode || this.vnode.nodeType !== Node.TEXT_NODE) return !1;
                    const t = this.vnode.textContent || "", e = /@([^@\s]*)$/, n = t.slice(0, this.cursorIndex),
                        i = e.exec(n);
                    return i && 2 === i.length && "@" === n[n.length - 1]
                }

                getRangeRect() {
                    let t = 0, e = 0;
                    const n = window.getSelection().getRangeAt(0).getClientRects()[0];
                    return n && (t = n.x, e = n.y), {x: t, y: e}
                }

                createAtUserSpan(t) {
                    const e = document.createElement("span");
                    return e.dataset.userId = String(t.id), e.className = "at-user", e.contentEditable = "false", e.textContent = `@${t.name}${this.needCallSpace ? " " : ""}`, this.createNewDom(e)
                }

                createNewDom(t) {
                    const e = document.createElement("span");
                    return e.className = "chat-tag", e.setAttribute("contenteditable", "false"), e.setAttribute("data-set-richType", "chatTag"), t.className += " chat-stat", e.appendChild(t), e
                }

                restCursorPos(t, e) {
                    null == e ? e = t.textContent === this.VOID_KEY ? 1 : 0 : e > t.textContent.length && (e = t.textContent.length);
                    const n = new Range;
                    n.setStart(t, e), n.setEnd(t, e);
                    const i = window.getSelection();
                    i && (this.vnode = t, this.cursorIndex = e, this.cursorLeft = e, i.removeAllRanges(), i.addRange(n))
                }

                setCursorPos(t, e) {
                    const n = window.getSelection();
                    if (n.rangeCount <= 0) return;
                    const i = n.getRangeAt(0);
                    i.setStart(t, e), i.collapse(!0), this.vnode = t, this.cursorIndex = e, this.cursorLeft = e
                }

                replaceRegContent(t, e = !1, n = !1, i = "") {
                    const r = this.vnode.textContent;
                    let o;
                    o = "boolean" == typeof e ? r.slice(0, e ? this.cursorIndex - 1 : this.cursorIndex) : r.slice(0, e - 1), 0 === o.length ? (this.vnode.parentElement.setAttribute("data-set-empty", !0), this.vnode.textContent = this.VOID_KEY) : this.vnode.textContent = o;
                    let s = i || r.slice(this.cursorIndex);
                    const a = this.vnode.parentNode.parentNode, c = a.nextSibling;
                    c ? a.parentNode.insertBefore(t, c) : a.parentNode.appendChild(t);
                    const l = t.previousSibling.childNodes[0], h = l.childNodes[1];
                    h && l.removeChild(h);
                    const d = this.getGridElm(!0), u = d.childNodes[0];
                    !n && s && s !== this.VOID_KEY && (u.setAttribute("data-set-empty", "false"), u.innerHTML = s);
                    const p = u.childNodes[1];
                    return n && p && u.removeChild(p), t.nextSibling ? (p && u.removeChild(p), a.parentNode.insertBefore(d, t.nextSibling)) : a.parentNode.appendChild(d), this.restCursorPos(u.childNodes[0]), s
                }

                switchRange(t) {
                    var e, n;
                    const {focusNode: i, focusOffset: r} = window.getSelection();
                    let o, s;
                    if (i.nodeType === Node.TEXT_NODE) {
                        const a = i.textContent.length, c = i.parentNode.parentNode;
                        switch (t) {
                            case"ArrowLeft":
                                if (r > 0 && i.textContent !== this.VOID_KEY) {
                                    s = r - 1, o = i;
                                    break
                                }
                                const t = null == (e = null == c ? void 0 : c.previousSibling) ? void 0 : e.previousSibling;
                                if (t) o = t.childNodes[0].childNodes[0], s = o.textContent.length; else {
                                    const t = c.parentNode.previousSibling;
                                    t && (o = t.lastChild.childNodes[0].childNodes[0], s = o.textContent.length)
                                }
                                break;
                            case"ArrowRight":
                                if (r < a && i.textContent !== this.VOID_KEY) {
                                    s = r + 1, o = i;
                                    break
                                }
                                const l = null == (n = null == c ? void 0 : c.nextSibling) ? void 0 : n.nextSibling;
                                if (l) o = l.childNodes[0].childNodes[0], s = o.textContent === this.VOID_KEY ? 1 : 0; else {
                                    const t = c.parentNode.nextSibling;
                                    t && (o = t.childNodes[0].childNodes[0].childNodes[0], s = o.textContent === this.VOID_KEY ? 1 : 0)
                                }
                                break
                        }
                    }
                    (s || 0 === s) && this.restCursorPos(o, s)
                }

                getGridElm(t = !1) {
                    const e = document.createElement("span");
                    if (e.setAttribute("data-set-richType", "richMark"), e.innerHTML = `<span class="chat-grid-input chat-stat" data-set-richType="richInput" data-set-empty="true">${this.VOID_KEY}<br></span>`, t) return e;
                    const n = document.createElement("p");
                    return n.className = "chat-grid-wrap", n.setAttribute("data-set-richType", "richBox"), n.appendChild(e), n
                }

                updateGrid() {
                    const t = window.getSelection(), e = t.focusNode, n = e.parentNode,
                        i = n.getAttribute("data-set-richType");
                    let r, o, s, a;
                    switch (i) {
                        case"richAllBox":
                            if (r = e.childNodes[t.focusOffset], !r || "chatTag" === r.getAttribute("data-set-richType")) {
                                const t = this.getGridElm(!0), n = t.children[0];
                                r ? (n.removeChild(n.childNodes[1]), e.insertBefore(t, r)) : e.appendChild(t), this.restCursorPos(n.childNodes[0]);
                                break
                            }
                            if ("BR" === r.tagName) {
                                const t = this.getGridElm(!0), n = t.children[0];
                                e.insertBefore(t, r), e.removeChild(r), this.restCursorPos(n.childNodes[0], n.childNodes[0].textContent.length)
                            }
                            break;
                        case"richMark":
                            const i = n.parentNode, c = Array.prototype.indexOf.call(i.childNodes, n);
                            if (-1 === c) break;
                            if (0 === c) {
                                const e = t.focusNode;
                                e.setAttribute("data-set-empty", "true"), e.innerHTML = this.VOID_KEY + "<br>", r = e.childNodes[0], this.restCursorPos(r, r.textContent.length);
                                break
                            }
                            let l, h = n.previousSibling;
                            "chatTag" === h.getAttribute("data-set-richType") ? (l = h.previousSibling, i.removeChild(h), i.removeChild(n)) : (l = n.previousSibling, i.removeChild(n)), r = l.childNodes[0].childNodes[0], r.textContent === this.VOID_KEY && r.parentNode.appendChild(document.createElement("br")), this.restCursorPos(r, r.textContent.length);
                            break;
                        case"richInput":
                            if (a = n.parentNode, s = a.parentNode, this.getNodeEmpty(n)) {
                                n.setAttribute("data-set-empty", "true"), s.childNodes[s.childNodes.length - 1] === a && (n.innerHTML = this.VOID_KEY + "<br>"), r = n.childNodes[0], this.restCursorPos(r, r.textContent.length);
                                break
                            }
                            if ("true" === String(n.getAttribute("data-set-empty"))) {
                                n.setAttribute("data-set-empty", "false");
                                const t = [], e = [];
                                Array.prototype.forEach.call(n.childNodes, n => {
                                    n.nodeType === Node.TEXT_NODE && -1 !== n.textContent.indexOf(this.VOID_KEY) ? e.push(n) : "BR" === n.tagName && t.push(n)
                                }), t.forEach(t => {
                                    n.removeChild(t)
                                }), e.forEach(t => {
                                    const e = t.textContent.indexOf(this.VOID_KEY), n = new Range;
                                    n.setStart(t, t.textContent.indexOf(this.VOID_KEY)), n.setEnd(t, e + 1), n.deleteContents()
                                }), r = n.childNodes[0], this.restCursorPos(r, r.textContent.length)
                            }
                            if (o = n.parentNode.nextSibling, o && o.nodeType === Node.TEXT_NODE) {
                                let t = o.textContent, e = this.getGridElm(!0);
                                e.childNodes[0].textContent = t, e.childNodes[0].setAttribute("data-set-empty", "false"), o.parentNode.insertBefore(e, o), o.parentNode.removeChild(o), o = e
                            }
                            o && "richMark" === o.getAttribute("data-set-richType") && this.markMerge(n.parentNode, o);
                            break
                    }
                }

                getNodeEmpty(t) {
                    const e = new RegExp(`^(${this.ZERO_WIDTH_KEY}|<br>|${this.VOID_KEY})+$`);
                    return !t.innerHTML || e.test(t.innerHTML)
                }

                setWrap() {
                    const t = window.getSelection();
                    let {focusNode: e, focusOffset: n} = t;
                    if (e.nodeType !== Node.TEXT_NODE) {
                        if (!e.getAttribute || "richInput" !== e.getAttribute("data-set-richType")) return;
                        e = e.childNodes[0]
                    }
                    const i = e.textContent.slice(n), r = e.parentNode.parentNode, o = r.parentNode,
                        s = Array.prototype.indexOf.call(o.childNodes, r),
                        a = Array.prototype.slice.call(o.childNodes, s + 1), c = this.getGridElm();
                    let l = c.children[0].children[0].childNodes[0], h = 1;
                    (i || a.length > 0) && l.parentNode.removeChild(l.parentNode.childNodes[1]), i && i !== this.VOID_KEY && (e.textContent = e.textContent.slice(0, n), l.textContent = (l.textContent + i).replace(new RegExp(this.VOID_KEY, "g"), () => (h--, "")), l.parentElement.setAttribute("data-set-empty", "false")), a.forEach(t => {
                        o.removeChild(t), c.appendChild(t)
                    });
                    const d = o.lastChild.childNodes[0], u = c.lastChild.childNodes[0];
                    if (d.childNodes.length <= 1) {
                        const t = d.childNodes[0];
                        (!t.textContent || t.textContent === this.VOID_KEY) && (d.innerHTML = this.VOID_KEY + "<br>", d.setAttribute("data-set-empty", "true"))
                    }
                    if ("richMark" !== u.parentElement.getAttribute("data-set-richType")) c.appendChild(this.getGridElm(!0)); else if (u.childNodes.length <= 1) {
                        const t = u.childNodes[0];
                        (!t.textContent || t.textContent === this.VOID_KEY) && (u.innerHTML = this.VOID_KEY + "<br>", u.setAttribute("data-set-empty", "true"), l = c.children[0].children[0].childNodes[0])
                    }
                    o.nextSibling ? this.richText.insertBefore(c, o.nextSibling) : this.richText.appendChild(c), this.restCursorPos(l, l.textContent === this.VOID_KEY ? 1 : h), this.viewIntoPoint()
                }

                selectRegionMerge() {
                    const t = window.getSelection();
                    if (t.isCollapsed || t.rangeCount <= 0) return;
                    const e = t.getRangeAt(0);
                    if (e.startContainer.nodeType === Node.TEXT_NODE && e.startContainer === e.endContainer) {
                        const t = e.startContainer;
                        if (t.length === e.endOffset - e.startOffset) {
                            const e = t.parentNode, n = e.parentNode === e.parentNode.parentNode.lastChild;
                            e.setAttribute("data-set-empty", "true"), e.innerHTML = "\ufeff" + (n ? "<br>" : ""), this.restCursorPos(e.childNodes[0])
                        } else e.deleteContents()
                    } else if (e.commonAncestorContainer && "richBox" === e.commonAncestorContainer.getAttribute("data-set-richType")) {
                        const t = e.startContainer.nodeType === Node.TEXT_NODE ? e.startContainer.parentNode.parentNode : e.startContainer,
                            n = e.endContainer.nodeType === Node.TEXT_NODE ? e.endContainer.parentNode.parentNode : e.endContainer;
                        e.deleteContents(), t.getAttribute("data-set-richType") === n.getAttribute("data-set-richType") && this.markMerge(t, n)
                    } else if (e.commonAncestorContainer === e.startContainer && e.startContainer === e.endContainer) this.textInnerHtmlInit(!0); else {
                        const t = t => {
                            if (t.nodeType === Node.TEXT_NODE) return t.parentNode.parentNode.parentNode;
                            switch (t.getAttribute("data-set-richType")) {
                                case"richInput":
                                    return t.parentNode.parentNode;
                                case"richMark":
                                    return t.parentNode;
                                case"richBox":
                                    return t;
                                default:
                                    return null
                            }
                        }, n = t(e.startContainer), i = t(e.endContainer);
                        if (!n || !i) return;
                        e.deleteContents(), this.gridMerge(n, i)
                    }
                    return !0
                }

                gridElmMerge() {
                    const t = window.getSelection(), {focusNode: e, focusOffset: n, isCollapsed: i} = t;
                    if (n > 1 || !i) return !1;
                    const r = (t, e) => (t.parentNode === this.richText || t === t.parentNode.childNodes[0]) && (-1 !== Array.prototype.indexOf.call(this.richText.childNodes, t) ? t : !(e >= 6) && r(t.parentNode, e + 1)),
                        o = r(e, 0);
                    if (!o || o === this.richText.childNodes[0] || 1 === n && "false" === o.children[0].children[0].getAttribute("data-set-empty")) return !1;
                    const s = o.previousSibling;
                    return this.gridMerge(s, o), !0
                }

                delMarkRule() {
                    const t = window.getSelection(), e = t.focusNode, n = e.textContent, i = e.parentNode,
                        r = i.parentNode, o = r.parentNode;
                    if (!t.isCollapsed || "richInput" !== i.getAttribute("data-set-richType")) return !1;
                    if (n && 1 === n.length && r !== o.childNodes[0] && (0 !== t.focusOffset || n === this.VOID_KEY)) {
                        if (n === this.VOID_KEY) {
                            const t = r.previousSibling.previousSibling;
                            o.removeChild(r.previousSibling), o.removeChild(r);
                            const e = t.childNodes[0], n = e.childNodes[0];
                            n.textContent === this.VOID_KEY && t === o.lastChild && e.appendChild(document.createElement("br")), this.restCursorPos(n, n.textContent.length)
                        } else {
                            i.innerHTML = r === o.lastChild ? this.VOID_KEY + "<br>" : this.VOID_KEY, i.setAttribute("data-set-empty", "true");
                            const t = i.childNodes[0];
                            this.restCursorPos(t, 1)
                        }
                        return !0
                    }
                    if (0 === t.focusOffset) {
                        const t = i.parentNode, e = null == t ? void 0 : t.previousSibling;
                        return !(!e || "chatTag" !== e.getAttribute("data-set-richType")) && (this.delTag(e), !0)
                    }
                }

                setIMESelection() {
                    const t = window.getSelection(), e = t.focusNode, n = e.parentNode.parentNode;
                    "richMark" === ((null == n ? void 0 : n.getAttribute("data-set-richType")) || "") && (this.pointEndIME = t.focusOffset, e.textContent = e.textContent.slice(0, this.pointEndIME) + this.IME_KEY + e.textContent.slice(this.pointEndIME), this.restCursorPos(e, this.pointEndIME + this.IME_KEY.length))
                }

                delMarkRuleIME() {
                    const t = window.getSelection(), e = t.focusNode, n = e.parentNode, i = n.parentNode;
                    if ("richMark" === ((null == i ? void 0 : i.getAttribute("data-set-richType")) || "")) {
                        this.pointEndIME = t.focusOffset;
                        const r = new RegExp(this.IME_KEY.slice(0, -1), "g");
                        if (e.textContent = e.textContent.replace(r, ""), this.pointEndIME > this.IME_KEY.length - 1 && e.textContent !== this.VOID_KEY) {
                            const t = this.pointEndIME - (this.IME_KEY.length - 1);
                            e.textContent = e.textContent.slice(0, t - 1) + e.textContent.slice(t), this.setCursorPos(e, t - 1)
                        } else {
                            if ("false" === n.getAttribute("data-set-empty") && ("" === e.textContent || e.textContent === this.VOID_KEY)) {
                                n.innerHTML = i === i.parentElement.lastChild ? this.VOID_KEY + "<br>" : this.VOID_KEY, n.setAttribute("data-set-empty", "true");
                                const t = n.childNodes[0];
                                return void this.setCursorPos(t, 1)
                            }
                            const t = i.previousSibling;
                            if (t && t.getAttribute && "chatTag" === t.getAttribute("data-set-richType")) return void this.delTag(t);
                            const r = i.parentElement, o = r.previousElementSibling;
                            o && this.gridMerge(o, r)
                        }
                    }
                }

                wrapIME() {
                    const t = window.getSelection().focusNode;
                    if (t.getAttribute && "richBox" === t.getAttribute("data-set-richType")) {
                        const e = t.previousSibling.lastChild.children[0].childNodes[0];
                        e.textContent = e.textContent.slice(0, -this.IME_KEY.length);
                        const n = t.children[0];
                        return void ("BR" === n.tagName && t.removeChild(n))
                    }
                    const e = t.parentNode.parentNode,
                        n = (null == e ? void 0 : e.getAttribute("data-set-richType")) || "";
                    if ("richMark" === n) {
                        const t = e.parentElement.previousSibling.lastChild.children[0].childNodes[0];
                        t.textContent = t.textContent.slice(0, -this.IME_KEY.length)
                    } else if ("richBox" === n) {
                        const t = e.previousSibling.lastChild.children[0].childNodes[0];
                        t.textContent = t.textContent.slice(0, -this.IME_KEY.length)
                    }
                }

                resetIME(t) {
                    " " === t && (t = " "), t || (t = "");
                    const e = window.getSelection(), n = e.focusNode, i = n.parentNode.parentNode;
                    "richMark" === ((null == i ? void 0 : i.getAttribute("data-set-richType")) || "") && (n.textContent = n.textContent.slice(0, e.focusOffset - this.IME_KEY.length - t.length) + t + n.textContent.slice(e.focusOffset), n.textContent = n.textContent.replace(new RegExp(this.ZERO_WIDTH_KEY, "g"), ""), this.setCursorPos(n, this.pointEndIME + t.length))
                }

                delTag(t) {
                    const e = t.previousSibling, n = t.nextSibling;
                    t.parentNode.removeChild(t), this.markMerge(e, n)
                }

                gridMerge(t, e, n = !1) {
                    "richMark" !== t.lastChild.getAttribute("data-set-richType") && t.appendChild(this.getGridElm(!0)), "richMark" !== e.childNodes[0].getAttribute("data-set-richType") && e.insertBefore(this.getGridElm(!0), e.childNodes[0]);
                    const i = t.lastChild.childNodes[0], r = i.childNodes[0];
                    let o = r.textContent.length;
                    Array.prototype.forEach.call(e.childNodes, e => {
                        t.appendChild(e.cloneNode(!0))
                    }), e.childNodes.length > 1 && i.childNodes[1] && i.removeChild(i.childNodes[1]);
                    const s = i.parentNode.nextSibling;
                    if (s) {
                        const e = s.children[0].childNodes[0];
                        e && e.textContent !== this.VOID_KEY && (i.childNodes[1] && i.removeChild(i.childNodes[1]), r.textContent = (r.textContent + e.textContent).replace(new RegExp(this.VOID_KEY, "g"), () => (o--, "")), r.parentElement.setAttribute("data-set-empty", "false")), t.removeChild(s)
                    }
                    if ("" === r.textContent && (r.textContent = this.VOID_KEY, r.parentNode.setAttribute("data-set-empty", "true"), o = 1), n) {
                        const e = t.childNodes[t.childNodes.length - 1].childNodes[0].childNodes[0];
                        this.restCursorPos(e, e.textContent.length)
                    } else this.richText.removeChild(e), this.restCursorPos(r, o);
                    this.viewIntoPoint()
                }

                markMerge(t, e) {
                    const n = t.children[0].childNodes[0];
                    let i = n.textContent.length;
                    if (e) {
                        const t = e.children[0].childNodes[0];
                        t && t.textContent !== this.VOID_KEY && (n.textContent = (n.textContent + t.textContent).replace(new RegExp(this.VOID_KEY, "g"), () => (i--, "")), n.parentElement.setAttribute("data-set-empty", "false")), e.parentNode.removeChild(e)
                    }
                    "" === n.textContent && (n.textContent = this.VOID_KEY, n.parentNode.setAttribute("data-set-empty", "true"), i = 1);
                    const r = t.parentNode;
                    n.textContent === this.VOID_KEY && t === r.lastChild && (n.parentNode.appendChild(document.createElement("br")), n.parentNode.setAttribute("data-set-empty", "true"), i = 1), this.restCursorPos(n, i)
                }

                setCallSpace(t) {
                    this.needCallSpace = t
                }

                getWrapNode(t) {
                    if (t.nodeType === Node.TEXT_NODE) return t.parentNode.parentNode.parentNode;
                    switch (t.getAttribute("data-set-richType")) {
                        case"richInput":
                            return t.parentNode.parentNode;
                        case"richMark":
                            return t.parentNode;
                        case"richBox":
                            return t
                    }
                }

                getMarkNode(t) {
                    if (t.nodeType === Node.TEXT_NODE) return t.parentNode.parentNode;
                    switch (t.getAttribute("data-set-richType")) {
                        case"richInput":
                            return t.parentNode;
                        case"richMark":
                            return t
                    }
                }

                getRichTextNodeIndex(t) {
                    const e = this.getMarkNode(t), n = e.parentNode;
                    return {
                        gridIndex: Array.prototype.indexOf.call(this.richText.childNodes, n),
                        markIndex: Array.prototype.indexOf.call(n.childNodes, e)
                    }
                }

                setWrapNodeByMark(t) {
                    const e = document.createElement("p");
                    return e.className = "chat-grid-wrap", e.setAttribute("data-set-richType", "richBox"), Array.prototype.forEach.call(t, t => {
                        e.appendChild(t)
                    }), e
                }

                setRangeLastText() {
                    const t = this.richText.childNodes[this.richText.childNodes.length - 1],
                        e = t.childNodes[t.childNodes.length - 1].children[0].childNodes[0];
                    this.restCursorPos(e, e.textContent === this.VOID_KEY ? 1 : e.textContent.length), this.viewIntoPoint()
                }

                viewIntoPoint() {
                    const t = window.getSelection();
                    if (t.rangeCount > 0) {
                        const e = t.getRangeAt(0).getBoundingClientRect(), n = this.richText.parentElement;
                        n.scrollTop = e.top + n.scrollTop - n.clientHeight / 2
                    }
                }
            }

            const s = (t = 50) => new Promise(e => {
                setTimeout(e, t)
            }), a = (t, e, n, i) => {
                const r = Object.assign(Object.assign({}, {
                    precision: "first",
                    continuous: !1,
                    space: "ignore",
                    lastPrecision: "start",
                    insensitive: !0
                }), i || {});
                return r.insensitive && (t = t.toLowerCase(), n = n.toLowerCase()), "ignore" === r.space && (n = n.replace(/\s/g, "")), c(t, e, n, r) || []
            }, c = (t, e, n, i) => {
                let r = [];
                for (let o = 0; o < t.length; o++) {
                    if ("ignore" === i.space && " " === t[o]) {
                        r.push(o);
                        continue
                    }
                    if (t[o] === n[0]) {
                        n = n.slice(1), r.push(o);
                        continue
                    }
                    const s = e.split(" ");
                    let a = 0;
                    if (s.forEach(t => {
                        const e = l(t, n);
                        e > a && (a = e)
                    }), a && (n = n.slice(a), r.push(o)), !n) break
                }
                if (n) return null;
                if (i.continuous) {
                    const t = r;
                    if (r.some((e, n) => n > 0 && e !== t[n - 1] + 1)) return null
                }
                return "ignore" === i.space && (r = r.filter(e => " " !== t[e])), r.length ? r : null
            }, l = (t, e) => {
                let n = 0;
                for (let i = 0; i < t.length; i++) t[i] === e[n] && n++;
                return n
            };

            function h() {
                const t = navigator.userAgent, e = /(?:Windows Phone)/.test(t), n = /(?:SymbianOS)/.test(t) || e,
                    i = /(?:Android)/.test(t), r = /(?:Firefox)/.test(t),
                    o = /(?:iPad|PlayBook)/.test(t) || i && !/(?:Mobile)/.test(t) || r && /(?:Tablet)/.test(t),
                    s = /(?:iPhone)/.test(t) && !o;
                return {isTablet: o, isPhone: s, isAndroid: i, isPc: !s && !i && !n}
            }

            const d = function (t, e, n) {
                return t.forEach(t => {
                    if (n in t) {
                        const i = e.indexOf(String(t[n]));
                        -1 !== i && (e[i] = t)
                    }
                }), e.filter(t => t[n])
            }, u = function (t) {
                return Object.prototype.toString.call(t).slice(8, -1)
            }, p = function (t, e) {
                const n = u(t).toLowerCase();
                switch (u(e)) {
                    case"String":
                        return n === e.toLowerCase();
                    case"Array":
                        return e.some(t => n === t.toLowerCase());
                    default:
                        return !0
                }
            };

            class f {
                constructor({
                                elm: t,
                                userList: e,
                                placeholder: n,
                                copyType: i,
                                uploadImage: a,
                                needCallEvery: c,
                                callEveryLabel: l,
                                userProps: d,
                                needCallSpace: u,
                                wrapKeyFun: p,
                                sendKeyFun: f,
                                needDialog: m,
                                maxLength: g
                            }) {
                    if (r(this, "richText"), r(this, "needCallEvery", !0), r(this, "callEveryLabel", "所有人"), r(this, "maxLength"), r(this, "textLength", 0), r(this, "needDialog", !0), r(this, "placeholderElm"), r(this, "userProps", {
                        id: "id",
                        name: "name",
                        avatar: "avatar",
                        pinyin: "pinyin"
                    }), r(this, "chat"), r(this, "targetUserList", []), r(this, "userList", []), r(this, "copyType", ["text"]), r(this, "itemShowList", []), r(this, "checkboxRows", []), r(this, "base64Images", {}), r(this, "uploadImage"), r(this, "deviceInfo", h()), r(this, "isComposition", !1), r(this, "undoHistory", []), r(this, "redoHistory", []), r(this, "doOverHistory", !0), r(this, "isExternalCallPopup", !1), r(this, "isIMEModel", !1), r(this, "chatEventModule", {
                        enterSend: [],
                        operate: [],
                        defaultAction: []
                    }), r(this, "wrapKeyFun", t => t.ctrlKey && ["Enter"].includes(t.key)), r(this, "sendKeyFun", t => !t.ctrlKey && ["Enter"].includes(t.key)), r(this, "startOpenIndex", 0), r(this, "toolUserList"), r(this, "dialogElm"), r(this, "dialogCheckElm"), r(this, "dialogMainElm"), r(this, "checkboxElm"), r(this, "searchResultElm"), r(this, "checkGroupElm"), r(this, "searchElm"), r(this, "tagsElm"), r(this, "dialogActiveItemElm"), r(this, "searchEmptyLabel", "没有匹配到任何结果"), r(this, "checkAllLabel", "全选"), r(this, "dialogH5Elm"), r(this, "dialogH5MainElm"), r(this, "dialogH5CheckElm"), r(this, "dialogH5ShowElm"), r(this, "dialogH5SearchElm"), r(this, "winClick", () => {
                        this.getElmBlock(this.dialogElm) && this.exitDialog(), this.searchResultElm && this.domElmShow(this.searchResultElm)
                    }), r(this, "winKeydown", async t => {
                        if (t.ctrlKey && "KeyZ" === t.code && t.preventDefault(), this.getElmBlock(this.dialogElm) && 0 !== this.itemShowList.length && !this.isComposition) {
                            if ("ArrowDown" === t.code) return void this.moveActiveItem("down");
                            if ("ArrowUp" === t.code) return void this.moveActiveItem("up");
                            if (("Enter" === t.code || "NumpadEnter" === t.code) && this.dialogActiveItemElm) {
                                t.preventDefault();
                                const e = this.dialogActiveItemElm.getAttribute("data-set-id") || "";
                                await s(100), this.toolUserList && this.toolUserList.length > 0 ? await this.matchSetTag(this.userList.find(t => t.id === e)) : await this.onceSetTag("isALL" === e ? {
                                    [this.userProps.id]: "isALL",
                                    [this.userProps.name]: this.callEveryLabel
                                } : this.userList.find(t => t.id === e)), this.exitDialog()
                            }
                        }
                    }), !(t instanceof HTMLElement)) throw new Error("参数值elm：参数类型错误, 参数类型应为HTMLElement！");
                    ["absolute", "relative", "fixed"].includes(t.style.position) || (t.style.position = "relative"), t.className += " chat-area-" + (this.deviceInfo.isPc ? "pc" : "h5"), this.richText = document.createElement("div"), this.richText.setAttribute("class", "chat-rich-text"), this.richText.setAttribute("data-set-richType", "richAllBox"), this.richText.setAttribute("contenteditable", "true"), t.appendChild(this.richText), this.placeholderElm = document.createElement("div"), this.placeholderElm.className = "chat-placeholder-wrap", this.domElmShow(this.placeholderElm, !0), t.appendChild(this.placeholderElm), this.chat = new o(this.richText), l && (this.callEveryLabel = l), void 0 !== m && (this.needDialog = "false" !== String(m)), this.needDialog && (this.deviceInfo.isPc ? this.hasPc() : this.hasH5()), this.registerEvent(), this.updateConfig({
                        userList: e,
                        placeholder: n,
                        maxLength: g,
                        copyType: i,
                        uploadImage: a,
                        needCallEvery: c,
                        needCallSpace: u,
                        userProps: d,
                        wrapKeyFun: p,
                        sendKeyFun: f
                    });
                    const v = {
                        html: this.richText.innerHTML,
                        gridIndex: 0,
                        markIndex: 0,
                        cursorIndex: this.chat.cursorIndex
                    };
                    this.undoHistory = [v]
                }

                registerEvent() {
                    this.richText.addEventListener("keyup", t => {
                        if (!this.needDialog) return;
                        if (t.stopPropagation(), this.deviceInfo.isPc) return void ((50 === t.keyCode || "Digit2" === t.code || "@" === t.key) && this.ruleCanShowPointDialog());
                        const e = "Unidentified" === t.key ? "android" : "ios";
                        let n = !1;
                        switch (e) {
                            case"android":
                                n = 229 === t.keyCode;
                                break;
                            case"ios":
                                n = 50 === t.keyCode || "Digit2" === t.code || "@" === t.key;
                                break
                        }
                        n && this.userList.length > 0 && this.chat.showAt() && (this.showH5Dialog(), this.isExternalCallPopup = !1)
                    }), this.richText.addEventListener("keydown", async t => {
                        if (!this.deviceInfo.isPc && "Unidentified" === t.key && 229 === t.keyCode) return t.preventDefault(), this.isIMEModel = !0, void this.chat.setIMESelection();
                        this.isIMEModel || (this.getElmBlock(this.dialogElm) ? ["ArrowUp", "ArrowDown", "Enter", "NumpadEnter"].includes(t.code) ? t.preventDefault() : ["ArrowLeft", "ArrowRight"].includes(t.code) && this.exitDialog() : ("Backspace" === t.code || "Backspace" === t.key ? (this.chat.selectRegionMerge() || this.chat.gridElmMerge() || this.chat.delMarkRule()) && (t.preventDefault(), await this.richTextInput()) : this.wrapKeyFun(t) || !this.deviceInfo.isPc && "Enter" === t.key ? (t.preventDefault(), this.chat.setWrap(), await this.richTextInput()) : this.sendKeyFun(t) ? (t.preventDefault(), await s(100), this.enterSend()) : ["ArrowLeft", "ArrowRight"].includes(t.code) ? (t.preventDefault(), this.chat.switchRange(t.code)) : t.ctrlKey && "KeyA" === t.code ? this.isEmpty() && t.preventDefault() : t.ctrlKey && "KeyZ" === t.code ? (t.preventDefault(), this.ruleChatEvent(this.undo, "defaultAction", "UNDO")) : t.ctrlKey && "KeyY" === t.code && (t.preventDefault(), this.ruleChatEvent(this.redo, "defaultAction", "REDO")), -1 === ["Backspace", "Shift", "Tab", "CapsLock", "Control", "Meta", "Alt", "ContextMenu", "Enter", "NumpadEnter", "Escape", "ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown", "Home", "End", "PageUp", "PageDown", "Insert", "Delete", "NumLock"].indexOf(t.key) && !t.ctrlKey && !t.altKey && !t.metaKey && this.chat.selectRegionMerge()))
                    }), this.richText.addEventListener("input", async t => {
                        if (this.isIMEModel) return this.isIMEModel = !1, "deleteContentBackward" === t.inputType ? (await s(50), this.chat.delMarkRuleIME()) : "insertParagraph" === t.inputType ? this.chat.wrapIME() : this.chat.resetIME(t.data), this.chat.upDataNodeOrIndex(), this.chat.updateGrid(), void 0 !== this.maxLength && this.ruleMaxLength(), this.showPlaceholder(), void this.triggerChatEvent("operate");
                        if (await this.richTextInput(), this.getElmBlock(this.dialogElm) && !this.isComposition) {
                            const t = this.chat.vnode.textContent, e = this.chat.cursorIndex,
                                n = new RegExp(`^([${this.chat.ZERO_WIDTH_KEY}${this.chat.VOID_KEY}])+$`);
                            if (!t || n.test(t) || e < this.startOpenIndex) return void this.exitDialog();
                            const i = t.slice(this.startOpenIndex, e + 1), r = String(i || "");
                            if (/\s{2,}/gi.test(r)) return void this.exitDialog();
                            if (!r) return this.resizeUserTool(), void this.showPCDialog();
                            this.toolUserList || (this.toolUserList = this.userList);
                            const o = this.searchUserList(r, this.toolUserList);
                            o.length > 0 ? (this.updateUserList(o, !0), this.showPCDialog()) : this.exitDialog()
                        }
                    }), this.richText.addEventListener("copy", t => {
                        t.preventDefault(), this.ruleChatEvent(() => {
                            this.copyRange(t)
                        }, "defaultAction", "COPY")
                    }), this.richText.addEventListener("cut", t => {
                        t.preventDefault(), this.ruleChatEvent(() => {
                            this.copyRange(t), this.removeRange()
                        }, "defaultAction", "CUT")
                    }), this.richText.addEventListener("paste", t => {
                        t.preventDefault(), this.ruleChatEvent(() => {
                            const e = t.clipboardData.getData("text/plain");
                            if ("string" == typeof e && "" !== e) {
                                if (-1 === this.copyType.indexOf("text")) return;
                                let n = document.createElement("div");
                                n.innerHTML = t.clipboardData.getData("application/my-custom-format") || "", this.chat.selectRegionMerge(), n.children[0] && "richBox" === n.children[0].getAttribute("data-set-richType") ? this.insertInsideHtml(n.innerHTML) : (n.innerHTML = e, this.insertText(n.innerText)), n = null
                            } else {
                                if (-1 === this.copyType.indexOf("image")) return;
                                const e = (t.clipboardData || t.originalEvent.clipboardData).items || [];
                                Array.prototype.forEach.call(e, async t => {
                                    if (-1 === t.type.indexOf("image")) return;
                                    const e = t.getAsFile();
                                    if (this.uploadImage) {
                                        const t = await this.uploadImage(e);
                                        this.insertHtml(`<img class="chat-img" src="${t}" alt="" />`)
                                    } else {
                                        const t = new FileReader;
                                        t.onload = t => {
                                            this.insertHtml(`<img class="chat-img" src="${t.target.result}" alt="" />`)
                                        }, t.readAsDataURL(e)
                                    }
                                })
                            }
                        }, "defaultAction", "PASTE")
                    }), this.richText.addEventListener("blur", t => {
                        this.chat.upDataNodeOrIndex()
                    }), this.richText.addEventListener("focus", t => {
                        this.chat.upDataNodeOrIndex()
                    }), this.richText.addEventListener("click", () => {
                        this.chat.upDataNodeOrIndex()
                    }), this.richText.addEventListener("dragstart", t => {
                        t.stopPropagation(), t.preventDefault()
                    }), this.richText.addEventListener("dragover", t => {
                        t.stopPropagation(), t.preventDefault()
                    }), this.richText.addEventListener("drop", t => {
                        t.stopPropagation(), t.preventDefault()
                    }), this.richText.addEventListener("compositionstart", () => {
                        this.isComposition = !0
                    }), this.richText.addEventListener("compositionend", () => {
                        this.isComposition = !1
                    })
                }

                async richTextInput(t = !0) {
                    this.chat.upDataNodeOrIndex(), this.deviceInfo.isPc && this.chat.selectRegionMerge(), await s(50), this.isComposition || this.chat.updateGrid();
                    const e = (this.richText.children[0] || {childNodes: []}).childNodes[0];
                    if (!e || !e.getAttribute || "richMark" !== e.getAttribute("data-set-richType")) return this.chat.textInnerHtmlInit(!0), this.showPlaceholder(), void this.triggerChatEvent("operate");
                    if (void 0 !== this.maxLength && this.ruleMaxLength(), this.showPlaceholder(), this.triggerChatEvent("operate"), t && !this.isComposition) {
                        const {gridIndex: t, markIndex: e} = this.chat.getRichTextNodeIndex(this.chat.vnode), n = {
                            html: this.richText.innerHTML,
                            gridIndex: t,
                            markIndex: e,
                            cursorIndex: this.chat.cursorIndex
                        };
                        this.undoHistory.push(n), this.undoHistory.length > 50 && this.undoHistory.shift()
                    }
                }

                copyRange(t) {
                    const e = window.getSelection();
                    if (e.isCollapsed || e.rangeCount <= 0) return t.clipboardData.setData("application/my-custom-format", ""), void t.clipboardData.setData("text/plain", "");
                    const n = e.toString() || "";
                    let i = document.createElement("div");
                    i.innerHTML = n;
                    const r = i.innerText.replace(/\n\n/g, "\n");
                    i = null, t.clipboardData.setData("text/plain", r);
                    const o = e.anchorNode, s = e.focusNode;
                    if (o === s && o.nodeType === Node.TEXT_NODE) {
                        const n = o.textContent.slice(e.anchorOffset, e.focusOffset);
                        return void t.clipboardData.setData("application/my-custom-format", n)
                    }
                    if (o === this.richText && s === this.richText) return void t.clipboardData.setData("application/my-custom-format", this.richText.innerHTML);
                    const a = this.chat.getWrapNode(o), c = this.chat.getWrapNode(s), l = this.chat.getMarkNode(o),
                        h = this.chat.getMarkNode(s), d = Array.prototype.indexOf.call(a.childNodes, l),
                        u = Array.prototype.indexOf.call(c.childNodes, h);
                    if (a === c && a.parentNode === this.richText) {
                        const n = d > u,
                            i = Array.prototype.filter.call(a.childNodes, (t, e) => n ? e < d && e > u : e > d && e < u).map(t => t.cloneNode(!0)),
                            r = n ? o.textContent.slice(0, e.anchorOffset) : o.textContent.slice(e.anchorOffset),
                            c = n ? s.textContent.slice(e.focusOffset) : s.textContent.slice(0, e.focusOffset),
                            l = this.chat.getGridElm(!0), h = this.chat.getGridElm(!0);
                        r && (l.childNodes[0].innerHTML = r, l.childNodes[0].setAttribute("data-set-empty", "false")), c && (h.childNodes[0].innerHTML = c, h.childNodes[0].setAttribute("data-set-empty", "false")), n ? (i.unshift(h), i.push(l)) : (i.unshift(l), i.push(h));
                        let p = document.createElement("div");
                        const f = this.chat.setWrapNodeByMark(i);
                        return p.appendChild(f), t.clipboardData.setData("application/my-custom-format", p.innerHTML), void (p = null)
                    }
                    if (a.parentNode === this.richText && c.parentNode === this.richText) {
                        const n = Array.prototype.indexOf.call(this.richText.childNodes, a),
                            i = Array.prototype.indexOf.call(this.richText.childNodes, c), r = n > i,
                            l = Array.prototype.filter.call(this.richText.childNodes, (t, e) => r ? e < n && e > i : e > n && e < i).map(t => t.cloneNode(!0)),
                            h = r ? o.textContent.slice(0, e.anchorOffset) : o.textContent.slice(e.anchorOffset),
                            p = r ? s.textContent.slice(e.focusOffset) : s.textContent.slice(0, e.focusOffset),
                            f = this.chat.getGridElm(!0), m = this.chat.getGridElm(!0);
                        h && (f.childNodes[0].innerHTML = h, f.childNodes[0].setAttribute("data-set-empty", "false")), p && (m.childNodes[0].innerHTML = p, m.childNodes[0].setAttribute("data-set-empty", "false"));
                        const g = Array.prototype.filter.call(a.childNodes, (t, e) => r ? e < d : e > d).map(t => t.cloneNode(!0)),
                            v = Array.prototype.filter.call(c.childNodes, (t, e) => r ? e > u : e < u).map(t => t.cloneNode(!0));
                        if (r) {
                            g.push(f), v.unshift(m);
                            const t = this.chat.setWrapNodeByMark(g), e = this.chat.setWrapNodeByMark(v);
                            l.push(t), l.unshift(e)
                        } else {
                            g.unshift(f), v.push(m);
                            const t = this.chat.setWrapNodeByMark(g), e = this.chat.setWrapNodeByMark(v);
                            l.unshift(t), l.push(e)
                        }
                        let b = document.createElement("div");
                        return Array.prototype.forEach.call(l, t => {
                            b.appendChild(t)
                        }), t.clipboardData.setData("application/my-custom-format", b.innerHTML), void (b = null)
                    }
                }

                async removeRange() {
                    window.getSelection().getRangeAt(0).deleteContents(), await s(50), this.chat.updateGrid(), this.showPlaceholder()
                }

                updateUserList(t, e) {
                    t && (this.userList = this.getRuleUserList(t)), e || (this.base64Images = {}), this.needDialog && (this.deviceInfo.isPc ? this.updatePCUser() : this.updateH5User())
                }

                getRuleUserList(t) {
                    if (!p(t, "Array")) throw new Error("参数值userList：类型值应为Array！");
                    this.targetUserList = JSON.parse(JSON.stringify(t || []));
                    const e = Object.create(null);
                    return e[this.userProps.id] = "isALL", e[this.userProps.name] = this.callEveryLabel, this.targetUserList.unshift(e), (null == t ? void 0 : t.map((t, e) => {
                        const n = t[this.userProps.id];
                        if (!n && 0 !== n) throw new Error(`参数值userList：下标第${e}项${this.userProps.id}值异常！`);
                        return t.name = String(t[this.userProps.name] || ""), t.pinyin = String(t[this.userProps.pinyin] || ""), t.id = String(n), t.avatar = String(t[this.userProps.avatar] || ""), t
                    })) || []
                }

                getElmBlock(t) {
                    return t && "block" === t.style.display
                }

                domElmShow(t, e = !1) {
                    t && (t.className = t.className.replace(/ chat-view-show| chat-view-hidden/g, ""), e ? (t.style.display = "block", t.className += " chat-view-show") : t.style.display = "none")
                }

                getUserHtmlTemplate(t, e) {
                    const n = document.createElement("span");
                    if (n.setAttribute("class", "call-user-dialog-item-sculpture " + (e.avatar ? "is-avatar" : "")), e.avatar) {
                        const t = new Image;
                        t.alt = "";
                        const i = this.base64Images[e.id];
                        i ? t.src = i : (t.src = String(e.avatar), t.setAttribute("crossOrigin", "Anonymous"), t.onload = () => {
                            const n = document.createElement("canvas");
                            n.width = 48, n.height = 48;
                            const i = n.getContext("2d");
                            i && (i.drawImage(t, 0, 0, n.width, n.height), this.base64Images[e.id] = n.toDataURL("image/png", 1))
                        }), n.appendChild(t)
                    } else n.innerHTML = `<span style="transform: scale(0.75)">${e.name.slice(-2)}</span>`;
                    t.appendChild(n);
                    const i = document.createElement("span");
                    i.setAttribute("class", "call-user-dialog-item-name"), i.innerHTML = e.name, t.appendChild(i)
                }

                hasPc() {
                    this.initCheckbox(), this.initCallUser(), window.addEventListener("click", this.winClick), window.addEventListener("keydown", this.winKeydown)
                }

                initCheckbox() {
                    this.checkboxElm = document.createElement("div"), this.checkboxElm.setAttribute("class", "checkbox-dialog"), this.domElmShow(this.checkboxElm), this.checkboxElm.innerHTML = '\n      <div class="checkbox-dialog-container">\n        <div class="checkbox-dialog-container-header">\n            <span>选择要@的人</span>\n            <span class="checkbox-dialog-container-header-close">⛌</span>\n        </div>\n        <div class="checkbox-dialog-container-body">\n            <div class="checkbox-dialog-left-box">\n                <div class="checkbox-dialog-search">\n                    <input class="checkbox-dialog-search-input" placeholder="搜索人员名称" type="text">\n\n                    <div data-set-remark="搜索下拉" class="checkbox-dialog-search-group"></div>\n                </div>\n\n                <div data-set-remark="反显选取的人员" class="checkbox-dialog-tags"></div>\n\n                <div class="checkbox-dialog-option">\n                    <button class="checkbox-dialog-option-btn btn-submit">确定</button>\n                    <button class="checkbox-dialog-option-btn btn-close">取消</button>\n                </div>\n            </div>\n            \n            <div class="checkbox-dialog-right-box">\n                <div class="checkbox-dialog-right-box-title">研讨成员列表</div>\n\n                <div data-set-remark="多选人员列表" class="checkbox-dialog-check-group"></div>\n            </div>\n        </div>\n      </div>\n    ', document.body.appendChild(this.checkboxElm), this.checkGroupElm = this.checkboxElm.querySelector(".checkbox-dialog-check-group"), this.searchResultElm = this.checkboxElm.querySelector(".checkbox-dialog-search-group"), this.searchElm = this.checkboxElm.querySelector(".checkbox-dialog-search-input"), this.tagsElm = this.checkboxElm.querySelector(".checkbox-dialog-tags");
                    const t = () => {
                            this.domElmShow(this.checkboxElm), this.isExternalCallPopup = !1
                        }, e = this.checkboxElm.querySelector(".checkbox-dialog-container-header-close"),
                        n = this.checkboxElm.querySelector(".btn-close");
                    e.onclick = t, n.onclick = t;
                    const i = this.checkboxElm.querySelector(".btn-submit");
                    i.onclick = async () => {
                        await this.batchSetTag(this.checkboxRows), t(), this.chat.viewIntoPoint()
                    }, this.domElmShow(this.searchResultElm), this.searchResultElm.onclick = t => {
                        t.stopPropagation()
                    }, this.searchElm.onclick = t => {
                        t.stopPropagation()
                    }, this.searchElm.oninput = t => {
                        this.searchResultElm.innerHTML = "";
                        const e = String(t.target.value || "").replace(/'/g, "");
                        if (!e) return void this.domElmShow(this.searchResultElm);
                        const n = this.searchUserList(e), i = document.createDocumentFragment();
                        if (n.forEach(t => {
                            const e = document.createElement("div");
                            e.setAttribute("class", "checkbox-dialog-check-item"), e.setAttribute("data-set-value", t.id);
                            const n = document.createElement("div");
                            n.setAttribute("class", "checkbox-dialog-check-item-label"), this.getUserHtmlTemplate(n, t), e.appendChild(n), e.onclick = () => {
                                this.domElmShow(this.searchResultElm);
                                const t = e.getAttribute("data-set-value") || "";
                                if (this.searchElm.value = "", this.checkboxRows.some(e => e.id === t)) return;
                                const n = this.userList.find(e => e.id === t);
                                n && this.checkboxRows.push(n), Array.prototype.some.call(this.checkGroupElm.children, (e, n) => 0 === n && this.checkboxRows.length === this.userList.length ? (e.className += " checkbox-dialog-check-item-check", !1) : e.getAttribute("data-set-value") === t ? (e.className += " checkbox-dialog-check-item-check", !0) : void 0), this.updateTags()
                            }, i.appendChild(e)
                        }), !n.length) {
                            const t = document.createElement("div");
                            t.setAttribute("class", "checkbox-dialog-search-empty"), t.innerText = this.searchEmptyLabel, i.appendChild(t)
                        }
                        this.searchResultElm.appendChild(i), this.domElmShow(this.searchResultElm, !0)
                    }
                }

                searchUserList(t, e) {
                    return (e || this.userList).filter(e => a(e.name, e.pinyin || "", t).length > 0)
                }

                updateTags() {
                    const t = this.checkboxRows.map(t => t.id), e = [], n = [];
                    Array.prototype.forEach.call(this.tagsElm.children, i => {
                        const r = i.getAttribute("data-set-value");
                        -1 === t.indexOf(r) ? n.push(i) : e.push(r)
                    }), n.forEach(t => {
                        this.tagsElm.removeChild(t)
                    });
                    const i = this.checkboxRows.filter(t => -1 === e.indexOf(t.id));
                    if (!i.length) return;
                    const r = document.createDocumentFragment();
                    i.forEach(t => {
                        const e = document.createElement("div");
                        e.setAttribute("class", "checkbox-dialog-tag-item"), e.setAttribute("data-set-value", t.id), e.innerHTML = `\n        <span>${t.name}</span>\n      `;
                        const n = document.createElement("span");
                        n.setAttribute("class", "checkbox-dialog-tag-item-close"), n.innerHTML = "⛌", n.onclick = () => {
                            const t = e.getAttribute("data-set-value");
                            this.checkboxRows = this.checkboxRows.filter(e => e.id !== t), this.tagsElm.removeChild(e), Array.prototype.some.call(this.checkGroupElm.children, (e, n) => 0 === n ? (e.className = e.className.replace(/ checkbox-dialog-check-item-check/g, ""), !1) : e.getAttribute("data-set-value") === t ? (e.className = e.className.replace(/ checkbox-dialog-check-item-check/g, ""), !0) : void 0)
                        }, e.appendChild(n), r.appendChild(e)
                    }), this.tagsElm.appendChild(r)
                }

                initCallUser() {
                    this.dialogElm = document.createElement("div"), this.dialogElm.setAttribute("class", "call-user-dialog"), this.domElmShow(this.dialogElm);
                    const t = document.createElement("div");
                    t.setAttribute("class", "call-user-dialog-header"), t.innerHTML = '\n        <span class="call-user-dialog-header-title">群成员</span>\n    ', this.dialogCheckElm = document.createElement("span"), this.dialogCheckElm.setAttribute("class", "call-user-dialog-header-check"), this.domElmShow(this.dialogCheckElm, this.userList.length > 0), this.dialogCheckElm.innerText = "多选", this.dialogCheckElm.onclick = () => {
                        this.showPCCheckDialog(), this.isExternalCallPopup = !1
                    }, t.appendChild(this.dialogCheckElm), this.dialogElm.appendChild(t), this.dialogMainElm = document.createElement("div"), this.dialogMainElm.setAttribute("class", "call-user-dialog-main"), this.dialogElm.appendChild(this.dialogMainElm), this.updateUserList();
                    const e = document.createElement("div");
                    e.setAttribute("class", "call-user-dialog-footer"), this.dialogElm.appendChild(e), document.body.appendChild(this.dialogElm)
                }

                resizeUserTool() {
                    this.toolUserList && (this.userList = this.toolUserList), this.toolUserList = void 0, this.updateUserList(this.userList, !0)
                }

                updatePCUser() {
                    this.dialogMainElm.innerHTML = "", this.dialogActiveItemElm = void 0;
                    const t = this.userList && this.userList.length > 0, e = document.createDocumentFragment();
                    if (this.domElmShow(this.dialogCheckElm), !this.toolUserList && t && (this.domElmShow(this.dialogCheckElm, !0), this.needCallEvery)) {
                        const t = document.createElement("div");
                        t.setAttribute("class", "call-user-dialog-item"), t.setAttribute("data-set-id", "isALL"), this.userSelectStyleAndEvent(t, {
                            [this.userProps.id]: "isALL",
                            [this.userProps.name]: this.callEveryLabel
                        }), t.innerHTML = `\n          <span class="call-user-dialog-item-sculpture">\n            <span style="transform: scale(0.75)">@</span>\n          </span>\n          <span class="call-user-dialog-item-name">${this.callEveryLabel}(${this.userList.length})</span>\n      `, e.appendChild(t)
                    }
                    this.userList.forEach(t => {
                        const n = document.createElement("div");
                        n.setAttribute("class", "call-user-dialog-item"), n.setAttribute("data-set-id", t.id), this.userSelectStyleAndEvent(n, t), this.getUserHtmlTemplate(n, t), e.appendChild(n)
                    }), this.dialogMainElm.appendChild(e), this.callUserDialogMap(), this.checkGroupElm.innerHTML = `\n                    <div class="checkbox-dialog-check-item" data-set-value="ALL">\n                        <input type="checkbox" value>\n                        <span class="checkbox-dialog-check-item-inner"></span>\n                        <div class="checkbox-dialog-check-item-label">${this.checkAllLabel}</div>\n                    </div>`;
                    const n = document.createDocumentFragment();
                    this.userList.forEach(t => {
                        const e = document.createElement("div");
                        e.setAttribute("class", "checkbox-dialog-check-item"), e.setAttribute("data-set-value", t.id), e.innerHTML = '\n          <input type="checkbox" value>\n          <span class="checkbox-dialog-check-item-inner"></span>\n      ', this.getUserHtmlTemplate(e, t), n.appendChild(e)
                    }), this.checkGroupElm.appendChild(n), this.checkGroupElm && this.checkGroupElm.children.length && Array.prototype.forEach.call(this.checkGroupElm.children, t => {
                        t.onclick = () => {
                            const e = t.getAttribute("data-set-value") || "", n = this.userList.find(t => t.id === e);
                            -1 !== t.className.indexOf("checkbox-dialog-check-item-check") ? (t.className = t.className.replace(/ checkbox-dialog-check-item-check/g, ""), "ALL" !== e && (this.checkboxRows = this.checkboxRows.filter(t => t.id !== e))) : (t.className += " checkbox-dialog-check-item-check", "ALL" !== e && n && this.checkboxRows.push(n)), "ALL" === e ? (Array.prototype.forEach.call(this.checkGroupElm.children, e => {
                                e.className = t.className
                            }), this.checkboxRows = -1 !== t.className.indexOf("checkbox-dialog-check-item-check") ? this.userList.map(t => t) : []) : this.checkboxRows.length === this.userList.length ? this.checkGroupElm.children[0].className += " checkbox-dialog-check-item-check" : this.checkGroupElm.children[0].className = this.checkGroupElm.children[0].className.replace(/ checkbox-dialog-check-item-check/g, ""), this.updateTags()
                        }
                    })
                }

                userSelectStyleAndEvent(t, e) {
                    t.addEventListener("click", async n => {
                        n.stopPropagation(), this.upDateActiveItem(t), this.toolUserList && this.toolUserList.length > 0 ? await this.matchSetTag(e) : await this.onceSetTag(e), this.exitDialog()
                    })
                }

                callUserDialogMap() {
                    this.itemShowList = [], Array.prototype.forEach.call(this.dialogMainElm.children || [], (t, e) => {
                        this.itemShowList.push({index: e, elm: t})
                    })
                }

                ruleCanShowPointDialog() {
                    this.userList.length > 0 && this.chat.showAt() && (this.startOpenIndex = this.chat.cursorIndex, this.showPCDialog())
                }

                showPCDialog() {
                    this.domElmShow(this.dialogElm, !0);
                    let t = "0", e = "100%";
                    this.upDateActiveItem(this.dialogMainElm.firstElementChild, !0);
                    const n = this.chat.getRangeRect(), {clientWidth: i, clientHeight: r} = this.dialogElm;
                    n.x > window.innerWidth - i && (n.x = n.x - i - 16, t = "100%"), n.y < r && (n.y = r + n.y, e = "0"), this.dialogElm.style.transformOrigin = `${t} ${e}`, this.dialogElm.style.left = n.x + 6 + "px", this.dialogElm.style.bottom = `calc(100% - ${n.y}px)`, this.dialogElm.style.opacity = "1"
                }

                showPCCheckDialog() {
                    this.winClick(), this.checkboxRows = [], this.domElmShow(this.checkboxElm, !0), this.tagsElm.scrollTop = 0, this.checkGroupElm.scrollTop = 0, this.searchElm.value = "", Array.prototype.forEach.call(this.checkGroupElm.children, t => {
                        t.className = "checkbox-dialog-check-item"
                    }), this.updateTags(), this.isExternalCallPopup = !0
                }

                async showPCPointDialog() {
                    this.insertText("@"), this.startOpenIndex = this.chat.cursorIndex, window.removeEventListener("click", this.winClick), this.showPCDialog(), await s(50), window.addEventListener("click", this.winClick)
                }

                moveActiveItem(t) {
                    if (!this.dialogActiveItemElm || 0 === this.itemShowList.length) return;
                    let e = 0;
                    const n = this.dialogActiveItemElm.getAttribute("data-set-id");
                    this.itemShowList.some(t => {
                        const i = t.elm.getAttribute("data-set-id");
                        return e = t.index, n === i
                    });
                    const i = this.itemShowList.map(t => t.index);
                    if ("down" === t && e !== this.itemShowList[this.itemShowList.length - 1].index) {
                        const t = this.itemShowList[i.indexOf(e) + 1];
                        t && this.upDateActiveItem(t.elm, !0)
                    } else if ("up" === t && e !== this.itemShowList[0].index) {
                        const t = this.itemShowList[i.indexOf(e) - 1];
                        t && this.upDateActiveItem(t.elm, !0)
                    }
                }

                upDateActiveItem(t, e = !1) {
                    this.dialogActiveItemElm && (this.dialogActiveItemElm.className = this.dialogActiveItemElm.className.replace(/ call-user-dialog-item-active/g, "")), this.dialogActiveItemElm = t, t && (t.className += " call-user-dialog-item-active", e && t.scrollIntoView({block: "center"}))
                }

                exitDialog() {
                    this.toolUserList && this.resizeUserTool(), this.upDateActiveItem(), this.domElmShow(this.dialogElm)
                }

                hasH5() {
                    this.dialogH5Elm = document.createElement("div"), this.dialogH5Elm.setAttribute("class", "call-user-popup"), this.dialogH5Elm.innerHTML = '\n      <div class="call-user-popup-main">\n        <div class="call-user-popup-header">\n            <span class="popup-show">收起</span>\n            <span class="popup-title">选择提醒的人</span>\n            <span class="popup-check">确定</span>\n        </div>\n        \n        <div class="call-user-popup-search">\n            <svg class="icon-search"\n                 style="vertical-align: middle;fill: currentColor;overflow: hidden;"\n                 viewBox="0 0 1024 1024" version="1.1"\n                 xmlns="http://www.w3.org/2000/svg"\n                 p-id="4209">\n                <path d="M684.8 223.530667a326.272 326.272 0 0 1 24.96 433.621333c2.645333 2.133333 5.290667 4.48 7.850667 7.04L870.4 817.066667c24.789333 24.746667 32.896 56.832 18.133333 71.594666-14.762667 14.805333-46.848 6.656-71.637333-18.090666l-152.789333-152.832a106.282667 106.282667 0 0 1-7.210667-7.936 326.101333 326.101333 0 0 1-433.109333-25.173334c-127.445333-127.445333-127.573333-333.952-0.256-461.269333 127.36-127.36 333.866667-127.232 461.269333 0.213333zM275.328 275.114667a252.885333 252.885333 0 0 0 0.256 357.632 252.885333 252.885333 0 0 0 357.632 0.256 252.885333 252.885333 0 0 0-0.256-357.632 252.885333 252.885333 0 0 0-357.632-0.256z"\n                      fill="#9B9B9B"\n                      p-id="4210"></path>\n            </svg>\n            <input class="call-user-popup-search-input"\n                   placeholder="搜索人员名称"\n                   type="text">\n        </div>\n        \n        <div class="call-user-popup-body">\n        </div>\n      </div>\n    ';
                    const t = async () => {
                        this.dialogH5Elm.className = this.dialogH5Elm.className.replace(/ chat-view-show/g, " chat-view-hidden"), this.dialogH5SearchElm.value = "", await s(260), this.domElmShow(this.dialogH5Elm), this.chat.restCursorPos(this.chat.vnode, this.chat.cursorIndex), this.isExternalCallPopup = !1, this.chat.viewIntoPoint()
                    };
                    this.dialogH5Elm.onclick = t, this.dialogH5Elm.querySelector(".call-user-popup-main").onclick = t => {
                        t.stopPropagation()
                    }, this.dialogH5ShowElm = this.dialogH5Elm.querySelector(".popup-show"), this.dialogH5ShowElm.onclick = t, this.dialogH5CheckElm = this.dialogH5Elm.querySelector(".popup-check"), this.dialogH5CheckElm.onclick = async () => {
                        const e = this.dialogH5Elm.querySelectorAll(".user-popup-check-item-check") || [];
                        if (0 === e.length) return void await t();
                        if (Array.prototype.some.call(e, t => "isALL" === t.getAttribute("data-set-id"))) return await this.onceSetTag({
                            [this.userProps.id]: "isALL",
                            [this.userProps.name]: this.callEveryLabel
                        }), void await t();
                        const n = Array.prototype.map.call(e, t => t.getAttribute("data-set-id")),
                            i = this.userList.filter(t => n.includes(t.id));
                        await this.batchSetTag(i), await t()
                    }, this.dialogH5MainElm = this.dialogH5Elm.querySelector(".call-user-popup-body"), this.updateUserList(), this.dialogH5SearchElm = this.dialogH5Elm.querySelector(".call-user-popup-search-input"), this.dialogH5SearchElm.oninput = t => {
                        const e = String(t.target.value || "").replace(/'/g, "");
                        Array.prototype.forEach.call(this.dialogH5MainElm.children, t => {
                            if (!e) return void (t.style.display = "");
                            const n = t.getAttribute("data-set-name") || "",
                                i = t.getAttribute("data-set-pinyin") || "";
                            t.style.display = a(n, i, e).length > 0 ? "" : "none"
                        })
                    }, this.domElmShow(this.dialogH5Elm), document.body.appendChild(this.dialogH5Elm)
                }

                updateH5User() {
                    this.dialogH5MainElm.innerHTML = "", this.domElmShow(this.dialogH5CheckElm, this.userList.length > 0);
                    const t = this.userList && this.userList.length > 0, e = document.createDocumentFragment(),
                        n = document.createElement("span");
                    if (n.innerHTML = '\n        <input type="checkbox" value>\n        <span class="user-popup-check-item-inner"></span>\n    ', t) {
                        const t = document.createElement("div");
                        this.needCallEvery && (t.setAttribute("class", "call-user-popup-item"), t.setAttribute("data-set-id", "isALL"), t.innerHTML = `\n          <span class="call-user-dialog-item-sculpture">\n            <span style="transform: scale(0.75)">@</span>\n          </span>\n          <span class="call-user-dialog-item-name">${this.callEveryLabel}(${this.userList.length})</span>\n      `, t.appendChild(n.cloneNode(!0)), t.onclick = () => {
                            const e = -1 === t.className.indexOf("user-popup-check-item-check");
                            Array.prototype.forEach.call(this.dialogH5MainElm.children, t => {
                                e ? t.className += " user-popup-check-item-check" : t.className = t.className.replace(/ user-popup-check-item-check/g, "")
                            })
                        }, e.appendChild(t)), this.userList.forEach((i, r) => {
                            const o = document.createElement("div");
                            o.setAttribute("class", "call-user-popup-item"), o.setAttribute("data-set-id", i.id), o.setAttribute("data-set-name", i.name), o.setAttribute("data-set-pinyin", i.pinyin || ""), this.getUserHtmlTemplate(o, i), o.appendChild(n.cloneNode(!0)), e.appendChild(o), o.onclick = e => {
                                -1 === o.className.indexOf("user-popup-check-item-check") ? (o.className += " user-popup-check-item-check", t.className += Array.prototype.every.call(this.dialogH5MainElm.children, t => -1 !== t.className.indexOf("user-popup-check-item-check") || "isALL" === t.getAttribute("data-set-id")) ? " user-popup-check-item-check" : "") : (o.className = o.className.replace(/ user-popup-check-item-check/g, ""), t.className = t.className.replace(/ user-popup-check-item-check/g, ""))
                            }
                        })
                    }
                    this.dialogH5MainElm.appendChild(e)
                }

                showH5Dialog() {
                    this.richText && this.richText.blur(), Array.prototype.forEach.call(this.dialogH5MainElm.children, t => {
                        t.style.display = "", t.className = t.className.replace(/ user-popup-check-item-check/g, "")
                    }), this.domElmShow(this.dialogH5Elm, !0), this.dialogH5MainElm.scrollTop = 0, this.isExternalCallPopup = !0
                }

                updateConfig({
                                 copyType: t,
                                 userProps: e,
                                 userList: n,
                                 uploadImage: i,
                                 needCallEvery: r,
                                 placeholder: o,
                                 maxLength: s,
                                 needCallSpace: a,
                                 wrapKeyFun: c,
                                 sendKeyFun: l
                             } = {}) {
                    if (t) {
                        if (!p(t, "Array")) throw new Error("参数值copyType：类型值应为Array！");
                        this.copyType = t.map(t => {
                            if (-1 === ["text", "image"].indexOf(t)) throw new Error(`参数值copyType：无效的参数值"${t}"！`);
                            return t
                        })
                    }
                    if (e) {
                        if (!p(e, "Object")) throw new Error("参数值copyType：类型值应为Object！");
                        this.userProps = Object.assign({}, {
                            id: "id",
                            name: "name",
                            avatar: "avatar",
                            pinyin: "pinyin"
                        }, e)
                    }
                    if (i) {
                        if ("function" != typeof i) throw new Error("参数值uploadImage：参数类型错误，参数类型应为Function！");
                        this.uploadImage = i
                    }
                    if (o && (this.placeholderElm.innerText = o), void 0 !== s) {
                        if (!p(s, "Number")) throw new Error("参数值maxLength：类型值应为Number！");
                        this.maxLength = s, this.ruleMaxLength()
                    }
                    if ((void 0 !== r || n) && (this.needCallEvery = "false" !== String(r), this.updateUserList(n)), void 0 !== a && this.chat.setCallSpace(a), c) {
                        if ("function" != typeof c) throw new Error("参数值wrapKeyFun：参数类型错误，参数类型应为Function！");
                        this.wrapKeyFun = c
                    }
                    if (l) {
                        if ("function" != typeof l) throw new Error("参数值sendKeyFun：参数类型错误，参数类型应为Function！");
                        this.sendKeyFun = l
                    }
                }

                enterSend() {
                    this.triggerChatEvent("enterSend")
                }

                insertHtml(t) {
                    const e = document.createElement("span");
                    e.innerHTML = t, e.className = "chat-set-html";
                    const n = this.chat.createNewDom(e);
                    return this.chat.replaceRegContent(n), this.chat.viewIntoPoint(), this.richTextInput(), n
                }

                insertInsideHtml(t) {
                    let e = document.createElement("div");
                    if (e.innerHTML = t, !e.children.length) return;
                    const n = this.chat.vnode, i = this.chat.getWrapNode(n);
                    if (1 === e.children.length) this.chat.gridMerge(i, e.children[0], !0); else {
                        this.chat.gridMerge(i, e.children[0], !0);
                        const t = Array.prototype.slice.call(e.children, 1);
                        let n = i;
                        Array.prototype.forEach.call(t, (e, i) => {
                            if (n.parentElement ? this.richText.insertBefore(e, n.nextElementSibling) : this.richText.appendChild(e), n = e, i === t.length - 1) {
                                const t = e.childNodes[e.childNodes.length - 1].childNodes[0].childNodes[0];
                                this.chat.restCursorPos(t, t.textContent.length)
                            }
                        })
                    }
                    e = null, this.chat.viewIntoPoint(), this.richTextInput()
                }

                insertText(t) {
                    var e, n;
                    if (!t) return;
                    const i = new RegExp(`${this.chat.ZERO_WIDTH_KEY}${this.chat.VOID_KEY}`, "ig"),
                        r = t.replace(i, "");
                    if (!r) return;
                    let o, s = 0;
                    const a = this.chat.vnode;
                    a && a.parentElement && "richInput" === a.parentElement.getAttribute("data-set-richType") ? (o = a.parentElement, s = a.textContent === this.chat.VOID_KEY ? 1 : this.chat.cursorLeft) : (o = null == (n = null == (e = this.richText) ? void 0 : e.lastChild) ? void 0 : n.lastChild, s = o.childNodes[0].textContent.length);
                    const c = (t, e = !1) => {
                        const n = o.childNodes[0], i = n.textContent.split("");
                        return i.splice(s, 0, t), n.textContent = i.join("").replace(new RegExp(this.chat.VOID_KEY, "g"), () => (s--, "")), o.setAttribute("data-set-empty", "false"), o.childNodes[1] && o.removeChild(o.childNodes[1]), this.chat.restCursorPos(n, s + t.length), e && this.chat.viewIntoPoint(), o.parentElement.parentElement
                    }, l = (t, e = !1, n) => {
                        const i = this.chat.getGridElm(), r = i.childNodes[0].childNodes[0];
                        let o = 1;
                        return t && (r.innerHTML = t, r.setAttribute("data-set-empty", "false"), o = t.length), n.nextSibling ? this.richText.insertBefore(i, n.nextSibling) : this.richText.appendChild(i), e && (this.chat.restCursorPos(r.childNodes[0], o), this.chat.viewIntoPoint()), i
                    }, h = r.split("\n");
                    if (h.length > 1) {
                        let t;
                        h.forEach((e, n) => {
                            t = 0 === n ? c(e) : l(e, n === h.length - 1, t)
                        })
                    } else c(r, !0);
                    this.richTextInput()
                }

                getCallUserList() {
                    const t = this.richText.querySelectorAll(".at-user");
                    if (t && t.length > 0) {
                        const e = Array.prototype.map.call(t, t => t.dataset.userId);
                        return d(this.targetUserList, e, this.userProps.id)
                    }
                    return []
                }

                getCallUserTagList() {
                    const t = this.richText.querySelectorAll(".at-user");
                    return t && t.length > 0 ? Array.prototype.map.call(t, t => ({
                        [this.userProps.id]: t.dataset.userId,
                        [this.userProps.name]: t.innerText.slice(1)
                    })) : []
                }

                clear(t) {
                    this.chat.textInnerHtmlInit(!0, t);
                    const e = {
                        html: this.richText.innerHTML,
                        gridIndex: 0,
                        markIndex: 0,
                        cursorIndex: this.chat.cursorIndex
                    };
                    this.undoHistory = [e], this.redoHistory = [], this.richTextInput(!1)
                }

                isEmpty(t = !1) {
                    if ((this.richText.querySelectorAll(".chat-tag") || []).length > 0) return !1;
                    const e = new RegExp(`^(${this.chat.ZERO_WIDTH_KEY}|<br>|${this.chat.VOID_KEY})+$`),
                        n = this.richText.querySelectorAll(".chat-grid-input") || [];
                    return t ? Array.prototype.every.call(n, t => !t.innerHTML || !t.innerText || !t.innerText.trim() || e.test(t.innerHTML)) : Array.prototype.every.call(n, t => !t.innerHTML || !t.innerText || e.test(t.innerHTML))
                }

                showPlaceholder() {
                    this.domElmShow(this.placeholderElm, this.isEmpty())
                }

                getReduceNode(t = {}) {
                    const e = Object.assign({}, {
                            needUserId: !1,
                            wrapClassName: void 0,
                            rowClassName: void 0,
                            imgToText: !1,
                            identifyLink: !1
                        }, t), n = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g,
                        i = this.richText.cloneNode(!0).querySelectorAll(".chat-grid-wrap") || [],
                        r = document.createElement("div");
                    return e.wrapClassName && (r.className = e.wrapClassName), Array.prototype.forEach.call(i, (t, i) => {
                        const o = t.querySelectorAll(".chat-stat") || [], s = document.createElement("p");
                        e.rowClassName && (s.className = e.rowClassName), Array.prototype.forEach.call(o, t => {
                            this.chat.getNodeEmpty(t) || (t.removeAttribute("data-set-richType"), t.removeAttribute("contenteditable"), t.removeAttribute("data-set-empty"), e.needUserId || t.removeAttribute("data-user-id"), e.imgToText && t.firstChild && "IMG" === t.firstChild.tagName && (t.className += " img-to-text", t.innerHTML = `[${t.firstChild.getAttribute("data-img-text") || "元素data-img-text未定义"}]`), e.identifyLink && -1 !== t.className.indexOf("chat-grid-input") && (t.innerHTML = t.innerHTML.replace(n, t => `<a class="chat-grid-link" href="${t}" target="_blank">${t}</a>`)), s.appendChild(t))
                        }), s.innerHTML || (s.innerHTML = "<br>"), r.appendChild(s)
                    }), r
                }

                getText(t = {}) {
                    let e = "";
                    const n = this.getReduceNode(t);
                    return Array.prototype.forEach.call(n.children, (t, n) => {
                        e = e + (n > 0 ? "\n" : "") + t.textContent
                    }), e
                }

                getHtml(t = {}) {
                    return this.getReduceNode(t).innerHTML
                }

                dispose() {
                    const t = this.richText.parentElement;
                    t && (t.removeChild(this.richText), t.removeChild(this.placeholderElm)), this.needDialog && (this.deviceInfo.isPc ? (document.body.removeChild(this.dialogElm), document.body.removeChild(this.checkboxElm), window.removeEventListener("click", this.winClick), window.removeEventListener("keydown", this.winKeydown)) : document.body.removeChild(this.dialogH5Elm));
                    for (const e in this) delete this[e];
                    Object.setPrototypeOf(this, Object)
                }

                setUserTag(t) {
                    const e = this.chat.createAtUserSpan({id: t[this.userProps.id], name: t[this.userProps.name]});
                    this.chat.replaceRegContent(e, !1), this.chat.viewIntoPoint(), this.richTextInput()
                }

                async matchSetTag(t) {
                    await this.chat.searchCall(t, this.startOpenIndex), await this.richTextInput()
                }

                async onceSetTag(t) {
                    await this.chat.onceCall({
                        id: t[this.userProps.id],
                        name: t[this.userProps.name]
                    }), await this.richTextInput()
                }

                async batchSetTag(t) {
                    if (1 === t.length) return void (this.isExternalCallPopup ? await this.chat.onceExternalCall(t[0]) : await this.onceSetTag(t[0]));
                    let e = "";
                    for (let n = 0; n <= t.length - 1;) {
                        const i = {id: t[n][this.userProps.id], name: t[n][this.userProps.name]};
                        0 === n ? e = this.isExternalCallPopup ? await this.chat.onceExternalCall(i) : await this.chat.onceCall(i, !0) : n === t.length - 1 ? await this.chat.onceCall(i, !1, e) : await this.chat.onceCall(i, !0), n++
                    }
                    await this.richTextInput()
                }

                disabled() {
                    this.richText.setAttribute("contenteditable", "false"), this.richText.className = this.richText.className.replace(/ chat-rich-text-disabled/g, "") + " chat-rich-text-disabled"
                }

                enable() {
                    this.richText.setAttribute("contenteditable", "true"), this.richText.className = this.richText.className.replace(/ chat-rich-text-disabled/g, ""), this.chat.setRangeLastText()
                }

                undo() {
                    if (!this.doOverHistory || !this.undoHistory || this.undoHistory.length <= 1) return;
                    const t = this.undoHistory[this.undoHistory.length - 2],
                        e = this.undoHistory[this.undoHistory.length - 1];
                    this.redoHistory.push(e), this.undoHistory.pop(), this.setChatHistory(t, !1)
                }

                redo() {
                    if (!this.doOverHistory || !this.redoHistory || this.redoHistory.length < 1) return;
                    const t = this.redoHistory[this.redoHistory.length - 1];
                    this.redoHistory.pop(), this.setChatHistory(t, !0)
                }

                ruleMaxLength() {
                    if (this.isEmpty() || void 0 === this.maxLength) return void (this.textLength = 0);
                    let t = 0, e = 0;
                    const n = [];
                    Array.prototype.some.call(this.richText.children, (i, r) => {
                        const {nodeInfos: o, nodeTextLength: s} = this.getGirdNodeTextInfo(i);
                        if (t += s, n.push(o), e = r, t >= this.maxLength) return !0
                    });
                    const i = [];
                    Array.prototype.forEach.call(this.richText.children, (t, n) => {
                        n > e && i.push(t)
                    }), i.forEach(t => this.richText.removeChild(t)), this.deepDelGirdText(n, t)
                }

                getGirdNodeTextInfo(t) {
                    const e = [];
                    let n = 0;
                    if (1 === t.children.length && t !== t.parentElement.children[0]) {
                        const i = t.children[0],
                            r = (i.textContent || "").replace(new RegExp(this.chat.VOID_KEY, "g"), "");
                        n += r.length || 1, e[0] = {node: i, textLength: r.length || 1, type: "richMark"}
                    } else Array.prototype.forEach.call(t.children, (t, i) => {
                        if ("richMark" === t.getAttribute("data-set-richType")) {
                            const r = (t.textContent || "").replace(new RegExp(this.chat.VOID_KEY, "g"), "");
                            n += r.length, e[i] = {node: t, textLength: r.length, type: "richMark"}
                        } else {
                            const r = (t.textContent || "").replace(new RegExp(this.chat.VOID_KEY, "g"), "");
                            n += r.length || 1, e[i] = {node: t, textLength: r.length || 1, type: "chatTag"}
                        }
                    });
                    return {nodeInfos: e, nodeTextLength: n}
                }

                deepDelGirdText(t, e) {
                    if (e > this.maxLength) {
                        const n = t[t.length - 1];
                        t.pop(), this.deepDelNode(n, t, e)
                    } else this.textLength = e
                }

                deepDelNode(t, e, n) {
                    const i = t[0].node.parentElement;
                    if (n > this.maxLength) {
                        let r = n - this.maxLength, o = t[t.length - 1];
                        if ("richMark" === o.type) if (0 === o.textLength || r >= o.textLength) i.removeChild(o.node), t.pop(), r -= o.textLength, o = t[t.length - 1], i.removeChild(o.node), t.pop(), r -= o.textLength; else {
                            const t = o.node.childNodes[0];
                            t.textContent = t.textContent.slice(0, o.textLength - r), 0 === t.textContent && (t.setAttribute("data-set-empty", "true"), t.innerHTML = this.chat.VOID_KEY + "<br>"), r = 0
                        } else i.removeChild(o.node), t.pop(), r -= o.textLength;
                        r > 0 ? t.length > 0 ? this.deepDelNode(t, e, r + this.maxLength) : (this.richText.appendChild(i), this.deepDelGirdText(e, r + this.maxLength)) : (this.textLength = this.maxLength + r, this.enable())
                    }
                }

                setChatHistory(t, e) {
                    this.doOverHistory = !1;
                    const {html: n, gridIndex: i, markIndex: r, cursorIndex: o} = t;
                    this.richText.innerHTML = n;
                    const s = this.richText.childNodes[i].childNodes[r].childNodes[0].childNodes[0];
                    this.chat.restCursorPos(s, o), this.chat.viewIntoPoint(), this.richTextInput(e).then(() => {
                        this.doOverHistory = !0
                    })
                }

                revisePCPointDialogLabel(t = {}) {
                    const e = Object.assign({}, {title: "群成员", checkLabel: "多选"}, t);
                    this.dialogElm.querySelector(".call-user-dialog-header-title").innerText = e.title, this.dialogCheckElm.innerText = e.checkLabel
                }

                revisePCCheckDialogLabel(t = {}) {
                    const e = Object.assign({}, {
                        title: "选择要@的人",
                        searchPlaceholder: "搜素人员名称",
                        searchEmptyLabel: "没有匹配到任何结果",
                        userTagTitle: "研讨成员列表",
                        checkAllLabel: "全选",
                        confirmLabel: "确定",
                        cancelLabel: "取消"
                    }, t);
                    this.checkboxElm.querySelector(".checkbox-dialog-container-header").children[0].innerText = e.title, this.searchElm.setAttribute("placeholder", e.searchPlaceholder || ""), this.searchEmptyLabel = e.searchEmptyLabel || "", this.checkboxElm.querySelector(".checkbox-dialog-right-box-title").innerText = e.userTagTitle, this.checkAllLabel = e.checkAllLabel || "", this.checkGroupElm.children[0].children[2].innerText = this.checkAllLabel, this.checkboxElm.querySelector(".btn-submit").innerText = e.confirmLabel, this.checkboxElm.querySelector(".btn-close").innerText = e.cancelLabel
                }

                reviseH5DialogLabel(t) {
                    const e = Object.assign({}, {
                        title: "选择提醒的人",
                        searchPlaceholder: "搜素人员名称",
                        confirmLabel: "确定",
                        cancelLabel: "收起"
                    }, t);
                    this.dialogH5Elm.querySelector(".popup-title").innerText = e.title, this.dialogH5SearchElm.setAttribute("placeholder", e.searchPlaceholder || ""), this.dialogH5CheckElm.innerText = e.confirmLabel, this.dialogH5ShowElm.innerText = e.cancelLabel
                }

                reverseAnalysis(t, e) {
                    const n = document.createElement("div");
                    n.innerHTML = t;
                    const i = n.children;
                    Array.prototype.forEach.call(i, t => {
                        t.className = "chat-grid-wrap", t.setAttribute("data-set-richType", "richBox");
                        const e = t.children, n = {}, i = [];
                        Array.prototype.forEach.call(e, (r, o) => {
                            if (-1 !== r.className.indexOf("chat-grid-input")) {
                                const t = r.innerText;
                                return r.className = "", r.setAttribute("data-set-richType", "richMark"), void (r.innerHTML = `<span class="chat-grid-input chat-stat" data-set-richType="richInput" data-set-empty="false">${t}</span>`)
                            }
                            if ("BR" === r.tagName) {
                                const e = this.chat.getGridElm(!0);
                                return t.removeChild(r), void t.appendChild(e)
                            }
                            const s = r.cloneNode(!0);
                            s.setAttribute("contenteditable", "false");
                            const a = document.createElement("span");
                            a.className = "chat-tag", a.setAttribute("contenteditable", "false"), a.setAttribute("data-set-richType", "chatTag"), a.appendChild(s), n[o] = a, o !== e.length - 1 ? -1 === e[o + 1].className.indexOf("chat-grid-input") && i.push(o) : i.push(o), 0 === o && i.push(-1)
                        });
                        for (const s in n) {
                            const i = Number(s);
                            i === e.length - 1 ? (t.removeChild(e[i]), t.appendChild(n[s])) : (t.insertBefore(n[s], e[i + 1]), t.removeChild(e[i]))
                        }
                        const r = [], o = t.children;
                        i.forEach(t => {
                            t === o.length - 1 ? r.push("isEnd") : r.push(o[t + 1])
                        }), r.forEach(e => {
                            const n = this.chat.getGridElm(!0);
                            if ("isEnd" === e) t.appendChild(n); else {
                                const i = n.children[0];
                                i.removeChild(i.childNodes[1]), t.insertBefore(n, e)
                            }
                        })
                    }), e ? (this.enable(), this.insertInsideHtml(n.innerHTML)) : (this.richText.innerHTML = n.innerHTML, this.enable(), this.chat.viewIntoPoint(), this.richTextInput())
                }

                addEventListener(t, e) {
                    this.chatEventModule[t].push(e)
                }

                removeEventListener(t, e) {
                    const n = this.chatEventModule[t], i = n.indexOf(e);
                    -1 !== i && n.splice(i, 1)
                }

                triggerChatEvent(t, ...e) {
                    let n;
                    return this.chatEventModule[t].forEach(t => {
                        t && (n ? t(...e) : n = t(...e))
                    }), n
                }

                ruleChatEvent(t, e, ...n) {
                    "PREVENT" !== (this.triggerChatEvent(e, ...n) + "").toUpperCase() && (t && t.bind(this)(), t = null)
                }
            }

            if (!window) throw new Error("非web环境！");
            window.console && window.console.log && console.log(" %c ".concat("ChatArea", " %c v4.6.5 "), "background: #269AFF; color: #FFFFFF; padding: 4px 0; border-radius: 4px 0px 0px 4px; font-style: italic;", "background: #FFFFFF; color: #269AFF; padding: 2px 0; border-radius: 0px 4px 4px 0px; font-style: italic; border: 2px solid #269AFF;"), window.ChatArea = f
        }, "93f9": function (t, e, n) {
            "use strict";
            n("323d")
        }, "96cf": function (t, e, n) {
            var i = function (t) {
                "use strict";
                var e, n = Object.prototype, i = n.hasOwnProperty, r = Object.defineProperty || function (t, e, n) {
                        t[e] = n.value
                    }, o = "function" === typeof Symbol ? Symbol : {}, s = o.iterator || "@@iterator",
                    a = o.asyncIterator || "@@asyncIterator", c = o.toStringTag || "@@toStringTag";

                function l(t, e, n) {
                    return Object.defineProperty(t, e, {value: n, enumerable: !0, configurable: !0, writable: !0}), t[e]
                }

                try {
                    l({}, "")
                } catch (M) {
                    l = function (t, e, n) {
                        return t[e] = n
                    }
                }

                function h(t, e, n, i) {
                    var o = e && e.prototype instanceof v ? e : v, s = Object.create(o.prototype), a = new L(i || []);
                    return r(s, "_invoke", {value: N(t, n, a)}), s
                }

                function d(t, e, n) {
                    try {
                        return {type: "normal", arg: t.call(e, n)}
                    } catch (M) {
                        return {type: "throw", arg: M}
                    }
                }

                t.wrap = h;
                var u = "suspendedStart", p = "suspendedYield", f = "executing", m = "completed", g = {};

                function v() {
                }

                function b() {
                }

                function y() {
                }

                var x = {};
                l(x, s, (function () {
                    return this
                }));
                var E = Object.getPrototypeOf, C = E && E(E(A([])));
                C && C !== n && i.call(C, s) && (x = C);
                var w = y.prototype = v.prototype = Object.create(x);

                function T(t) {
                    ["next", "throw", "return"].forEach((function (e) {
                        l(t, e, (function (t) {
                            return this._invoke(e, t)
                        }))
                    }))
                }

                function k(t, e) {
                    function n(r, o, s, a) {
                        var c = d(t[r], t, o);
                        if ("throw" !== c.type) {
                            var l = c.arg, h = l.value;
                            return h && "object" === typeof h && i.call(h, "__await") ? e.resolve(h.__await).then((function (t) {
                                n("next", t, s, a)
                            }), (function (t) {
                                n("throw", t, s, a)
                            })) : e.resolve(h).then((function (t) {
                                l.value = t, s(l)
                            }), (function (t) {
                                return n("throw", t, s, a)
                            }))
                        }
                        a(c.arg)
                    }

                    var o;

                    function s(t, i) {
                        function r() {
                            return new e((function (e, r) {
                                n(t, i, e, r)
                            }))
                        }

                        return o = o ? o.then(r, r) : r()
                    }

                    r(this, "_invoke", {value: s})
                }

                function N(t, e, n) {
                    var i = u;
                    return function (r, o) {
                        if (i === f) throw new Error("Generator is already running");
                        if (i === m) {
                            if ("throw" === r) throw o;
                            return O()
                        }
                        n.method = r, n.arg = o;
                        while (1) {
                            var s = n.delegate;
                            if (s) {
                                var a = I(s, n);
                                if (a) {
                                    if (a === g) continue;
                                    return a
                                }
                            }
                            if ("next" === n.method) n.sent = n._sent = n.arg; else if ("throw" === n.method) {
                                if (i === u) throw i = m, n.arg;
                                n.dispatchException(n.arg)
                            } else "return" === n.method && n.abrupt("return", n.arg);
                            i = f;
                            var c = d(t, e, n);
                            if ("normal" === c.type) {
                                if (i = n.done ? m : p, c.arg === g) continue;
                                return {value: c.arg, done: n.done}
                            }
                            "throw" === c.type && (i = m, n.method = "throw", n.arg = c.arg)
                        }
                    }
                }

                function I(t, n) {
                    var i = n.method, r = t.iterator[i];
                    if (r === e) return n.delegate = null, "throw" === i && t.iterator["return"] && (n.method = "return", n.arg = e, I(t, n), "throw" === n.method) || "return" !== i && (n.method = "throw", n.arg = new TypeError("The iterator does not provide a '" + i + "' method")), g;
                    var o = d(r, t.iterator, n.arg);
                    if ("throw" === o.type) return n.method = "throw", n.arg = o.arg, n.delegate = null, g;
                    var s = o.arg;
                    return s ? s.done ? (n[t.resultName] = s.value, n.next = t.nextLoc, "return" !== n.method && (n.method = "next", n.arg = e), n.delegate = null, g) : s : (n.method = "throw", n.arg = new TypeError("iterator result is not an object"), n.delegate = null, g)
                }

                function S(t) {
                    var e = {tryLoc: t[0]};
                    1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), this.tryEntries.push(e)
                }

                function _(t) {
                    var e = t.completion || {};
                    e.type = "normal", delete e.arg, t.completion = e
                }

                function L(t) {
                    this.tryEntries = [{tryLoc: "root"}], t.forEach(S, this), this.reset(!0)
                }

                function A(t) {
                    if (null != t) {
                        var n = t[s];
                        if (n) return n.call(t);
                        if ("function" === typeof t.next) return t;
                        if (!isNaN(t.length)) {
                            var r = -1, o = function n() {
                                while (++r < t.length) if (i.call(t, r)) return n.value = t[r], n.done = !1, n;
                                return n.value = e, n.done = !0, n
                            };
                            return o.next = o
                        }
                    }
                    throw new TypeError(typeof t + " is not iterable")
                }

                function O() {
                    return {value: e, done: !0}
                }

                return b.prototype = y, r(w, "constructor", {
                    value: y,
                    configurable: !0
                }), r(y, "constructor", {
                    value: b,
                    configurable: !0
                }), b.displayName = l(y, c, "GeneratorFunction"), t.isGeneratorFunction = function (t) {
                    var e = "function" === typeof t && t.constructor;
                    return !!e && (e === b || "GeneratorFunction" === (e.displayName || e.name))
                }, t.mark = function (t) {
                    return Object.setPrototypeOf ? Object.setPrototypeOf(t, y) : (t.__proto__ = y, l(t, c, "GeneratorFunction")), t.prototype = Object.create(w), t
                }, t.awrap = function (t) {
                    return {__await: t}
                }, T(k.prototype), l(k.prototype, a, (function () {
                    return this
                })), t.AsyncIterator = k, t.async = function (e, n, i, r, o) {
                    void 0 === o && (o = Promise);
                    var s = new k(h(e, n, i, r), o);
                    return t.isGeneratorFunction(n) ? s : s.next().then((function (t) {
                        return t.done ? t.value : s.next()
                    }))
                }, T(w), l(w, c, "Generator"), l(w, s, (function () {
                    return this
                })), l(w, "toString", (function () {
                    return "[object Generator]"
                })), t.keys = function (t) {
                    var e = Object(t), n = [];
                    for (var i in e) n.push(i);
                    return n.reverse(), function t() {
                        while (n.length) {
                            var i = n.pop();
                            if (i in e) return t.value = i, t.done = !1, t
                        }
                        return t.done = !0, t
                    }
                }, t.values = A, L.prototype = {
                    constructor: L, reset: function (t) {
                        if (this.prev = 0, this.next = 0, this.sent = this._sent = e, this.done = !1, this.delegate = null, this.method = "next", this.arg = e, this.tryEntries.forEach(_), !t) for (var n in this) "t" === n.charAt(0) && i.call(this, n) && !isNaN(+n.slice(1)) && (this[n] = e)
                    }, stop: function () {
                        this.done = !0;
                        var t = this.tryEntries[0], e = t.completion;
                        if ("throw" === e.type) throw e.arg;
                        return this.rval
                    }, dispatchException: function (t) {
                        if (this.done) throw t;
                        var n = this;

                        function r(i, r) {
                            return a.type = "throw", a.arg = t, n.next = i, r && (n.method = "next", n.arg = e), !!r
                        }

                        for (var o = this.tryEntries.length - 1; o >= 0; --o) {
                            var s = this.tryEntries[o], a = s.completion;
                            if ("root" === s.tryLoc) return r("end");
                            if (s.tryLoc <= this.prev) {
                                var c = i.call(s, "catchLoc"), l = i.call(s, "finallyLoc");
                                if (c && l) {
                                    if (this.prev < s.catchLoc) return r(s.catchLoc, !0);
                                    if (this.prev < s.finallyLoc) return r(s.finallyLoc)
                                } else if (c) {
                                    if (this.prev < s.catchLoc) return r(s.catchLoc, !0)
                                } else {
                                    if (!l) throw new Error("try statement without catch or finally");
                                    if (this.prev < s.finallyLoc) return r(s.finallyLoc)
                                }
                            }
                        }
                    }, abrupt: function (t, e) {
                        for (var n = this.tryEntries.length - 1; n >= 0; --n) {
                            var r = this.tryEntries[n];
                            if (r.tryLoc <= this.prev && i.call(r, "finallyLoc") && this.prev < r.finallyLoc) {
                                var o = r;
                                break
                            }
                        }
                        o && ("break" === t || "continue" === t) && o.tryLoc <= e && e <= o.finallyLoc && (o = null);
                        var s = o ? o.completion : {};
                        return s.type = t, s.arg = e, o ? (this.method = "next", this.next = o.finallyLoc, g) : this.complete(s)
                    }, complete: function (t, e) {
                        if ("throw" === t.type) throw t.arg;
                        return "break" === t.type || "continue" === t.type ? this.next = t.arg : "return" === t.type ? (this.rval = this.arg = t.arg, this.method = "return", this.next = "end") : "normal" === t.type && e && (this.next = e), g
                    }, finish: function (t) {
                        for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                            var n = this.tryEntries[e];
                            if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), _(n), g
                        }
                    }, catch: function (t) {
                        for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                            var n = this.tryEntries[e];
                            if (n.tryLoc === t) {
                                var i = n.completion;
                                if ("throw" === i.type) {
                                    var r = i.arg;
                                    _(n)
                                }
                                return r
                            }
                        }
                        throw new Error("illegal catch attempt")
                    }, delegateYield: function (t, n, i) {
                        return this.delegate = {
                            iterator: A(t),
                            resultName: n,
                            nextLoc: i
                        }, "next" === this.method && (this.arg = e), g
                    }
                }, t
            }(t.exports);
            try {
                regeneratorRuntime = i
            } catch (r) {
                "object" === typeof globalThis ? globalThis.regeneratorRuntime = i : Function("r", "regeneratorRuntime = r")(i)
            }
        }, "990b": function (t, e, n) {
            var i = n("9093"), r = n("2621"), o = n("cb7c"), s = n("7726").Reflect;
            t.exports = s && s.ownKeys || function (t) {
                var e = i.f(o(t)), n = r.f;
                return n ? e.concat(n(t)) : e
            }
        }, "9b43": function (t, e, n) {
            var i = n("d8e8");
            t.exports = function (t, e, n) {
                if (i(t), void 0 === e) return t;
                switch (n) {
                    case 1:
                        return function (n) {
                            return t.call(e, n)
                        };
                    case 2:
                        return function (n, i) {
                            return t.call(e, n, i)
                        };
                    case 3:
                        return function (n, i, r) {
                            return t.call(e, n, i, r)
                        }
                }
                return function () {
                    return t.apply(e, arguments)
                }
            }
        }, "9c6c": function (t, e, n) {
            var i = n("2b4c")("unscopables"), r = Array.prototype;
            void 0 == r[i] && n("32e9")(r, i, {}), t.exports = function (t) {
                r[i][t] = !0
            }
        }, "9def": function (t, e, n) {
            var i = n("4588"), r = Math.min;
            t.exports = function (t) {
                return t > 0 ? r(i(t), 9007199254740991) : 0
            }
        }, "9e1e": function (t, e, n) {
            t.exports = !n("79e5")((function () {
                return 7 != Object.defineProperty({}, "a", {
                    get: function () {
                        return 7
                    }
                }).a
            }))
        }, a481: function (t, e, n) {
            "use strict";
            var i = n("cb7c"), r = n("4bf8"), o = n("9def"), s = n("4588"), a = n("0390"), c = n("5f1b"), l = Math.max,
                h = Math.min, d = Math.floor, u = /\$([$&`']|\d\d?|<[^>]*>)/g, p = /\$([$&`']|\d\d?)/g,
                f = function (t) {
                    return void 0 === t ? t : String(t)
                };
            n("214f")("replace", 2, (function (t, e, n, m) {
                return [function (i, r) {
                    var o = t(this), s = void 0 == i ? void 0 : i[e];
                    return void 0 !== s ? s.call(i, o, r) : n.call(String(o), i, r)
                }, function (t, e) {
                    var r = m(n, t, this, e);
                    if (r.done) return r.value;
                    var d = i(t), u = String(this), p = "function" === typeof e;
                    p || (e = String(e));
                    var v = d.global;
                    if (v) {
                        var b = d.unicode;
                        d.lastIndex = 0
                    }
                    var y = [];
                    while (1) {
                        var x = c(d, u);
                        if (null === x) break;
                        if (y.push(x), !v) break;
                        var E = String(x[0]);
                        "" === E && (d.lastIndex = a(u, o(d.lastIndex), b))
                    }
                    for (var C = "", w = 0, T = 0; T < y.length; T++) {
                        x = y[T];
                        for (var k = String(x[0]), N = l(h(s(x.index), u.length), 0), I = [], S = 1; S < x.length; S++) I.push(f(x[S]));
                        var _ = x.groups;
                        if (p) {
                            var L = [k].concat(I, N, u);
                            void 0 !== _ && L.push(_);
                            var A = String(e.apply(void 0, L))
                        } else A = g(k, u, N, I, _, e);
                        N >= w && (C += u.slice(w, N) + A, w = N + k.length)
                    }
                    return C + u.slice(w)
                }];

                function g(t, e, i, o, s, a) {
                    var c = i + t.length, l = o.length, h = p;
                    return void 0 !== s && (s = r(s), h = u), n.call(a, h, (function (n, r) {
                        var a;
                        switch (r.charAt(0)) {
                            case"$":
                                return "$";
                            case"&":
                                return t;
                            case"`":
                                return e.slice(0, i);
                            case"'":
                                return e.slice(c);
                            case"<":
                                a = s[r.slice(1, -1)];
                                break;
                            default:
                                var h = +r;
                                if (0 === h) return n;
                                if (h > l) {
                                    var u = d(h / 10);
                                    return 0 === u ? n : u <= l ? void 0 === o[u - 1] ? r.charAt(1) : o[u - 1] + r.charAt(1) : n
                                }
                                a = o[h - 1]
                        }
                        return void 0 === a ? "" : a
                    }))
                }
            }))
        }, aa77: function (t, e, n) {
            var i = n("5ca1"), r = n("be13"), o = n("79e5"), s = n("fdef"), a = "[" + s + "]", c = "​",
                l = RegExp("^" + a + a + "*"), h = RegExp(a + a + "*$"), d = function (t, e, n) {
                    var r = {}, a = o((function () {
                        return !!s[t]() || c[t]() != c
                    })), l = r[t] = a ? e(u) : s[t];
                    n && (r[n] = l), i(i.P + i.F * a, "String", r)
                }, u = d.trim = function (t, e) {
                    return t = String(r(t)), 1 & e && (t = t.replace(l, "")), 2 & e && (t = t.replace(h, "")), t
                };
            t.exports = d
        }, aae3: function (t, e, n) {
            var i = n("d3f4"), r = n("2d95"), o = n("2b4c")("match");
            t.exports = function (t) {
                var e;
                return i(t) && (void 0 !== (e = t[o]) ? !!e : "RegExp" == r(t))
            }
        }, ab30: function (t, e, n) {
        }, ac6a: function (t, e, n) {
            for (var i = n("cadf"), r = n("0d58"), o = n("2aba"), s = n("7726"), a = n("32e9"), c = n("84f2"), l = n("2b4c"), h = l("iterator"), d = l("toStringTag"), u = c.Array, p = {
                CSSRuleList: !0,
                CSSStyleDeclaration: !1,
                CSSValueList: !1,
                ClientRectList: !1,
                DOMRectList: !1,
                DOMStringList: !1,
                DOMTokenList: !0,
                DataTransferItemList: !1,
                FileList: !1,
                HTMLAllCollection: !1,
                HTMLCollection: !1,
                HTMLFormElement: !1,
                HTMLSelectElement: !1,
                MediaList: !0,
                MimeTypeArray: !1,
                NamedNodeMap: !1,
                NodeList: !0,
                PaintRequestList: !1,
                Plugin: !1,
                PluginArray: !1,
                SVGLengthList: !1,
                SVGNumberList: !1,
                SVGPathSegList: !1,
                SVGPointList: !1,
                SVGStringList: !1,
                SVGTransformList: !1,
                SourceBufferList: !1,
                StyleSheetList: !0,
                TextTrackCueList: !1,
                TextTrackList: !1,
                TouchList: !1
            }, f = r(p), m = 0; m < f.length; m++) {
                var g, v = f[m], b = p[v], y = s[v], x = y && y.prototype;
                if (x && (x[h] || a(x, h, u), x[d] || a(x, d, v), c[v] = u, b)) for (g in i) x[g] || o(x, g, i[g], !0)
            }
        }, b0c5: function (t, e, n) {
            "use strict";
            var i = n("520a");
            n("5ca1")({target: "RegExp", proto: !0, forced: i !== /./.exec}, {exec: i})
        }, b25a: function (t, e, n) {
            "use strict";
            n("ab30")
        }, ba05: function (t, e, n) {
        }, bd0d: function (t, e, n) {
            "use strict";
            n("cfe1")
        }, be13: function (t, e) {
            t.exports = function (t) {
                if (void 0 == t) throw TypeError("Can't call method on  " + t);
                return t
            }
        }, c366: function (t, e, n) {
            var i = n("6821"), r = n("9def"), o = n("77f1");
            t.exports = function (t) {
                return function (e, n, s) {
                    var a, c = i(e), l = r(c.length), h = o(s, l);
                    if (t && n != n) {
                        while (l > h) if (a = c[h++], a != a) return !0
                    } else for (; l > h; h++) if ((t || h in c) && c[h] === n) return t || h || 0;
                    return !t && -1
                }
            }
        }, c5f6: function (t, e, n) {
            "use strict";
            var i = n("7726"), r = n("69a8"), o = n("2d95"), s = n("5dbc"), a = n("6a99"), c = n("79e5"),
                l = n("9093").f, h = n("11e9").f, d = n("86cc").f, u = n("aa77").trim, p = "Number", f = i[p], m = f,
                g = f.prototype, v = o(n("2aeb")(g)) == p, b = "trim" in String.prototype, y = function (t) {
                    var e = a(t, !1);
                    if ("string" == typeof e && e.length > 2) {
                        e = b ? e.trim() : u(e, 3);
                        var n, i, r, o = e.charCodeAt(0);
                        if (43 === o || 45 === o) {
                            if (n = e.charCodeAt(2), 88 === n || 120 === n) return NaN
                        } else if (48 === o) {
                            switch (e.charCodeAt(1)) {
                                case 66:
                                case 98:
                                    i = 2, r = 49;
                                    break;
                                case 79:
                                case 111:
                                    i = 8, r = 55;
                                    break;
                                default:
                                    return +e
                            }
                            for (var s, c = e.slice(2), l = 0, h = c.length; l < h; l++) if (s = c.charCodeAt(l), s < 48 || s > r) return NaN;
                            return parseInt(c, i)
                        }
                    }
                    return +e
                };
            if (!f(" 0o1") || !f("0b1") || f("+0x1")) {
                f = function (t) {
                    var e = arguments.length < 1 ? 0 : t, n = this;
                    return n instanceof f && (v ? c((function () {
                        g.valueOf.call(n)
                    })) : o(n) != p) ? s(new m(y(e)), n, f) : y(e)
                };
                for (var x, E = n("9e1e") ? l(m) : "MAX_VALUE,MIN_VALUE,NaN,NEGATIVE_INFINITY,POSITIVE_INFINITY,EPSILON,isFinite,isInteger,isNaN,isSafeInteger,MAX_SAFE_INTEGER,MIN_SAFE_INTEGER,parseFloat,parseInt,isInteger".split(","), C = 0; E.length > C; C++) r(m, x = E[C]) && !r(f, x) && d(f, x, h(m, x));
                f.prototype = g, g.constructor = f, n("2aba")(i, p, f)
            }
        }, c69a: function (t, e, n) {
            t.exports = !n("9e1e") && !n("79e5")((function () {
                return 7 != Object.defineProperty(n("230e")("div"), "a", {
                    get: function () {
                        return 7
                    }
                }).a
            }))
        }, ca5a: function (t, e) {
            var n = 0, i = Math.random();
            t.exports = function (t) {
                return "Symbol(".concat(void 0 === t ? "" : t, ")_", (++n + i).toString(36))
            }
        }, cadf: function (t, e, n) {
            "use strict";
            var i = n("9c6c"), r = n("d53b"), o = n("84f2"), s = n("6821");
            t.exports = n("01f9")(Array, "Array", (function (t, e) {
                this._t = s(t), this._i = 0, this._k = e
            }), (function () {
                var t = this._t, e = this._k, n = this._i++;
                return !t || n >= t.length ? (this._t = void 0, r(1)) : r(0, "keys" == e ? n : "values" == e ? t[n] : [n, t[n]])
            }), "values"), o.Arguments = o.Array, i("keys"), i("values"), i("entries")
        }, cb50: function (t, e, n) {
        }, cb7c: function (t, e, n) {
            var i = n("d3f4");
            t.exports = function (t) {
                if (!i(t)) throw TypeError(t + " is not an object!");
                return t
            }
        }, cc9a: function (t, e, n) {
            "use strict";
            n("8bcf")
        }, cd1c: function (t, e, n) {
            var i = n("e853");
            t.exports = function (t, e) {
                return new (i(t))(e)
            }
        }, ce10: function (t, e, n) {
            var i = n("69a8"), r = n("6821"), o = n("c366")(!1), s = n("613b")("IE_PROTO");
            t.exports = function (t, e) {
                var n, a = r(t), c = 0, l = [];
                for (n in a) n != s && i(a, n) && l.push(n);
                while (e.length > c) i(a, n = e[c++]) && (~o(l, n) || l.push(n));
                return l
            }
        }, cfe1: function (t, e, n) {
        }, d2c8: function (t, e, n) {
            var i = n("aae3"), r = n("be13");
            t.exports = function (t, e, n) {
                if (i(e)) throw TypeError("String#" + n + " doesn't accept regex!");
                return String(r(t))
            }
        }, d3f4: function (t, e) {
            t.exports = function (t) {
                return "object" === typeof t ? null !== t : "function" === typeof t
            }
        }, d53b: function (t, e) {
            t.exports = function (t, e) {
                return {value: e, done: !!t}
            }
        }, d6dd: function (t, e, n) {
        }, d6f8: function (t, e, n) {
        }, d8e8: function (t, e) {
            t.exports = function (t) {
                if ("function" != typeof t) throw TypeError(t + " is not a function!");
                return t
            }
        }, dcc3: function (t, e, n) {
            "use strict";
            n("117e")
        }, dfa9: function (t, e, n) {
        }, e003: function (t, e, n) {
            "use strict";
            n("61c8")
        }, e11e: function (t, e) {
            t.exports = "constructor,hasOwnProperty,isPrototypeOf,propertyIsEnumerable,toLocaleString,toString,valueOf".split(",")
        }, e61c: function (t, e, n) {
            "use strict";
            n("d6dd")
        }, e853: function (t, e, n) {
            var i = n("d3f4"), r = n("1169"), o = n("2b4c")("species");
            t.exports = function (t) {
                var e;
                return r(t) && (e = t.constructor, "function" != typeof e || e !== Array && !r(e.prototype) || (e = void 0), i(e) && (e = e[o], null === e && (e = void 0))), void 0 === e ? Array : e
            }
        }, f1ae: function (t, e, n) {
            "use strict";
            var i = n("86cc"), r = n("4630");
            t.exports = function (t, e, n) {
                e in t ? i.f(t, e, r(0, n)) : t[e] = n
            }
        }, f559: function (t, e, n) {
            "use strict";
            var i = n("5ca1"), r = n("9def"), o = n("d2c8"), s = "startsWith", a = ""[s];
            i(i.P + i.F * n("5147")(s), "String", {
                startsWith: function (t) {
                    var e = o(this, t, s), n = r(Math.min(arguments.length > 1 ? arguments[1] : void 0, e.length)),
                        i = String(t);
                    return a ? a.call(e, i, n) : e.slice(n, n + i.length) === i
                }
            })
        }, f6fd: function (t, e) {
            (function (t) {
                var e = "currentScript", n = t.getElementsByTagName("script");
                e in t || Object.defineProperty(t, e, {
                    get: function () {
                        try {
                            throw new Error
                        } catch (i) {
                            var t, e = (/.*at [^\(]*\((.*):.+:.+\)$/gi.exec(i.stack) || [!1])[1];
                            for (t in n) if (n[t].src == e || "interactive" == n[t].readyState) return n[t];
                            return null
                        }
                    }
                })
            })(document)
        }, f751: function (t, e, n) {
            var i = n("5ca1");
            i(i.S + i.F, "Object", {assign: n("7333")})
        }, fa5b: function (t, e, n) {
            t.exports = n("5537")("native-function-to-string", Function.toString)
        }, fab2: function (t, e, n) {
            var i = n("7726").document;
            t.exports = i && i.documentElement
        }, fb15: function (t, e, n) {
            "use strict";
            var i;
            (n.r(e), "undefined" !== typeof window) && (n("f6fd"), (i = window.document.currentScript) && (i = i.src.match(/(.+\/)[^/]+\.js(\?.*)?$/)) && (n.p = i[1]));
            n("7f7f"), n("ac6a"), n("3b2b"), n("cadf"), n("8615"), n("6b54");

            function r(t) {
                return "[object Object]" === Object.prototype.toString.call(t)
            }

            function o(t) {
                return "string" == typeof t
            }

            function s(t) {
                return (new Date).getTime() - t < 864e5
            }

            function a(t) {
                return !t || (!(!Array.isArray(t) || 0 != t.length) || !(!r(t) || 0 != Object.values(t).length))
            }

            function c(t) {
                return t && "function" === typeof t
            }

            n("96cf");

            function l(t, e, n, i, r, o, s) {
                try {
                    var a = t[o](s), c = a.value
                } catch (l) {
                    return void n(l)
                }
                a.done ? e(c) : Promise.resolve(c).then(i, r)
            }

            function h(t) {
                return function () {
                    var e = this, n = arguments;
                    return new Promise((function (i, r) {
                        var o = t.apply(e, n);

                        function s(t) {
                            l(o, i, r, s, a, "next", t)
                        }

                        function a(t) {
                            l(o, i, r, s, a, "throw", t)
                        }

                        s(void 0)
                    }))
                }
            }

            n("456d"), n("6762"), n("2fdb");
            var d, u, p = [], f = {
                hover: function (t) {
                }, focus: function (t) {
                    var e = this;
                    t.addEventListener("focus", (function (t) {
                        e.changeVisible()
                    })), t.addEventListener("blur", (function (t) {
                        e.changeVisible()
                    }))
                }, click: function (t) {
                    var e = this;
                    t.addEventListener("click", (function (t) {
                        t.stopPropagation(), A.hide(), e.changeVisible()
                    }))
                }, contextmenu: function (t) {
                    var e = this;
                    t.addEventListener("contextmenu", (function (t) {
                        t.preventDefault(), e.changeVisible()
                    }))
                }
            }, m = {
                name: "LemonPopover", props: {
                    trigger: {
                        type: String, default: "click", validator: function (t) {
                            return Object.keys(f).includes(t)
                        }
                    }
                }, data: function () {
                    return {popoverStyle: {}, visible: !1}
                }, created: function () {
                    document.addEventListener("click", this._documentClickEvent), p.push(this.close)
                }, mounted: function () {
                    f[this.trigger].call(this, this.$slots.default[0].elm)
                }, render: function () {
                    var t = arguments[0];
                    return t("span", {style: "position:relative"}, [t("transition", {attrs: {name: "lemon-slide-top"}}, [this.visible && t("div", {
                        class: "lemon-popover",
                        ref: "popover",
                        style: this.popoverStyle,
                        on: {
                            click: function (t) {
                                return t.stopPropagation()
                            }
                        }
                    }, [t("div", {class: "lemon-popover__content"}, [this.$slots.content]), t("div", {class: "lemon-popover__arrow"})])]), this.$slots.default])
                }, destroyed: function () {
                    document.removeEventListener("click", this._documentClickEvent)
                }, computed: {}, watch: {
                    visible: function () {
                        var t = h(regeneratorRuntime.mark((function t(e) {
                            var n, i;
                            return regeneratorRuntime.wrap((function (t) {
                                while (1) switch (t.prev = t.next) {
                                    case 0:
                                        if (!e) {
                                            t.next = 6;
                                            break
                                        }
                                        return t.next = 3, this.$nextTick();
                                    case 3:
                                        n = this.$slots.default[0].elm, i = this.$refs.popover, this.popoverStyle = {
                                            top: "-".concat(i.offsetHeight + 10, "px"),
                                            left: "".concat(n.offsetWidth / 2 - i.offsetWidth / 2, "px")
                                        };
                                    case 6:
                                    case"end":
                                        return t.stop()
                                }
                            }), t, this)
                        })));

                        function e(e) {
                            return t.apply(this, arguments)
                        }

                        return e
                    }()
                }, methods: {
                    _documentClickEvent: function (t) {
                        t.stopPropagation(), this.visible && this.close()
                    }, changeVisible: function () {
                        this.visible ? this.close() : this.open()
                    }, open: function () {
                        this.closeAll(), this.visible = !0
                    }, closeAll: function () {
                        p.forEach((function (t) {
                            return t()
                        }))
                    }, close: function () {
                        this.visible = !1
                    }
                }
            }, g = m;
            n("718e");

            function v(t, e, n, i, r, o, s, a) {
                var c, l = "function" === typeof t ? t.options : t;
                if (e && (l.render = e, l.staticRenderFns = n, l._compiled = !0), i && (l.functional = !0), o && (l._scopeId = "data-v-" + o), s ? (c = function (t) {
                    t = t || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext, t || "undefined" === typeof __VUE_SSR_CONTEXT__ || (t = __VUE_SSR_CONTEXT__), r && r.call(this, t), t && t._registeredComponents && t._registeredComponents.add(s)
                }, l._ssrRegister = c) : r && (c = a ? function () {
                    r.call(this, (l.functional ? this.parent : this).$root.$options.shadowRoot)
                } : r), c) if (l.functional) {
                    l._injectStyles = c;
                    var h = l.render;
                    l.render = function (t, e) {
                        return c.call(e), h(t, e)
                    }
                } else {
                    var d = l.beforeCreate;
                    l.beforeCreate = d ? [].concat(d, c) : [c]
                }
                return {exports: t, options: l}
            }

            var b, y = v(g, d, u, !1, null, null, null), x = y.exports, E = function () {
                b && (b.style.display = "none")
            }, C = function () {
                b && (b.style.display = "block")
            };
            document.addEventListener("click", (function (t) {
                E()
            }));
            var w, T, k, N, I, S, _, L, A = {
                hide: E, bind: function (t, e, n) {
                    t.addEventListener(e.modifiers.click ? "click" : "contextmenu", (function (t) {
                        if (!a(e.value) && Array.isArray(e.value)) {
                            var i;
                            e.modifiers.click && t.stopPropagation(), t.preventDefault(), x.methods.closeAll();
                            var r = [];
                            e.modifiers.message ? i = n.context : e.modifiers.contact && (i = n.child), b || (b = document.createElement("div"), b.className = "lemon-contextmenu", document.body.appendChild(b)), b.innerHTML = e.value.map((function (t) {
                                var e;
                                if (e = c(t.visible) ? t.visible(i) : void 0 === t.visible || t.visible, e) {
                                    r.push(t);
                                    var n = t.icon ? '<i class="lemon-contextmenu__icon '.concat(t.icon, '"></i>') : "";
                                    return '<div style="color:'.concat(t.color, '" title="').concat(t.text, '" class="lemon-contextmenu__item">').concat(n, "<span>").concat(t.text, "</span></div>")
                                }
                                return ""
                            })).join("");
                            var o = b.offsetHeight, s = b.offsetWidth, l = window.innerHeight, h = window.innerWidth,
                                d = t.clientY + o > l ? t.pageY - o - 5 : t.pageY,
                                u = t.clientX + s > h ? t.pageX - s - 5 : t.pageX;
                            b.style.top = "".concat(d, "px"), b.style.left = "".concat(u, "px"), b.childNodes.forEach((function (t, e) {
                                var n = r[e], o = n.click;
                                n.render;
                                t.addEventListener("click", (function (t) {
                                    t.stopPropagation(), c(o) && o(t, i, E)
                                }))
                            })), C()
                        }
                    }))
                }
            }, O = {
                name: "LemonTabs", props: {activeIndex: String}, data: function () {
                    return {active: this.activeIndex}
                }, mounted: function () {
                    this.active || (this.active = this.$slots["tab-pane"][0].data.attrs.index)
                }, render: function () {
                    var t = this, e = arguments[0], n = [], i = [];
                    return this.$slots["tab-pane"].map((function (r) {
                        var o = r.data.attrs, s = o.tab, a = o.index;
                        n.push(e("div", {
                            class: "lemon-tabs-content__pane",
                            directives: [{name: "show", value: t.active == a}]
                        }, [r])), i.push(e("div", {
                            class: ["lemon-tabs-nav__item", t.active == a && "lemon-tabs-nav__item--active"],
                            on: {
                                click: function () {
                                    return t._handleNavClick(a)
                                }
                            }
                        }, [s]))
                    })), e("div", {class: "lemon-tabs"}, [e("div", {class: "lemon-tabs-content"}, [n]), e("div", {class: "lemon-tabs-nav"}, [i])])
                }, methods: {
                    _handleNavClick: function (t) {
                        this.active = t
                    }
                }
            }, M = O, D = (n("69bb"), v(M, w, T, !1, null, null, null)), P = D.exports, j = {
                name: "LemonButton",
                props: {color: {type: String, default: "default"}, disabled: Boolean},
                render: function () {
                    var t = arguments[0];
                    return t("button", {
                        class: ["lemon-button", "lemon-button--color-".concat(this.color)],
                        attrs: {disabled: this.disabled, type: "button"},
                        on: {click: this._handleClick}
                    }, [this.$slots.default])
                },
                methods: {
                    _handleClick: function (t) {
                        this.$emit("click", t)
                    }
                }
            }, H = j, R = (n("cc9a"), v(H, k, N, !1, null, null, null)), $ = R.exports, F = (n("c5f6"), {
                name: "LemonBadge",
                props: {count: [Number, Boolean], overflowCount: {type: Number, default: 99}},
                render: function () {
                    var t = arguments[0];
                    return t("span", {class: "lemon-badge"}, [this.$slots.default, 0 !== this.count && void 0 !== this.count && t("span", {class: ["lemon-badge__label", this.isDot && "lemon-badge__label--dot"]}, [this.label])])
                },
                computed: {
                    isDot: function () {
                        return !0 === this.count
                    }, label: function () {
                        return this.isDot ? "" : this.count > this.overflowCount ? "".concat(this.overflowCount, "+") : this.count
                    }
                },
                methods: {}
            }), U = F, K = (n("93f9"), v(U, I, S, !1, null, null, null)), B = K.exports, V = {
                name: "LemonAvatar",
                inject: ["IMUI"],
                props: {
                    src: String,
                    icon: {type: String, default: "lemon-icon-people"},
                    circle: {
                        type: Boolean, default: function () {
                            return !!this.IMUI && this.IMUI.avatarCricle
                        }
                    },
                    size: {type: Number, default: 32}
                },
                data: function () {
                    return {imageFinishLoad: !0}
                },
                render: function () {
                    var t = this, e = arguments[0];
                    return e("span", {
                        style: this.style,
                        class: ["lemon-avatar", {"lemon-avatar--circle": this.circle}],
                        on: {
                            click: function (e) {
                                return t.$emit("click", e)
                            }
                        }
                    }, [(this.imageFinishLoad || !this.src) && e("i", {class: this.icon}), e("img", {
                        attrs: {src: this.src},
                        on: {load: this._handleLoad}
                    })])
                },
                computed: {
                    style: function () {
                        var t = "".concat(this.size, "px");
                        return {width: t, height: t, lineHeight: t, fontSize: "".concat(this.size / 2, "px")}
                    }
                },
                methods: {
                    _handleLoad: function () {
                        this.imageFinishLoad = !1
                    }
                }
            }, Y = V, G = (n("e003"), v(Y, _, L, !1, null, null, null)), W = G.exports, q = n("2638"), z = n.n(q);
            n("8e6e");

            function X(t) {
                return X = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
                    return typeof t
                } : function (t) {
                    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t
                }, X(t)
            }

            function Z(t, e) {
                if ("object" != X(t) || !t) return t;
                var n = t[Symbol.toPrimitive];
                if (void 0 !== n) {
                    var i = n.call(t, e || "default");
                    if ("object" != X(i)) return i;
                    throw new TypeError("@@toPrimitive must return a primitive value.")
                }
                return ("string" === e ? String : Number)(t)
            }

            function J(t) {
                var e = Z(t, "string");
                return "symbol" == X(e) ? e : String(e)
            }

            function Q(t, e, n) {
                return e = J(e), e in t ? Object.defineProperty(t, e, {
                    value: n,
                    enumerable: !0,
                    configurable: !0,
                    writable: !0
                }) : t[e] = n, t
            }

            n("a481");

            function tt(t, e, n) {
                return t ? t(n) : e
            }

            function et(t) {
                return t < 10 ? "0".concat(t) : t
            }

            function nt(t) {
                var e, n = new Date(t), i = new Date, r = function (t) {
                    return t.getFullYear()
                }, o = function (t) {
                    return "".concat(t.getMonth() + 1, "-").concat(t.getDate())
                }, s = r(n), a = r(i);
                return e = s !== a ? "y年m月d日 h:i" : "".concat(s, "-").concat(o(n)) === "".concat(a, "-").concat(o(i)) ? "h:i" : "m月d日 h:i", it(t, e)
            }

            function it(t, e) {
                e || (e = "y-m-d h:i:s"), t = t ? new Date(t) : new Date;
                for (var n = [t.getFullYear().toString(), et((t.getMonth() + 1).toString()), et(t.getDate().toString()), et(t.getHours().toString()), et(t.getMinutes().toString()), et(t.getSeconds().toString())], i = "ymdhis", r = 0; r < n.length; r++) e = e.replace(i.charAt(r), n[r]);
                return e
            }

            function rt(t, e) {
                c(t) ? t((function () {
                    e()
                })) : e()
            }

            function ot(t) {
                return t.replace(/<.*?>/gi, "")
            }

            function st(t) {
                return t.replace(/<(?!img).*?>/gi, "")
            }

            function at(t) {
                if (null == t || "" == t) return "0 Bytes";
                var e = ["B", "K", "M", "G", "T", "P", "E", "Z", "Y"], n = 0, i = parseFloat(t);
                n = Math.floor(Math.log(i) / Math.log(1024));
                var r = i / Math.pow(1024, n);
                return r = parseFloat(r.toFixed(2)), r + e[n]
            }

            function ct() {
                var t = (new Date).getTime();
                window.performance && "function" === typeof window.performance.now && (t += performance.now());
                var e = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (function (e) {
                    var n = (t + 16 * Math.random()) % 16 | 0;
                    return t = Math.floor(t / 16), ("x" == e ? n : 3 & n | 8).toString(16)
                }));
                return e
            }

            var lt, ht, dt = {
                name: "LemonContact", components: {}, inject: {
                    IMUI: {
                        from: "IMUI", default: function () {
                            return this
                        }
                    }
                }, data: function () {
                    return {}
                }, props: {
                    contact: Object, simple: Boolean, timeFormat: {
                        type: Function, default: function (t) {
                            return it(t, s(t) ? "h:i" : "y/m/d")
                        }
                    }
                }, render: function () {
                    var t = this, e = arguments[0];
                    return e("div", {
                        class: ["lemon-contact", {"lemon-contact--name-center": this.simple}],
                        attrs: {title: this.contact.displayName},
                        on: {
                            click: function (e) {
                                return t._handleClick(e, t.contact)
                            }
                        }
                    }, [tt(this.$scopedSlots.default, this._renderInner(), this.contact)])
                }, created: function () {
                }, mounted: function () {
                }, computed: {}, watch: {}, methods: {
                    _renderInner: function () {
                        var t = this.$createElement, e = this.contact;
                        return [t("lemon-badge", {
                            attrs: {count: this.simple ? 0 : e.unread},
                            class: "lemon-contact__avatar"
                        }, [t("lemon-avatar", {
                            attrs: {
                                size: 40,
                                src: e.avatar
                            }
                        })]), t("div", {class: "lemon-contact__inner"}, [t("p", {class: "lemon-contact__label"}, [t("span", {class: "lemon-contact__name"}, [e.displayName]), !this.simple && t("span", {class: "lemon-contact__time"}, [this.timeFormat(e.lastSendTime)])]), !this.simple && t("p", {class: "lemon-contact__content"}, [o(e.lastContent) ? t("span", z()([{}, {domProps: {innerHTML: e.lastContent}}])) : e.lastContent])])]
                    }, _handleClick: function (t, e) {
                        this.$emit("click", e)
                    }
                }
            }, ut = dt, pt = (n("8fb6"), v(ut, lt, ht, !1, null, null, null)), ft = pt.exports;
            n("5df3"), n("1c4c"), n("9204");
            const mt = window.ChatArea;
            var gt = mt;
            n("6fb5");

            function vt(t, e) {
                var n = Object.keys(t);
                if (Object.getOwnPropertySymbols) {
                    var i = Object.getOwnPropertySymbols(t);
                    e && (i = i.filter((function (e) {
                        return Object.getOwnPropertyDescriptor(t, e).enumerable
                    }))), n.push.apply(n, i)
                }
                return n
            }

            function bt(t) {
                for (var e = 1; e < arguments.length; e++) {
                    var n = null != arguments[e] ? arguments[e] : {};
                    e % 2 ? vt(Object(n), !0).forEach((function (e) {
                        Q(t, e, n[e])
                    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : vt(Object(n)).forEach((function (e) {
                        Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(n, e))
                    }))
                }
                return t
            }

            var yt, xt, Et, Ct, wt, Tt, kt, Nt = function (t, e) {
                document.execCommand(t, !1, e)
            }, It = window.getSelection(), St = [], _t = {
                name: "LemonEditor", inject: {
                    IMUI: {
                        from: "IMUI", default: function () {
                            return this
                        }
                    }
                }, components: {}, props: {
                    tools: {
                        type: Array, default: function () {
                            return []
                        }
                    }, sendText: {type: String, default: "发 送"}, wrapKey: {
                        type: Function, default: function (t) {
                            return 13 == t.keyCode && !0 === t.ctrlKey
                        }
                    }, sendKey: {
                        type: Function, default: function (t) {
                            return 13 == t.keyCode && 0 == t.ctrlKey && 0 == t.shiftKey
                        }
                    }
                }, data: function () {
                    return this.clipboardBlob = null, {clipboardUrl: "", submitDisabled: !0, accept: "", chatArea: null}
                }, created: function () {
                    var t = this;
                    this.IMUI.$on("change-contact", (function () {
                        t.closeClipboardImage()
                    })), this.$nextTick((function () {
                        t.chatArea = new gt({
                            elm: t.$refs.textarea,
                            userList: [],
                            userProps: {id: "id", name: "displayName", avatar: "avatar", pinyin: "name_py"},
                            placeholder: "",
                            needCallSpace: !0,
                            wrapKeyFun: function (e) {
                                return t.wrapKey(e)
                            },
                            sendKeyFun: function (e) {
                                return t.sendKey(e)
                            }
                        }), t.chatArea.revisePCCheckDialogLabel({
                            title: "选择要@的人",
                            searchPlaceholder: "搜素人员名称",
                            searchEmptyLabel: "没有匹配到任何结果",
                            userTagTitle: "成员列表",
                            checkAllLabel: "全选",
                            confirmLabel: "确定",
                            cancelLabel: "取消"
                        }), t.chatArea.richText.addEventListener("blur", (function (e) {
                            setTimeout(t.chatArea.winClick, 200)
                        })), t.chatArea.richText.addEventListener("drop", (function (e) {
                            var n = e.dataTransfer, i = n.files;
                            Array.from(i).forEach((function (e) {
                                t.$emit("upload", e)
                            }))
                        })), t.chatArea.enterSend = function () {
                            0 == t.submitDisabled && t._handleSend()
                        }
                    }))
                }, render: function () {
                    var t = this, e = arguments[0], n = [], i = [];
                    return this.proxyTools.forEach((function (r) {
                        var o = r.name, s = r.title, a = r.render, c = r.click, l = r.isRight;
                        c = c || new Function;
                        var h, d = ["lemon-editor__tool-item", {"lemon-editor__tool-item--right": l}];
                        h = "emoji" == o ? 0 == St.length ? "" : e("lemon-popover", {class: "lemon-editor__emoji"}, [e("template", {slot: "content"}, [t._renderEmojiTabs()]), e("div", {
                            class: d,
                            attrs: {title: s}
                        }, [a()])]) : e("div", {
                            class: d,
                            on: {click: c},
                            attrs: {title: s}
                        }, [a()]), l ? i.push(h) : n.push(h)
                    })), e("div", {class: "lemon-editor"}, [this.clipboardUrl && this.sendClipboardImage(), e("input", {
                        style: "display:none",
                        attrs: {type: "file", multiple: "multiple", accept: this.accept},
                        ref: "fileInput",
                        on: {change: this._handleChangeFile}
                    }), e("div", {class: "lemon-editor__tool"}, [e("div", {class: "lemon-editor__tool-left"}, [n]), e("div", {class: "lemon-editor__tool-right"}, [i])]), e("div", {class: "lemon-editor__inner"}, [e("div", {
                        class: "lemon-editor__input",
                        ref: "textarea",
                        on: {
                            keyup: this._handleKeyup,
                            keydown: this._handleKeydown,
                            paste: this._handlePaste,
                            click: this._handleClick,
                            input: this._handleInput
                        },
                        attrs: {spellcheck: "false"}
                    })]), e("div", {class: "lemon-editor__footer"}, [e("div", {class: "lemon-editor__tip"}, [tt(this.IMUI.$scopedSlots["editor-footer"], "使用 ctrl + enter 换行")]), e("div", {class: "lemon-editor__submit"}, [e("lemon-button", {
                        attrs: {disabled: this.submitDisabled},
                        on: {click: this._handleSend}
                    }, [this.sendText])])])])
                }, computed: {
                    proxyTools: function () {
                        var t = this, e = this.$createElement;
                        if (!this.tools) return [];
                        var n = [{
                            name: "emoji", title: "表情", click: null, render: function (t) {
                                return e("i", {class: "lemon-icon-emoji"})
                            }
                        }, {
                            name: "uploadFile", title: "文件上传", click: function () {
                                return t.selectFile("*")
                            }, render: function (t) {
                                return e("i", {class: "lemon-icon-folder"})
                            }
                        }, {
                            name: "uploadImage", title: "图片上传", click: function () {
                                return t.selectFile("image/*")
                            }, render: function (t) {
                                return e("i", {class: "lemon-icon-image"})
                            }
                        }], i = [];
                        if (Array.isArray(this.tools)) {
                            var r = {emoji: 0, uploadFile: 1, uploadImage: 2}, o = Object.keys(r);
                            i = this.tools.map((function (t) {
                                return o.includes(t.name) ? bt(bt({}, n[r[t.name]]), t) : t
                            }))
                        } else i = n;
                        return i
                    }
                }, methods: {
                    closeClipboardImage: function () {
                        this.clipboardUrl = "", this.clipboardBlob = null
                    }, sendClipboardImage: function () {
                        this.clipboardBlob && (this.$emit("upload", this.clipboardBlob), this.closeClipboardImage())
                    }, saveRangeToLast: function () {
                        yt || (yt = document.createRange()), yt.selectNodeContents(textarea.value), yt.collapse(!1), It.removeAllRanges(), It.addRange(yt)
                    }, inertContent: function (t) {
                        var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
                        e && saveRangeToLast(), this.focusRange(), Nt("insertHTML", t), this.saveRange()
                    }, saveRange: function () {
                        yt = It.getRangeAt(0)
                    }, focusRange: function () {
                        this.$refs.textarea.focus(), yt && (It.removeAllRanges(), It.addRange(yt))
                    }, _handleClick: function () {
                        this.saveRange()
                    }, _handleInput: function () {
                        this._checkSubmitDisabled()
                    }, _renderEmojiTabs: function () {
                        var t = this, e = this.$createElement, n = function (n) {
                            return n.map((function (n) {
                                return e("img", {
                                    attrs: {src: n.src, title: n.title},
                                    class: "lemon-editor__emoji-item",
                                    on: {
                                        click: function () {
                                            return t._handleSelectEmoji(n)
                                        }
                                    }
                                })
                            }))
                        };
                        if (St[0].label) {
                            var i = St.map((function (t, i) {
                                return e("div", {slot: "tab-pane", attrs: {index: i, tab: t.label}}, [n(t.children)])
                            }));
                            return e("lemon-tabs", {style: "width: 412px"}, [i])
                        }
                        return e("div", {class: "lemon-tabs-content", style: "width:406px"}, [n(St)])
                    }, _handleSelectEmoji: function (t) {
                        this.chatArea.insertHtml('<img emoji-name="'.concat(t.name, '" src="').concat(t.src, '"></img>')), this._checkSubmitDisabled()
                    }, selectFile: function () {
                        var t = h(regeneratorRuntime.mark((function t(e) {
                            return regeneratorRuntime.wrap((function (t) {
                                while (1) switch (t.prev = t.next) {
                                    case 0:
                                        return this.accept = e, t.next = 3, this.$nextTick();
                                    case 3:
                                        this.$refs.fileInput.click();
                                    case 4:
                                    case"end":
                                        return t.stop()
                                }
                            }), t, this)
                        })));

                        function e(e) {
                            return t.apply(this, arguments)
                        }

                        return e
                    }(), _handlePaste: function (t) {
                        t.preventDefault();
                        var e = t.clipboardData || window.clipboardData, n = e.getData("Text");
                        if (n) this.submitDisabled = !1; else {
                            var i = this._getClipboardBlob(e), r = i.blob, o = i.blobUrl;
                            this.clipboardBlob = r, this.clipboardUrl = o
                        }
                    }, _getClipboardBlob: function (t) {
                        for (var e, n, i = 0; i < t.items.length; ++i) "file" == t.items[i].kind && -1 !== t.items[i].type.indexOf("image/") && (e = t.items[i].getAsFile(), n = (window.URL || window.webkitURL).createObjectURL(e));
                        return {blob: e, blobUrl: n}
                    }, _handleKeyup: function (t) {
                        this.saveRange(), this._checkSubmitDisabled()
                    }, _handleKeydown: function (t) {
                        (13 == t.keyCode || 13 == t.keyCode && t.shiftKey) && t.preventDefault(), this.wrapKey(t) && (t.preventDefault(), Nt("insertLineBreak"))
                    }, getFormatValue: function () {
                        return this.IMUI.emojiImageToName(this.chatArea.getHtml({needUserId: !0}))
                    }, _checkSubmitDisabled: function () {
                        this.submitDisabled = !st(this.chatArea.getHtml().trim())
                    }, _handleSend: function (t) {
                        var e = this.getFormatValue();
                        this.$emit("send", e), this.clear(), this._checkSubmitDisabled()
                    }, _handleChangeFile: function (t) {
                        var e = this, n = this.$refs.fileInput;
                        Array.from(n.files).forEach((function (t) {
                            e.$emit("upload", t)
                        })), n.value = ""
                    }, clear: function () {
                        this.chatArea.clear()
                    }, initEmoji: function (t) {
                        St = t, this.$forceUpdate()
                    }, setValue: function (t) {
                        "" == t ? this.clear() : this.chatArea.reverseAnalysis(this.IMUI.emojiNameToImage(t)), this._checkSubmitDisabled()
                    }
                }
            }, Lt = _t, At = (n("631a"), v(Lt, xt, Et, !1, null, null, null)), Ot = At.exports, Mt = {
                name: "LemonMessages",
                components: {},
                props: {
                    hideName: Boolean,
                    hideTime: Boolean,
                    reverseUserId: [String, Number],
                    timeRange: {type: Number, default: 1},
                    timeFormat: {
                        type: Function, default: function (t) {
                            return nt(t)
                        }
                    },
                    loadingText: {type: [String, Function]},
                    loadendText: {type: [String, Function], default: "暂无更多消息"},
                    messages: {
                        type: Array, default: function () {
                            return []
                        }
                    }
                },
                data: function () {
                    return this._lockScroll = !1, {_loading: !1, _loadend: !1, isBottom: !0}
                },
                render: function () {
                    var t = this, e = arguments[0];
                    return e("div", {
                        class: "lemon-messages",
                        ref: "wrap",
                        on: {scroll: this._handleScroll}
                    }, [e("div", {class: ["lemon-messages__load", "lemon-messages__load--".concat(this._loadend ? "end" : "ing")]}, [e("span", {class: "lemon-messages__loadend"}, [o(this.loadendText) ? this.loadendText : this.loadendText()]), e("span", {class: "lemon-messages__loading"}, [this.loadingText ? o(this.loadingText) ? this.loadingText : this.loadingText() : e("i", {class: "lemon-icon-loading lemonani-spin"})])]), this.messages.map((function (n, i) {
                        var r = [], o = "lemon-message-".concat(n.type), s = t.toCamelCase(o);
                        t.$options.components[s] || (n.type = "text", n.content = "[暂不支持的消息类型]", o = "lemon-message-text");
                        var a, c = t.messages[i - 1];
                        return c && t.msecRange && n.sendTime - c.sendTime > t.msecRange && r.push(e("lemon-message-event", z()([{}, {
                            attrs: {
                                message: {
                                    id: "__time__",
                                    type: "event",
                                    content: nt(n.sendTime)
                                }
                            }
                        }]))), a = "event" == n.type ? {message: n} : {
                            timeFormat: t.timeFormat,
                            message: n,
                            reverse: t.reverseUserId == n.fromUser.id,
                            hideTime: t.hideTime,
                            hideName: t.hideName
                        }, r.push(e(o, z()([{ref: "message", refInFor: !0}, {attrs: a}]))), r
                    }))])
                },
                computed: {
                    msecRange: function () {
                        return 1e3 * this.timeRange * 60
                    }
                },
                watch: {},
                methods: {
                    loaded: function () {
                        this._loadend = !0, this.$forceUpdate()
                    }, toCamelCase: function (t) {
                        return t.replace(/-([a-z])/g, (function (t, e) {
                            return e.toUpperCase()
                        }))
                    }, resetLoadState: function () {
                        var t = this;
                        this._lockScroll = !0, this._loading = !1, this._loadend = !1, setTimeout((function () {
                            t._lockScroll = !1
                        }), 200)
                    }, _handleScroll: function () {
                        var t = h(regeneratorRuntime.mark((function t(e) {
                            var n, i, r, o, s, a = this;
                            return regeneratorRuntime.wrap((function (t) {
                                while (1) switch (t.prev = t.next) {
                                    case 0:
                                        if (!this._lockScroll) {
                                            t.next = 2;
                                            break
                                        }
                                        return t.abrupt("return");
                                    case 2:
                                        if (n = e.target, A.hide(), 0 != n.scrollTop || 0 != this._loading || 0 != this._loadend) {
                                            t.next = 10;
                                            break
                                        }
                                        return this._loading = !0, t.next = 8, this.$nextTick();
                                    case 8:
                                        i = n.scrollHeight, this.$emit("reach-top", function () {
                                            var t = h(regeneratorRuntime.mark((function t(e) {
                                                return regeneratorRuntime.wrap((function (t) {
                                                    while (1) switch (t.prev = t.next) {
                                                        case 0:
                                                            return t.next = 2, a.$nextTick();
                                                        case 2:
                                                            n.scrollTop = n.scrollHeight - i, a._loading = !1, a._loadend = !!e;
                                                        case 5:
                                                        case"end":
                                                            return t.stop()
                                                    }
                                                }), t)
                                            })));
                                            return function (e) {
                                                return t.apply(this, arguments)
                                            }
                                        }());
                                    case 10:
                                        r = n.scrollTop, o = n.scrollHeight, s = this.$refs.wrap, this.isBottom = r + s.offsetHeight >= o, this.$emit("is-bottom", this.isBottom);
                                    case 15:
                                    case"end":
                                        return t.stop()
                                }
                            }), t, this)
                        })));

                        function e(e) {
                            return t.apply(this, arguments)
                        }

                        return e
                    }(), scrollToBottom: function () {
                        var t = h(regeneratorRuntime.mark((function t() {
                            var e;
                            return regeneratorRuntime.wrap((function (t) {
                                while (1) switch (t.prev = t.next) {
                                    case 0:
                                        return t.next = 2, this.$nextTick();
                                    case 2:
                                        e = this.$refs.wrap, e && (e.scrollTop = e.scrollHeight);
                                    case 4:
                                    case"end":
                                        return t.stop()
                                }
                            }), t, this)
                        })));

                        function e() {
                            return t.apply(this, arguments)
                        }

                        return e
                    }()
                },
                created: function () {
                },
                mounted: function () {
                }
            }, Dt = Mt, Pt = (n("bd0d"), v(Dt, Ct, wt, !1, null, null, null)), jt = Pt.exports, Ht = {
                name: "lemonMessageBasic", inject: {
                    IMUI: {
                        from: "IMUI", default: function () {
                            return this
                        }
                    }
                }, props: {
                    contextmenu: Array, message: {
                        type: Object, default: function () {
                            return {}
                        }
                    }, timeFormat: {
                        type: Function, default: function () {
                            return ""
                        }
                    }, reverse: Boolean, hideName: Boolean, hideTime: Boolean
                }, data: function () {
                    return {}
                }, render: function () {
                    var t = this, e = arguments[0], n = this.message, i = n.fromUser, r = n.status, o = n.sendTime,
                        s = 1 == this.hideName && 1 == this.hideTime;
                    return e("div", {
                        class: ["lemon-message", "lemon-message--status-".concat(r), {
                            "lemon-message--reverse": this.reverse,
                            "lemon-message--hide-title": s
                        }]
                    }, [e("div", {class: "lemon-message__avatar"}, [e("lemon-avatar", {
                        attrs: {
                            size: 36,
                            shape: "square",
                            src: i.avatar
                        }, on: {
                            click: function (e) {
                                t._emitClick(e, "avatar")
                            }
                        }
                    })]), e("div", {class: "lemon-message__inner"}, [e("div", {class: "lemon-message__title"}, [0 == this.hideName && e("span", {
                        on: {
                            click: function (e) {
                                t._emitClick(e, "displayName")
                            }
                        }
                    }, [i.displayName]), 0 == this.hideTime && e("span", {
                        class: "lemon-message__time",
                        on: {
                            click: function (e) {
                                t._emitClick(e, "sendTime")
                            }
                        }
                    }, [this.timeFormat(o)])]), e("div", {class: "lemon-message__content-flex"}, [e("div", {
                        directives: [{
                            name: "lemon-contextmenu",
                            value: this.IMUI.contextmenu,
                            modifiers: {message: !0}
                        }], class: "lemon-message__content", on: {
                            click: function (e) {
                                t._emitClick(e, "content")
                            }
                        }
                    }, [tt(this.$scopedSlots["content"], null, this.message)]), e("div", {class: "lemon-message__content-after"}, [tt(this.IMUI.$scopedSlots["message-after"], null, this.message)]), e("div", {
                        class: "lemon-message__status",
                        on: {
                            click: function (e) {
                                t._emitClick(e, "status")
                            }
                        }
                    }, [e("i", {class: "lemon-icon-loading lemonani-spin"}), e("i", {
                        class: "lemon-icon-prompt",
                        attrs: {title: "重发消息"},
                        style: {color: "#ff2525", cursor: "pointer"}
                    })])])])])
                }, created: function () {
                }, mounted: function () {
                }, computed: {}, watch: {}, methods: {
                    _emitClick: function (t, e) {
                        this.IMUI.$emit("message-click", t, e, this.message, this.IMUI)
                    }
                }
            }, Rt = Ht, $t = (n("4c77"), v(Rt, Tt, kt, !1, null, null, null)), Ft = $t.exports;

            function Ut(t, e) {
                var n = Object.keys(t);
                if (Object.getOwnPropertySymbols) {
                    var i = Object.getOwnPropertySymbols(t);
                    e && (i = i.filter((function (e) {
                        return Object.getOwnPropertyDescriptor(t, e).enumerable
                    }))), n.push.apply(n, i)
                }
                return n
            }

            function Kt(t) {
                for (var e = 1; e < arguments.length; e++) {
                    var n = null != arguments[e] ? arguments[e] : {};
                    e % 2 ? Ut(Object(n), !0).forEach((function (e) {
                        Q(t, e, n[e])
                    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : Ut(Object(n)).forEach((function (e) {
                        Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(n, e))
                    }))
                }
                return t
            }

            var Bt, Vt, Yt = {
                name: "lemonMessageText", inheritAttrs: !1, inject: ["IMUI"], render: function () {
                    var t = this, e = arguments[0];
                    return e("lemon-message-basic", z()([{class: "lemon-message-text"}, {props: Kt({}, this.$attrs)}, {
                        scopedSlots: {
                            content: function (n) {
                                var i = t.IMUI.emojiNameToImage(n.content);
                                return e("span", z()([{}, {domProps: {innerHTML: i}}]))
                            }
                        }
                    }]))
                }
            }, Gt = Yt, Wt = (n("5778"), v(Gt, Bt, Vt, !1, null, null, null)), qt = Wt.exports;

            function zt(t, e) {
                var n = Object.keys(t);
                if (Object.getOwnPropertySymbols) {
                    var i = Object.getOwnPropertySymbols(t);
                    e && (i = i.filter((function (e) {
                        return Object.getOwnPropertyDescriptor(t, e).enumerable
                    }))), n.push.apply(n, i)
                }
                return n
            }

            function Xt(t) {
                for (var e = 1; e < arguments.length; e++) {
                    var n = null != arguments[e] ? arguments[e] : {};
                    e % 2 ? zt(Object(n), !0).forEach((function (e) {
                        Q(t, e, n[e])
                    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : zt(Object(n)).forEach((function (e) {
                        Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(n, e))
                    }))
                }
                return t
            }

            var Zt, Jt, Qt = {
                name: "lemonMessageImage", inheritAttrs: !1, render: function () {
                    var t = arguments[0];
                    return t("lemon-message-basic", z()([{class: "lemon-message-image"}, {props: Xt({}, this.$attrs)}, {
                        scopedSlots: {
                            content: function (e) {
                                return t("img", {attrs: {src: e.content}})
                            }
                        }
                    }]))
                }
            }, te = Qt, ee = (n("e61c"), v(te, Zt, Jt, !1, null, null, null)), ne = ee.exports;

            function ie(t, e) {
                var n = Object.keys(t);
                if (Object.getOwnPropertySymbols) {
                    var i = Object.getOwnPropertySymbols(t);
                    e && (i = i.filter((function (e) {
                        return Object.getOwnPropertyDescriptor(t, e).enumerable
                    }))), n.push.apply(n, i)
                }
                return n
            }

            function re(t) {
                for (var e = 1; e < arguments.length; e++) {
                    var n = null != arguments[e] ? arguments[e] : {};
                    e % 2 ? ie(Object(n), !0).forEach((function (e) {
                        Q(t, e, n[e])
                    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : ie(Object(n)).forEach((function (e) {
                        Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(n, e))
                    }))
                }
                return t
            }

            var oe, se, ae, ce, le = {
                name: "lemonMessageFile", inheritAttrs: !1, render: function () {
                    var t = arguments[0];
                    return t("lemon-message-basic", z()([{class: "lemon-message-file"}, {props: re({}, this.$attrs)}, {
                        scopedSlots: {
                            content: function (e) {
                                return [t("div", {class: "lemon-message-file__inner"}, [t("p", {class: "lemon-message-file__name"}, [e.fileName]), t("p", {class: "lemon-message-file__byte"}, [at(e.fileSize)])]), t("div", {class: "lemon-message-file__sfx"}, [t("i", {class: "lemon-icon-attah"})])]
                            }
                        }
                    }]))
                }
            }, he = le, de = (n("85ff"), v(he, oe, se, !1, null, null, null)), ue = de.exports, pe = {
                name: "lemonMessageEvent", inheritAttrs: !1, inject: ["IMUI"], render: function () {
                    var t = this, e = arguments[0], n = this.$attrs.message.content;
                    return e("div", {class: "lemon-message lemon-message-event"}, [e("span", {
                        class: "lemon-message-event__content",
                        on: {
                            click: function (e) {
                                return t._emitClick(e, "content")
                            }
                        }
                    }, [n])])
                }, methods: {
                    _emitClick: function (t, e) {
                        this.IMUI.$emit("message-click", t, e, this.$attrs.message, this.IMUI)
                    }
                }
            }, fe = pe, me = (n("dcc3"), v(fe, ae, ce, !1, null, null, null)), ge = me.exports;
            n("20d6");

            function ve(t, e) {
                (null == e || e > t.length) && (e = t.length);
                for (var n = 0, i = new Array(e); n < e; n++) i[n] = t[n];
                return i
            }

            function be(t) {
                if (Array.isArray(t)) return ve(t)
            }

            function ye(t) {
                if ("undefined" !== typeof Symbol && null != t[Symbol.iterator] || null != t["@@iterator"]) return Array.from(t)
            }

            function xe(t, e) {
                if (t) {
                    if ("string" === typeof t) return ve(t, e);
                    var n = Object.prototype.toString.call(t).slice(8, -1);
                    return "Object" === n && t.constructor && (n = t.constructor.name), "Map" === n || "Set" === n ? Array.from(t) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? ve(t, e) : void 0
                }
            }

            function Ee() {
                throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.")
            }

            function Ce(t) {
                return be(t) || ye(t) || xe(t) || Ee()
            }

            n("f559"), n("f751"), n("7514"), n("55dd");
            var we = "messages", Te = "contacts", ke = [we, Te], Ne = {
                file: function (t) {
                    return "[文件]"
                }, image: function (t) {
                    return "[图片]"
                }, text: function (t) {
                    return this.emojiNameToImage(ot(t.content))
                }, event: function (t) {
                    return "[通知]"
                }
            };

            function Ie(t, e) {
                if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function")
            }

            function Se(t, e) {
                for (var n = 0; n < e.length; n++) {
                    var i = e[n];
                    i.enumerable = i.enumerable || !1, i.configurable = !0, "value" in i && (i.writable = !0), Object.defineProperty(t, J(i.key), i)
                }
            }

            function _e(t, e, n) {
                return e && Se(t.prototype, e), n && Se(t, n), Object.defineProperty(t, "prototype", {writable: !1}), t
            }

            var Le = function () {
                function t() {
                    Ie(this, t), this.table = {}
                }

                return _e(t, [{
                    key: "get", value: function (t) {
                        return t ? this.table[t] : this.table
                    }
                }, {
                    key: "set", value: function (t, e) {
                        this.table[t] = e
                    }
                }, {
                    key: "remove", value: function (t) {
                        t ? delete this.table[t] : this.table = {}
                    }
                }, {
                    key: "has", value: function (t) {
                        return !!this.table[t]
                    }
                }]), t
            }();

            function Ae(t, e) {
                var n = Object.keys(t);
                if (Object.getOwnPropertySymbols) {
                    var i = Object.getOwnPropertySymbols(t);
                    e && (i = i.filter((function (e) {
                        return Object.getOwnPropertyDescriptor(t, e).enumerable
                    }))), n.push.apply(n, i)
                }
                return n
            }

            function Oe(t) {
                for (var e = 1; e < arguments.length; e++) {
                    var n = null != arguments[e] ? arguments[e] : {};
                    e % 2 ? Ae(Object(n), !0).forEach((function (e) {
                        Q(t, e, n[e])
                    })) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : Ae(Object(n)).forEach((function (e) {
                        Object.defineProperty(t, e, Object.getOwnPropertyDescriptor(n, e))
                    }))
                }
                return t
            }

            var Me, De, Pe = {}, je = {}, He = function (t) {
                    return o(t) ? t : "".concat(t, "px")
                }, Re = function (t) {
                    return t.replace("%", "") / 100
                }, $e = function () {
                }, Fe = {
                    name: "LemonImui",
                    provide: function () {
                        return {IMUI: this}
                    },
                    props: {
                        width: {type: [String, Number], default: 850},
                        height: {type: [String, Number], default: 580},
                        theme: {type: String, default: "default"},
                        simple: {type: Boolean, default: !1},
                        loadingText: [String, Function],
                        loadendText: [String, Function],
                        messageTimeFormat: Function,
                        contactTimeFormat: Function,
                        hideDrawer: {type: Boolean, default: !0},
                        hideMenuAvatar: Boolean,
                        hideMenu: Boolean,
                        hideMessageName: Boolean,
                        hideMessageTime: Boolean,
                        sendKey: Function,
                        wrapKey: Function,
                        sendText: String,
                        contextmenu: Array,
                        contactContextmenu: Array,
                        avatarCricle: Boolean,
                        user: {
                            type: Object, default: function () {
                                return {}
                            }
                        },
                        latelyContacts: {
                            type: Function, default: function (t) {
                                var e = t.filter((function (t) {
                                    return !a(t.lastContent)
                                }));
                                return e.sort((function (t, e) {
                                    return e.lastSendTime - t.lastSendTime
                                })), e
                            }
                        }
                    },
                    data: function () {
                        return this.CacheContactContainer = new Le, this.CacheMenuContainer = new Le, this.CacheMessageLoaded = new Le, this.CacheDraft = new Le, {
                            drawerVisible: !this.hideDrawer,
                            currentContactId: null,
                            currentContactIdSidebarContact: null,
                            currentMessages: [],
                            activeSidebar: we,
                            contacts: [],
                            menus: [],
                            editorTools: [{name: "emoji"}, {name: "uploadFile"}, {name: "uploadImage"}]
                        }
                    },
                    render: function () {
                        return this._renderWrapper([this._renderMenu(), this._renderSidebarMessage(), this._renderSidebarContact(), this._renderContainer(), this._renderDrawer()])
                    },
                    created: function () {
                        this.initMenus()
                    },
                    mounted: function () {
                        var t = h(regeneratorRuntime.mark((function t() {
                            return regeneratorRuntime.wrap((function (t) {
                                while (1) switch (t.prev = t.next) {
                                    case 0:
                                        return t.next = 2, this.$nextTick();
                                    case 2:
                                    case"end":
                                        return t.stop()
                                }
                            }), t, this)
                        })));

                        function e() {
                            return t.apply(this, arguments)
                        }

                        return e
                    }(),
                    computed: {
                        currentContact: function () {
                            var t = this;
                            return this.contacts.find((function (e) {
                                return e.id == t.currentContactId
                            })) || {}
                        }, currentContactSidebarContact: function () {
                            var t = this;
                            return this.contacts.find((function (e) {
                                return e.id == t.currentContactIdSidebarContact
                            })) || {}
                        }, currentMenu: function () {
                            var t = this;
                            return this.menus.find((function (e) {
                                return e.name == t.activeSidebar
                            })) || {}
                        }, currentIsDefSidebar: function () {
                            return ke.includes(this.activeSidebar)
                        }, lastMessages: function () {
                            return this.latelyContacts(this.contacts)
                        }
                    },
                    watch: {
                        activeSidebar: function () {
                        }
                    },
                    methods: {
                        _menuIsContacts: function () {
                            return this.activeSidebar == Te
                        }, _menuIsMessages: function () {
                            return this.activeSidebar == we
                        }, _createMessage: function (t) {
                            return Oe(Oe({}, {
                                id: ct(),
                                type: "text",
                                status: "going",
                                sendTime: (new Date).getTime(),
                                toContactId: this.currentContactId,
                                fromUser: Oe({}, this.user)
                            }), t)
                        }, appendMessage: function (t) {
                            var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1], n = "+1",
                                i = Pe[t.toContactId];
                            if ("event" != t.type && this.user.id != t.fromUser.id || (n = "+0"), void 0 === i) this.updateContact({
                                id: t.toContactId,
                                unread: n,
                                lastSendTime: t.sendTime,
                                lastContent: this.lastContentRender(t)
                            }); else {
                                var r = i.some((function (e) {
                                    var n = e.id;
                                    return n == t.id
                                }));
                                if (r) return;
                                this._addMessage(t, t.toContactId, 1);
                                var o = {
                                    id: t.toContactId,
                                    lastContent: this.lastContentRender(t),
                                    lastSendTime: t.sendTime
                                };
                                t.toContactId == this.currentContactId ? (1 == e && this.messageViewToBottom(), this.CacheDraft.remove(t.toContactId)) : o.unread = n, this.updateContact(o)
                            }
                        }, _emitSend: function (t, e, n) {
                            var i = this;
                            this.$emit("send", t, (function () {
                                var n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {status: "succeed"};
                                e(), i.updateMessage(Object.assign(t, n))
                            }), n)
                        }, _handleSend: function (t) {
                            var e = this, n = this.$refs.editor.chatArea.getCallUserList(), i = n.map((function (t) {
                                return t.id
                            })), r = this._createMessage({content: t, at: i});
                            this.appendMessage(r, !0), this._emitSend(r, (function () {
                                e.updateContact({
                                    id: r.toContactId,
                                    lastContent: e.lastContentRender(r),
                                    lastSendTime: r.sendTime
                                }), e.CacheDraft.remove(r.toContactId)
                            }))
                        }, _handleUpload: function (t) {
                            var e, n = this, i = ["image/gif", "image/jpeg", "image/png"];
                            e = i.includes(t.type) ? {type: "image", content: URL.createObjectURL(t)} : {
                                type: "file",
                                fileSize: t.size,
                                fileName: t.name,
                                content: ""
                            };
                            var r = this._createMessage(e);
                            this.appendMessage(r, !0), this._emitSend(r, (function () {
                                n.updateContact({
                                    id: r.toContactId,
                                    lastContent: n.lastContentRender(r),
                                    lastSendTime: r.sendTime
                                })
                            }), t)
                        }, _emitPullMessages: function (t) {
                            var e = this;
                            this._changeContactLock = !0, this.$emit("pull-messages", this.currentContact, (function () {
                                var n = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
                                    i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
                                e._addMessage(n, e.currentContactId, 0), e.CacheMessageLoaded.set(e.currentContactId, i), 1 == i && e.$refs.messages.loaded(), e.updateCurrentMessages(), e._changeContactLock = !1, t(i)
                            }), this)
                        }, callIsBottom: function (t) {
                            this.$emit("is-bottom", t)
                        }, clearCacheContainer: function (t) {
                            this.CacheContactContainer.remove(t), this.CacheMenuContainer.remove(t)
                        }, _renderWrapper: function (t) {
                            var e = this.$createElement;
                            return e("div", {
                                style: {width: He(this.width), height: He(this.height)},
                                ref: "wrapper",
                                class: ["lemon-wrapper", "lemon-wrapper--theme-".concat(this.theme), {"lemon-wrapper--simple": this.simple}, this.drawerVisible && "lemon-wrapper--drawer-show"]
                            }, [t])
                        }, _renderMenu: function () {
                            var t = this, e = this.$createElement, n = this._renderMenuItem();
                            return e("div", {
                                class: "lemon-menu",
                                directives: [{name: "show", value: !this.hideMenu}]
                            }, [e("lemon-avatar", {
                                directives: [{name: "show", value: !this.hideMenuAvatar}],
                                on: {
                                    click: function (e) {
                                        t.$emit("menu-avatar-click", e)
                                    }
                                },
                                class: "lemon-menu__avatar",
                                attrs: {src: this.user.avatar}
                            }), n.top, this.$slots.menu, e("div", {class: "lemon-menu__bottom"}, [this.$slots["menu-bottom"], n.bottom])])
                        }, _renderMenuAvatar: function () {
                        }, _renderMenuItem: function () {
                            var t = this, e = this.$createElement, n = [], i = [];
                            return this.menus.forEach((function (r) {
                                var o = r.name, s = r.title, a = r.unread, c = r.render, l = r.click, h = e("div", {
                                    class: ["lemon-menu__item", {"lemon-menu__item--active": t.activeSidebar == o}],
                                    on: {
                                        click: function () {
                                            rt(l, (function () {
                                                o && t.changeMenu(o)
                                            }))
                                        }
                                    },
                                    attrs: {title: s}
                                }, [e("lemon-badge", {attrs: {count: a}}, [c(r)])]);
                                !0 === r.isBottom ? i.push(h) : n.push(h)
                            })), {top: n, bottom: i}
                        }, _renderSidebarMessage: function () {
                            var t = this;
                            return this._renderSidebar([tt(this.$scopedSlots["sidebar-message-top"], null, this), this.lastMessages.map((function (e) {
                                return t._renderContact({contact: e, timeFormat: t.contactTimeFormat}, (function () {
                                    return t.changeContact(e.id)
                                }), t.$scopedSlots["sidebar-message"])
                            }))], we, tt(this.$scopedSlots["sidebar-message-fixedtop"], null, this))
                        }, _renderContact: function (t, e, n) {
                            var i = this, r = this.$createElement, o = t.contact, s = o.click, a = o.renderContainer,
                                c = o.id, l = function () {
                                    rt(s, (function () {
                                        e(), i._customContainerReady(a, i.CacheContactContainer, c)
                                    }))
                                };
                            return r("lemon-contact", z()([{
                                class: {"lemon-contact--active": this.activeSidebar == Te ? this.currentContactIdSidebarContact == t.contact.id : this.currentContactId == t.contact.id},
                                directives: [{
                                    name: "lemon-contextmenu",
                                    value: this.contactContextmenu,
                                    modifiers: {contact: !0}
                                }]
                            }, {props: t}, {on: {click: l}, scopedSlots: {default: n}}]))
                        }, _renderSidebarContact: function () {
                            var t, e = this, n = this.$createElement;
                            return this._renderSidebar([tt(this.$scopedSlots["sidebar-contact-top"], null, this), this.contacts.map((function (i) {
                                if (i.index) {
                                    i.index = i.index.replace(/\[[0-9]*\]/, "");
                                    var r = [i.index !== t && n("p", {class: "lemon-sidebar__label"}, [i.index]), e._renderContact({
                                        contact: i,
                                        simple: !0
                                    }, (function () {
                                        e.changeContact(i.id)
                                    }), e.$scopedSlots["sidebar-contact"])];
                                    return t = i.index, r
                                }
                            }))], Te, tt(this.$scopedSlots["sidebar-contact-fixedtop"], null, this))
                        }, _renderSidebar: function (t, e, n) {
                            var i = this.$createElement;
                            return i("div", {
                                class: "lemon-sidebar",
                                directives: [{name: "show", value: this.activeSidebar == e}],
                                on: {scroll: this._handleSidebarScroll}
                            }, [i("div", {class: "lemon-sidebar__fixed-top"}, [n]), i("div", {class: "lemon-sidebar__scroll"}, [t])])
                        }, _renderDrawer: function () {
                            var t = this.$createElement;
                            return this._menuIsMessages() && this.currentContactId ? t("div", {
                                class: "lemon-drawer",
                                ref: "drawer"
                            }, [$e(this.currentContact), tt(this.$scopedSlots.drawer, "", this.currentContact)]) : ""
                        }, _isContactContainerCache: function (t) {
                            return t.startsWith("contact#")
                        }, _renderContainer: function () {
                            var t = this, e = this.$createElement, n = [], i = "lemon-container",
                                r = this.activeSidebar == Te ? this.currentContactSidebarContact : this.currentContact,
                                o = !0;
                            for (var s in this.CacheContactContainer.get()) {
                                var c = r.id == s && this.currentIsDefSidebar;
                                c && (o = !c), n.push(e("div", {
                                    class: i,
                                    directives: [{name: "show", value: c}]
                                }, [this.CacheContactContainer.get(s)]))
                            }
                            for (var l in this.CacheMenuContainer.get()) n.push(e("div", {
                                class: i,
                                directives: [{name: "show", value: this.activeSidebar == l && !this.currentIsDefSidebar}]
                            }, [this.CacheMenuContainer.get(l)]));
                            return n.push(e("div", {
                                class: i,
                                directives: [{name: "show", value: this._menuIsMessages() && o && r.id}]
                            }, [e("div", {class: "lemon-container__title"}, [tt(this.$scopedSlots["message-title"], e("div", {class: "lemon-container__displayname"}, [r.displayName]), r)]), e("div", {class: "lemon-vessel"}, [e("div", {class: "lemon-vessel__left"}, [e("lemon-messages", {
                                ref: "messages",
                                attrs: {
                                    "loading-text": this.loadingText,
                                    "loadend-text": this.loadendText,
                                    "hide-time": this.hideMessageTime,
                                    "hide-name": this.hideMessageName,
                                    "time-format": this.messageTimeFormat,
                                    "reverse-user-id": this.user.id,
                                    messages: this.currentMessages
                                },
                                on: {"reach-top": this._emitPullMessages, "is-bottom": this.callIsBottom}
                            }), e("lemon-editor", {
                                ref: "editor",
                                attrs: {
                                    tools: this.editorTools,
                                    sendText: this.sendText,
                                    sendKey: this.sendKey,
                                    wrapKey: this.wrapKey
                                },
                                on: {send: this._handleSend, upload: this._handleUpload}
                            })]), e("div", {class: "lemon-vessel__right"}, [tt(this.$scopedSlots["message-side"], null, r)])])])), n.push(e("div", {
                                class: i,
                                directives: [{name: "show", value: !r.id && this.currentIsDefSidebar}]
                            }, [this.$slots.cover])), n.push(e("div", {
                                class: i,
                                directives: [{name: "show", value: this._menuIsContacts() && o && r.id}]
                            }, [tt(this.$scopedSlots["contact-info"], e("div", {class: "lemon-contact-info"}, [e("lemon-avatar", {
                                attrs: {
                                    src: r.avatar,
                                    size: 90
                                }
                            }), e("h4", [r.displayName]), e("lemon-button", {
                                on: {
                                    click: function () {
                                        a(r.lastContent) && t.updateContact({
                                            id: r.id,
                                            lastContent: " "
                                        }), t.changeContact(r.id, we)
                                    }
                                }
                            }, ["发送消息"])]), r)])), n
                        }, _handleSidebarScroll: function () {
                            A.hide()
                        }, _addContact: function (t, e) {
                            var n = {0: "unshift", 1: "push"}[e];
                            this.contacts[n](t)
                        }, _addMessage: function (t, e, n) {
                            var i, r = {0: "unshift", 1: "push"}[n];
                            Array.isArray(t) || (t = [t]), Pe[e] = Pe[e] || [], (i = Pe[e])[r].apply(i, Ce(t))
                        }, setLastContentRender: function (t, e) {
                            Ne[t] = e
                        }, lastContentRender: function (t) {
                            return c(Ne[t.type]) ? Ne[t.type].call(this, t) : "[不支持的消息类型]"
                        }, emojiNameToImage: function (t) {
                            return t.replace(/\[!(\w+)\]/gi, (function (t, e) {
                                var n = e;
                                return je[n] ? '<img emoji-name="'.concat(e, '" src="').concat(je[n], '" />') : "[!".concat(e, "]")
                            }))
                        }, emojiImageToName: function (t) {
                            return t.replace(/<img emoji-name=\"([^\"]*?)\" [^>]*>/gi, "[!$1]")
                        }, updateCurrentMessages: function () {
                            Pe[this.currentContactId] || (Pe[this.currentContactId] = []), this.currentMessages = Pe[this.currentContactId]
                        }, messageViewToBottom: function () {
                            this.$refs.messages.scrollToBottom()
                        }, setDraft: function (t, e) {
                            if (a(t) || a(e)) return !1;
                            var n = this.findContact(t), i = n.lastContent;
                            if (a(n)) return !1;
                            this.CacheDraft.has(t) && (i = this.CacheDraft.get(t).lastContent), this.CacheDraft.set(t, {
                                editorValue: e,
                                lastContent: i
                            }), this.updateContact({
                                id: t,
                                lastContent: '<span style="color:red;">[草稿]</span><span>'.concat(this.lastContentRender({
                                    type: "text",
                                    content: e
                                }), "</span>")
                            })
                        }, clearDraft: function (t) {
                            var e = this.CacheDraft.get(t);
                            if (e) {
                                var n = this.findContact(t).lastContent;
                                0 === n.indexOf('<span style="color:red;">[草稿]</span>') && this.updateContact({
                                    id: t,
                                    lastContent: e.lastContent
                                }), this.CacheDraft.remove(t)
                            }
                        }, changeContact: function () {
                            var t = h(regeneratorRuntime.mark((function t(e, n) {
                                var i, r, o, s = this;
                                return regeneratorRuntime.wrap((function (t) {
                                    while (1) switch (t.prev = t.next) {
                                        case 0:
                                            if (!n) {
                                                t.next = 4;
                                                break
                                            }
                                            this.changeMenu(n), t.next = 6;
                                            break;
                                        case 4:
                                            if (!(this._changeContactLock || this.activeSidebar == we && this.currentContactId == e || this.activeSidebar == Te && this.currentContactIdSidebarContact == e)) {
                                                t.next = 6;
                                                break
                                            }
                                            return t.abrupt("return", !1);
                                        case 6:
                                            if (this.currentContactId && (i = this.$refs.editor.chatArea.getHtml({needUserId: !0}), r = this.$refs.editor.chatArea.getText(), r ? (this.setDraft(this.currentContactId, i), this.setEditorValue()) : this.clearDraft(this.currentContactId)), this.activeSidebar == Te ? this.currentContactIdSidebarContact = e : this.currentContactId = e, this.currentContactId) {
                                                t.next = 10;
                                                break
                                            }
                                            return t.abrupt("return", !1);
                                        case 10:
                                            if (this.$emit("change-contact", this.currentContact, this), !c(this.currentContact.renderContainer) && this.activeSidebar != Te) {
                                                t.next = 13;
                                                break
                                            }
                                            return t.abrupt("return");
                                        case 13:
                                            o = this.CacheDraft.get(e), o && (this.$refs.editor.chatArea.reverseAnalysis(o.editorValue), this.$refs.editor._checkSubmitDisabled()), this.CacheMessageLoaded.has(e) ? this.$refs.messages.loaded() : this.$refs.messages.resetLoadState(), Pe[e] ? setTimeout((function () {
                                                s.updateCurrentMessages(), s.messageViewToBottom()
                                            }), 0) : (this.updateCurrentMessages(), this._emitPullMessages((function (t) {
                                                s.messageViewToBottom()
                                            })));
                                        case 17:
                                        case"end":
                                            return t.stop()
                                    }
                                }), t, this)
                            })));

                            function e(e, n) {
                                return t.apply(this, arguments)
                            }

                            return e
                        }(), removeMessage: function (t) {
                            var e = this.findMessage(t);
                            if (!e) return !1;
                            var n = Pe[e.toContactId].findIndex((function (e) {
                                var n = e.id;
                                return n == t
                            }));
                            return Pe[e.toContactId].splice(n, 1), !0
                        }, updateMessage: function (t) {
                            if (!t.id) return !1;
                            var e = this.findMessage(t.id);
                            return !!e && (e = Object.assign(e, t, {toContactId: e.toContactId}), !0)
                        }, forceUpdateMessage: function (t) {
                            if (t) {
                                var e = this.$refs.messages.$refs.message;
                                if (e) {
                                    var n = e.find((function (e) {
                                        return e.$attrs.message.id == t
                                    }));
                                    n && n.$forceUpdate()
                                }
                            } else this.$refs.messages.$forceUpdate()
                        }, _customContainerReady: function (t, e, n) {
                            c(t) && !e.has(n) && e.set(n, t.call(this))
                        }, changeMenu: function (t) {
                            this.$emit("change-menu", t), this.activeSidebar = t
                        }, initEmoji: function (t) {
                            var e = [];
                            this.$refs.editor.initEmoji(t), t[0].label ? t.forEach((function (t) {
                                var n;
                                (n = e).push.apply(n, Ce(t.children))
                            })) : e = t, e.forEach((function (t) {
                                var e = t.name, n = t.src;
                                return je[e] = n
                            }))
                        }, initEditorTools: function (t) {
                            this.editorTools = t
                        }, initMenus: function (t) {
                            var e = this, n = this.$createElement, i = [{
                                name: we, title: "聊天", unread: 0, click: null, render: function (t) {
                                    return n("i", {class: "lemon-icon-message"})
                                }, isBottom: !1
                            }, {
                                name: Te, title: "通讯录", unread: 0, click: null, render: function (t) {
                                    return n("i", {class: "lemon-icon-addressbook"})
                                }, isBottom: !1
                            }], r = [];
                            if (Array.isArray(t)) {
                                var o = {messages: 0, contacts: 1}, s = Object.keys(o);
                                r = t.map((function (t) {
                                    return s.includes(t.name) ? Oe(Oe(Oe({}, i[o[t.name]]), t), {renderContainer: null}) : (t.renderContainer && e._customContainerReady(t.renderContainer, e.CacheMenuContainer, t.name), t)
                                }))
                            } else r = i;
                            this.menus = r
                        }, initContacts: function (t) {
                            this.contacts = t, this.sortContacts()
                        }, sortContacts: function () {
                            this.contacts.sort((function (t, e) {
                                if (t.index) return t.index.localeCompare(e.index)
                            }))
                        }, appendContact: function (t) {
                            return a(t.id) || a(t.displayName) ? (console.error("id | displayName cant be empty"), !1) : (this.hasContact(t.id) || this.contacts.push(Object.assign({
                                id: "",
                                displayName: "",
                                avatar: "",
                                index: "",
                                unread: 0,
                                lastSendTime: "",
                                lastContent: ""
                            }, t)), !0)
                        }, removeContact: function (t) {
                            var e = this.findContactIndexById(t);
                            return -1 !== e && (this.contacts.splice(e, 1), this.CacheDraft.remove(t), this.CacheMessageLoaded.remove(t), !0)
                        }, updateContact: function (t) {
                            var e = t.id;
                            delete t.id;
                            var n = this.findContactIndexById(e);
                            if (-1 !== n) {
                                var i = t.unread;
                                o(i) && (0 !== i.indexOf("+") && 0 !== i.indexOf("-") || (t.unread = parseInt(i) + parseInt(this.contacts[n].unread))), this.$set(this.contacts, n, Oe(Oe({}, this.contacts[n]), t))
                            }
                        }, findContactIndexById: function (t) {
                            return this.contacts.findIndex((function (e) {
                                return e.id == t
                            }))
                        }, hasContact: function (t) {
                            return -1 !== this.findContactIndexById(t)
                        }, findMessage: function (t) {
                            for (var e in Pe) {
                                var n = Pe[e].find((function (e) {
                                    var n = e.id;
                                    return n == t
                                }));
                                if (n) return n
                            }
                        }, findContact: function (t) {
                            return this.getContacts().find((function (e) {
                                var n = e.id;
                                return n == t
                            }))
                        }, getContacts: function () {
                            return this.contacts
                        }, getCurrentContact: function () {
                            return this.currentContact
                        }, getCurrentMessages: function () {
                            return this.currentMessages
                        }, setEditorValue: function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
                            if (!o(t)) return !1;
                            this.$refs.editor.setValue(this.emojiNameToImage(t))
                        }, getEditorValue: function () {
                            return this.$refs.editor.getFormatValue()
                        }, clearMessages: function (t) {
                            return t ? (delete Pe[t], this.CacheMessageLoaded.remove(t), this.CacheDraft.remove(t)) : (Pe = {}, this.CacheMessageLoaded.remove(), this.CacheDraft.remove()), !0
                        }, getMessages: function (t) {
                            return (t ? Pe[t] : Pe) || []
                        }, changeDrawer: function (t) {
                            this.drawerVisible = !this.drawerVisible, 1 == this.drawerVisible && this.openDrawer(t)
                        }, openDrawer: function (t) {
                            $e = c(t) ? t : t.render || new Function;
                            var e = this.$refs.wrapper.clientWidth, n = this.$refs.wrapper.clientHeight, i = t.width || 200,
                                r = t.height || n, s = t.offsetX || 0, a = t.offsetY || 0, l = t.position || "right";
                            o(i) && (i = e * Re(i)), o(r) && (r = n * Re(r)), o(s) && (s = e * Re(s)), o(a) && (a = n * Re(a)), this.$refs.drawer.style.width = "".concat(i, "px"), this.$refs.drawer.style.height = "".concat(r, "px");
                            var h = 0, d = 0, u = "";
                            "right" == l ? h = e : "rightInside" == l ? (h = e - i, u = "-15px 0 16px -14px rgba(0,0,0,0.08)") : "center" == l && (h = e / 2 - i / 2, d = n / 2 - r / 2, u = "0 0 20px rgba(0,0,0,0.08)"), h += s, d += a + -1, this.$refs.drawer.style.top = "".concat(d, "px"), this.$refs.drawer.style.left = "".concat(h, "px"), this.$refs.drawer.style.boxShadow = u, this.drawerVisible = !0
                        }, closeDrawer: function () {
                            this.drawerVisible = !1
                        }, setAtUserList: function (t, e) {
                            this.$refs.editor.chatArea.updateConfig({userList: t, needCallEvery: e})
                        }, setUserTag: function (t) {
                            this.$refs.editor.chatArea.setUserTag(t), this.$refs.editor._checkSubmitDisabled()
                        }
                    }
                }, Ue = Fe, Ke = (n("b25a"), v(Ue, Me, De, !1, null, null, null)), Be = Ke.exports,
                Ve = (n("6a2b"), "1.4.2"), Ye = [Be, ft, jt, Ot, W, B, $, x, P, Ft, qt, ne, ue, ge], Ge = function (t) {
                    t.directive("LemonContextmenu", A), Ye.forEach((function (e) {
                        t.component(e.name, e)
                    }))
                };
            "undefined" !== typeof window && window.Vue && Ge(window.Vue);
            var We = {version: Ve, install: Ge};
            e["default"] = We
        }, fdef: function (t, e) {
            t.exports = "\t\n\v\f\r   ᠎             　\u2028\u2029\ufeff"
        }
    })
}));
